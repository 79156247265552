import { FieldHookConfig, useField } from 'formik'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { ReactElement } from 'react'
import { Box } from '@mui/material'

interface OtherProps {
  width?: string
  label?: string
  maxSize?: number
  validate?: (value: unknown) => string
  onChange?: (e: unknown) => void
  type?: 'text' | 'textarea' | 'number'
  note?: string
  autoComplete?: 'on' | 'off'
}

const CustomField = ({
  width,
  label,
  maxSize,
  type,
  note,
  onChange,
  autoComplete,
  ...props
}: OtherProps & FieldHookConfig<string>): ReactElement => {
  const [field, meta] = useField(props)
  return (
    <InputWrapper>
      <FieldLabel>{label}</FieldLabel>
      {(type === 'text' || type === 'number') && (
        <Input
          {...field}
          {...props}
          type={type}
          onChange={(value: string) => {
            field.onChange(value)
            if (onChange) {
              onChange(value)
            }
          }}
          width={width}
          error={meta?.touched && meta?.error}
          maxLength={maxSize}
          autoComplete={autoComplete}
        />
      )}
      {type === 'textarea' && (
        <TextArea
          {...field}
          {...props}
          maxLength={maxSize}
          error={meta?.touched && meta?.error}
        />
      )}
      {maxSize && (
        <SizeText error={field?.value?.length > maxSize}>
          {field?.value?.length || 0}/{maxSize}
        </SizeText>
      )}
      {meta?.touched && meta?.error ? (
        <ErrorText className="error">{meta?.error}</ErrorText>
      ) : null}
      {note && (
        <Box display="flex" justifyContent="flex-start">
          <Note>{note}</Note>
        </Box>
      )}
    </InputWrapper>
  )
}

CustomField.defaultProps = {
  maxSize: undefined,
  label: undefined,
  width: 'auto',
  type: 'text',
  validate: undefined,
  onChange: (): void => undefined,
  note: undefined,
  autoComplete: 'on',
}
export default CustomField

const ErrorText = styled.h6`
  font-family: Roboto;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 10px;
  color: ${defaultTheme.colors.red};
`
interface SizeTextProps {
  error: boolean
}
const SizeText = styled.h6<SizeTextProps>`
  font-family: Roboto;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 10px;
  color: ${(p) => (p.error ? defaultTheme.colors.red : 'gray')};
  align-self: flex-start;
`
const FieldLabel = styled.span`
  font-family: Roboto, serif;
  font-size: 14px;
  line-height: 24px;
  align-self: flex-start;
  font-weight: bold;
`

const InputWrapper = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`
interface InputProps {
  error?: boolean
  width: string
}

const Input = styled.input<InputProps>`
  min-height: 48px;
  width: ${(p) => p.width};
  padding: 8px 16px 8px 16px;
  background: ${defaultTheme.colors.componentsBackground};
  border: 1px solid
    ${(p) =>
      p.error ? defaultTheme.colors.red : defaultTheme.colors.greyMedium};
  border-radius: 4px;
`

const TextArea = styled.textarea<InputProps>`
  min-height: 48px;
  padding: 8px 16px 8px 16px;
  background: ${defaultTheme.colors.componentsBackground};
  border: 1px solid
    ${(p) =>
      p.error ? defaultTheme.colors.red : defaultTheme.colors.greyMedium};
  border-radius: 4px;
`

const Note = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-top: 8px;

  color: ${defaultTheme.colors.almostBlack};
`
