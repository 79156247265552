import { unparse } from 'papaparse'

interface CsvDownloadProps {
  data: unknown[]
  filename: string
}
const CSV_FILE_TYPE = 'text/csv;charset=utf-8;'

export const downloadCsv = ({ data, filename }: CsvDownloadProps): void => {
  const formattedFilename = getFilename(filename)

  const csvAsString = unparse(data)

  triggerCsvDownload(csvAsString, formattedFilename)
}

const triggerCsvDownload = (csvAsString: string, fileName: string) => {
  // BOM support for special characters in Excel
  const byteOrderMark = '\ufeff'

  const blob = new Blob([byteOrderMark, csvAsString], {
    type: CSV_FILE_TYPE,
  })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getFilename = (providedFilename: string): string => {
  return /csv$/i.test(providedFilename)
    ? providedFilename
    : `${providedFilename}.csv`
}
