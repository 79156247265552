import React, { ReactElement, isValidElement } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { Button, ButtonVariant } from 'src/ui-components/forms/Button'
import {
  Checkbox,
  ICheckboxClickHandler,
} from 'src/ui-components/forms/Checkbox'
import { Translate } from 'src/i18n'
import { Close } from '@mui/icons-material'
import { zIndex } from 'src/theme/zIndex'

interface SimpleModalProps {
  show: boolean
  title: string
  primaryActionText?: string
  onPrimaryAction?: () => void
  primaryButtonVariant?: ButtonVariant
  primaryButtonToTheLeft?: boolean
  primaryButtonIcon?: React.ReactElement
  disablePrimaryButton?: boolean
  secondaryActionText?: string
  onSecondaryAction?: () => void
  dontAskThisAgainText?: string
  dontAskThisAgainAction?: (value: ICheckboxClickHandler) => void
  size?: 'lg' | 'sm' | 'xl'
  borderBottomHeader?: boolean
  borderTopFooter?: boolean
  onCancel?: () => void
  children: string | ReactElement
  loading?: boolean
  buttonsDirection?: 'column' | 'row'
}

export const SimpleModal = (props: SimpleModalProps): ReactElement => {
  const {
    title,
    show,
    onPrimaryAction,
    primaryActionText,
    onSecondaryAction,
    secondaryActionText,
    disablePrimaryButton,
    primaryButtonVariant,
    dontAskThisAgainText,
    dontAskThisAgainAction = () => {},
    borderBottomHeader = false,
    borderTopFooter,
    size,
    primaryButtonToTheLeft = false,
    primaryButtonIcon,
    onCancel,
    loading,
    children,
    buttonsDirection = 'row',
  } = props

  const onCancelAction = onCancel ?? onSecondaryAction

  return (
    <Modal size={size} centered show={show} style={{ zIndex: zIndex.modal }}>
      <Header onHide={onCancelAction} $borderBottomHeader={borderBottomHeader}>
        <>
          <Title>{Translate(title)}</Title>
          <Close
            style={{ color: '#0F172A', cursor: 'pointer', marginLeft: 'auto' }}
            onClick={onCancelAction}
          />
        </>
      </Header>
      <Body>
        {isValidElement(children) ? children : Translate(children as string)}
      </Body>
      <Footer $borderTopFooter={borderTopFooter}>
        <div>
          {dontAskThisAgainText !== undefined && (
            <Checkbox
              value="true"
              label={dontAskThisAgainText}
              onClick={dontAskThisAgainAction}
            />
          )}
        </div>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <ButtonsWrapper
            primaryButtonToTheLeft={primaryButtonToTheLeft}
            buttonsDirection={buttonsDirection}
          >
            {primaryActionText !== undefined && (
              <Button
                minWidth="80px"
                onClick={onPrimaryAction}
                text={primaryActionText}
                disabled={disablePrimaryButton}
                variant={primaryButtonVariant}
                icon={primaryButtonIcon}
              />
            )}
            {secondaryActionText !== undefined && (
              <Button
                onClick={onSecondaryAction}
                minWidth="80px"
                text={secondaryActionText}
                variant="secondary"
              />
            )}
          </ButtonsWrapper>
        )}
      </Footer>
    </Modal>
  )
}

SimpleModal.defaultProps = {
  onPrimaryAction: undefined,
  onSecondaryAction: undefined,
  primaryActionText: undefined,
  secondaryActionText: undefined,
  disablePrimaryButton: false,
  primaryButtonVariant: undefined,
  dontAskThisAgainText: undefined,
  dontAskThisAgainAction: undefined,
  size: 'lg',
  borderBottomHeader: false,
  borderTopFooter: false,
  primaryButtonToTheLeft: false,
  primaryButtonIcon: undefined,
  onCancel: undefined,
  loading: false,
  isMobile: false,
  buttonsDirection: 'row',
}

interface HeaderProps {
  $borderBottomHeader: boolean
}

const Header = styled(Modal.Header)<HeaderProps>`
  border-bottom: none;
  ${(p) =>
    p.$borderBottomHeader
      ? `box-shadow: inset 0 -1px 0 #d3d2cf;
         width: 100%;`
      : ''};
`

const Title = styled(Modal.Title)`
  font-family: Oswald, serif;
  font-weight: bold;
  font-size: 24px;
`

const Body = styled(Modal.Body)`
  font-family: Roboto, serif;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0;
`

interface FooterProps {
  $borderTopFooter: boolean
}

const Footer = styled(Modal.Footer)<FooterProps>`
  border-top: none;
  font-family: Oswald, serif;
  justify-content: space-between;
  ${(p) =>
    p.$borderTopFooter
      ? `box-shadow: inset 0 1px 0 #d3d2cf;
         width: 100%;`
      : ''};
`
interface ButtonsWrapperProps {
  primaryButtonToTheLeft: boolean
  buttonsDirection: 'column' | 'row'
}
const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  flex-direction: ${(p) =>
    p.primaryButtonToTheLeft
      ? p.buttonsDirection === 'column'
        ? `column`
        : `row`
      : p.buttonsDirection === 'column'
        ? 'column-reverse'
        : 'row-reverse'};
  width: ${(p) => (p.buttonsDirection === 'column' ? '100%' : 'auto')};
`
