import { ReactElement, RefObject } from 'react'
import { I18n, Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import styled from 'styled-components'
import { Print as PrintIcon } from '@mui/icons-material'
import { useReactToPrint } from 'react-to-print'
import { Button } from 'src/ui-components'

interface PrintInterface {
  beforePrint?: () => Promise<void>
  afterPrint?: () => void
  printRef: RefObject<HTMLDivElement>
  documentTitle: string
  displayText: string
  underlined?: boolean
  printButton?: boolean
}

export const Print = (props: PrintInterface): ReactElement | null => {
  const {
    beforePrint,
    afterPrint,
    printRef,
    documentTitle,
    displayText,
    underlined,
    printButton,
  } = props

  const handlePrint = useReactToPrint({
    onBeforePrint: beforePrint,
    onAfterPrint: afterPrint,
    content: () => printRef.current,
    documentTitle,
  })
  if (!window.print) {
    return null
  }

  return (
    <>
      {!printButton && (
        <Hyperlink action={handlePrint} underlined={!!underlined}>
          <PrintIcon style={{ marginRight: 5, width: 18 }} />
          <PrintComponent name={displayText} />
        </Hyperlink>
      )}
      {printButton && (
        <Button text={Translate('Print')} onClick={handlePrint} />
      )}
    </>
  )
}

const PrintComponent = styled(I18n)`
  font-size: 16px;
  @media screen and (max-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`
