import { ReactElement, useState } from 'react'
import PartsCatalog from './PartsCatalog'
import { isMobile } from 'react-device-detect'
import { LaborLookupSelectorMobile } from 'src/features/partsCatalog/Selections/LaborLookupSelectorMobile'

const DrawerBody = (): ReactElement => {
  const [mode, setMode] = useState(0)
  const [, setHideTitle] = useState(false)
  if (isMobile) {
    return (
      <LaborLookupSelectorMobile
        {...{ mode, setMode }}
        setHideTitle={(hide) => {
          setHideTitle(hide)
        }}
      />
    )
  } else {
    return <PartsCatalog />
  }
}
export default DrawerBody
