/** See this SO post: https://stackoverflow.com/a/42234988 */

import React, { useEffect } from 'react'

export const useClickOutsideDetector = (
  refs: Array<React.RefObject<HTMLElement>>,
  active: boolean,
  onClickedOutside: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target as HTMLElement)) {
          return
        }
        if (!document.body.contains(event.target as HTMLElement)) {
          /*
          This if block is added because of VehicleQuickFind component. This component implements 'react-select' package. 
          In the package we have a cancel button available on the "Select" component. When we click on the cancel the button, the button 
          itself is removed from the DOM. This button is a svg element inside a div element. This parent div is removed when we 
          click on the cancel button i.e., event.target is not present in the DOM. As a result the condition ref.current.contains(event.target) 
          will be false, when ref is referring to BaseDrawer component.
          */
          return
        }
      }
      onClickedOutside()
    }
    if (active) {
      document.addEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener
      )
    }

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener
      )
    }
  }, [onClickedOutside, refs, active])
}
