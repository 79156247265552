import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { I18n } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import { zIndex } from 'src/theme/zIndex'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'

interface HistoricalEntriesOverlayProps {
  wrapperRef: React.RefObject<HTMLInputElement>
  setEnableSearchHistory: (value: boolean) => void
  onSearch: (selected: string) => void
}

export const HistoricalEntriesOverlay = (
  props: HistoricalEntriesOverlayProps
): ReactElement => {
  const { wrapperRef, setEnableSearchHistory, onSearch } = props

  const [searches, setSearches] = useState([])
  const { setShowMultiPartInquiryDrawer } = useUiStateStore()
  const readSearches = () => {
    const localStorageSearches = JSON.parse(
      localStorage.getItem('searches')
    )?.slice(0, 10)
    setSearches(localStorageSearches)
  }

  useEffect(() => {
    readSearches()
  }, [])

  return (
    <ResultsWrapper ref={wrapperRef}>
      {import.meta.env.VITE_FEATURE_MULTI_PART_INQUIRY_ENABLED === 'true' && (
        <MultiPartInquiryWrapper
          onClick={() => {
            setShowMultiPartInquiryDrawer(true)
          }}
        >
          <StyledListAltIcon />
          <I18n name="multiPartInquiry" />
        </MultiPartInquiryWrapper>
      )}
      <DataWrapper>
        <RecentSearchTitle>
          <I18n name="recentSearches" />
        </RecentSearchTitle>
        {searches &&
          searches?.length > 0 &&
          searches?.map((search, index) => (
            <DataItem
              key={`history-${search?.id}_${index}`}
              onClick={() => {
                onSearch(search?.id)
                setEnableSearchHistory(false)
              }}
            >
              <TimeIcon />
              {search?.id}
            </DataItem>
          ))}
      </DataWrapper>
    </ResultsWrapper>
  )
}

const TimeIcon = styled(AccessTimeIcon)`
  height: 16px;
  width: 16.67px;
  margin-right: 9.67px;
  fill: ${defaultTheme.colors.disabledButtonText};
  margin-left: 17px;
`

const RecentSearchTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
  margin: 16px 0 8px 17px;
`

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 110%;
  min-height: 36px;
  position: absolute;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid ${defaultTheme.colors.border};
  z-index: ${zIndex.dropdown};
  left: 0;
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 3px;
`
const DataItem = styled.span`
  width: 100%;
  text-align: left;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
  &:hover {
    background: ${defaultTheme.colors.disabledBackground};
  }
  &:active {
    background: #777777;
  }
`

const MultiPartInquiryWrapper = styled.span`
  width: 100%;
  text-align: left;
  font-family: Roboto, serif;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  color: ${defaultTheme.colors.blueLight};
  margin: 5px 0px 0px 16px;
  line-height:40px;
  display: flex;
  align-items: center;
  }
  &:hover {
    text-decoration: underline;
  }
  
`
const StyledListAltIcon = styled(ListAltIcon)`
  width: 20px;
  margin-right: 5px;
`
