import { ReactElement } from 'react'
import styled from 'styled-components'
import { I18n } from 'src/i18n'
import { ErrorOutline } from '@mui/icons-material'
import { defaultTheme } from 'src/theme'

interface InlineErrorProps {
  message: string
}

export const InlineError = (props: InlineErrorProps): ReactElement => {
  const { message } = props
  return (
    <Wrapper color="#FBE6E8">
      <IconWrapper>
        <ErrorOutline htmlColor={defaultTheme.colors.red} />
      </IconWrapper>
      <I18n name={message} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Roboto, serif;
  border-radius: 2px;
  background-color: ${(p) => p.color};
`

const IconWrapper = styled.span`
  margin-right: 10px;
`
