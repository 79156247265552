export enum LookupType {
  CAR = 111,
  MOTORCYCLE = 112,
  ATV_UTV = 113,
  SCOOTER = 114,
  SNOWMOBILE = 115,
  WATERCRAFT = 116,
  TRUCK = 117,
  MEDIUM_DUTY = 118,
  HEAVY_DUTY = 211,
}
