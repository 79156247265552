export const SELLER_MANAGED_LIST_LABEL = 'Seller Managed'

export const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as const

export const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZoneName: 'short',
} as const

export enum DateValidation {
  invalidDate = 'Invalid Date',
}
