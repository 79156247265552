import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import CategoryNameModal from './components/LeftPanel/CategoryNameModal'
import DeleteCategoryConfirmation from './components/LeftPanel/DeleteCategoryConfirmation'
import DeleteListConfirmation from './components/LeftPanel/DeleteListConfirmation'
import LeftPanel from './components/LeftPanel/LeftPanel'
import MoveListConfirmation from './components/LeftPanel/MoveListConfirmation'
import NameListConfirmation from './components/LeftPanel/NameListConfirmation'
import CartSelectorModal from './components/RightPanel/CartSelectorModal'
import { DeleteItemsFromListsModal } from './components/RightPanel/DeleteItemsFromListsModal'
import { RightPanel } from './components/RightPanel/RightPanel'
import { ListsStoreContext, ListsStore } from 'src/store/lists/ListsStore'

const Lists = observer((): React.ReactElement => {
  const listStore = useRef<ListsStore>(new ListsStore())

  return (
    <ListsStoreContext.Provider value={listStore.current}>
      <Container>
        <LeftPanel />
        <RightPanel />
        <DeleteListConfirmation />
        <NameListConfirmation />
        <MoveListConfirmation />
        <CategoryNameModal />
        <DeleteCategoryConfirmation />
        <DeleteItemsFromListsModal />
        <CartSelectorModal />
      </Container>
    </ListsStoreContext.Provider>
  )
})

export default Lists

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
  }
`
