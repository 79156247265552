import { ReactElement } from 'react'
import DOMPurify from 'dompurify'

interface SanitizedProps {
  html: string
}
export const Sanitized = (props: SanitizedProps): ReactElement => {
  const { html } = props
  const sanitizer = DOMPurify.sanitize
  return <span dangerouslySetInnerHTML={{ __html: sanitizer(html) }} />
}
