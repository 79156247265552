import { IPartnerExternal } from '../types/PartnerExternal'
import { IBasePartnerCommunicationManager } from './index'
import { LocalInventoryMessage, PartsBin } from '../types/localInventory'
import { partsBasketToSearchResults } from '../utils/localInventoryUtils'
import { ProductModel } from 'src/store/models/ProductModel'
import { download } from 'src/helpers/utils'

declare const external: IPartnerExternal

class BasePartnerCommunicationManager
  implements IBasePartnerCommunicationManager
{
  protected readonly targetOrigin: string

  protected originalParts: ProductModel[] = []

  protected localInventoryCallBack?: (data: ProductModel[]) => void

  constructor() {
    this.targetOrigin = '*'
    this.handlePostMessage = this.handlePostMessage.bind(this)
    this.init()
  }

  protected init(): void {
    window.addEventListener('message', this.handlePostMessage)
  }

  protected handlePostMessage(e: MessageEvent): void {
    const data = typeof e.data === 'object' ? e.data : JSON.parse(e.data)
    if (data.fromLocalInventory) {
      const partsData: PartsBin =
        typeof data.partsData === 'object'
          ? data.partsData
          : JSON.parse(data.partsData)
      if (this.localInventoryCallBack) {
        this.localInventoryCallBack(
          partsBasketToSearchResults(partsData, this.originalParts)
        )
      }
    }
  }

  public cartCallBack(data: string): void {
    const params = {
      cartData: true,
      partsData: data,
    }
    this.handleDownload(data)
    this.messageParent(params)

    if (external && external.PostData) {
      external.PostData(data)
    }
    // Data island case.
    const partsBasketElement: HTMLInputElement = document.getElementById(
      'partsBasket'
    ) as HTMLInputElement
    partsBasketElement.value = ''
    partsBasketElement.value = btoa(data)
  }

  protected messageParent = (data: unknown): void => {
    window.parent.postMessage(
      typeof data !== 'string' ? JSON.stringify(data) : data,
      this.targetOrigin
    )
  }

  // Download code copied as is from aes and updated to typescript.
  protected handleDownload = (data: unknown): void => {
    let partsBasketUID

    const locationRef = window.location.href
    const splitLocRef = decodeURI(locationRef).split('partsBasket=')
    if (splitLocRef && splitLocRef.length > 1) {
      const partsBasket = splitLocRef[1].toString().split('","')

      if (partsBasket && partsBasket.length) {
        let isDownload: boolean = false
        for (let i = 0; i < partsBasket.length; i++) {
          if (partsBasket[i].match(/download/g)) {
            const splitVal = partsBasket[i].split('":"')

            isDownload =
              splitVal && splitVal.length > 1 ? splitVal[1] === 'true' : false
          }
          if (partsBasket[i].match(/uid/g)) {
            const uidValue = partsBasket[i].split('":"')

            if (uidValue && uidValue.length > 1) {
              if (
                uidValue[1] === '' ||
                uidValue[1] === undefined ||
                uidValue[1] === null
              ) {
                partsBasketUID = 'UID cannot be empty'
              } else {
                partsBasketUID = uidValue[1]
              }
            }
          }
        }
        if (!isDownload) {
          partsBasketUID = undefined
        }
      }
    }

    if (partsBasketUID === 'UID cannot be empty') {
      //do nothing
    } else if (
      partsBasketUID &&
      partsBasketUID.split('|') &&
      partsBasketUID.split('|').length
    ) {
      const parsedData = typeof data !== 'string' ? JSON.stringify(data) : data

      download(parsedData, partsBasketUID.split('|')[0] + '.json', 'text/plain')
    }
  }

  public cancelCallBack(): void {
    const params = {
      cancel: true,
    }

    this.messageParent(params)
    if (external && external.CloseApp) {
      external.CloseApp()
    }
  }

  public getLocalInventory(
    results: LocalInventoryMessage,
    originalParts: ProductModel[],
    cb: (data: ProductModel[]) => void
  ): void {
    this.originalParts = originalParts
    this.messageParent(results)
    this.localInventoryCallBack = cb
  }
}

export default BasePartnerCommunicationManager
