import { ExpandMore } from '@mui/icons-material'
import { ReactElement, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickOutsideDetector } from 'src/hooks'
import { breakpoints } from 'src/theme/breakpoints'
import { observer } from 'mobx-react-lite'
import { zIndex } from 'src/theme/zIndex'
import { options } from './constants'
import { I18n } from 'src/i18n'

interface ILanguageProps {
  onLanguageChange: (key: ISelectedOption) => void
  selectedLanguage: ISelectedOption
}

export interface ISelectedOption {
  label: string
  key: string
  imgPath: string
}

export const LanguageDropdown = observer(
  (props: ILanguageProps): ReactElement => {
    const { onLanguageChange, selectedLanguage } = props
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef<HTMLInputElement>(null)

    const [selectedOption, setSelectedOption] = useState<ISelectedOption>(
      options.find((option) => selectedLanguage.key === option.key) ??
        options[0]
    )

    const toggling = () => {
      setIsOpen(!isOpen)
    }

    const onOptionClicked = (value: string) => () => {
      const selectedLanguage =
        options.find((lang) => lang.key === value) || options[0]
      setSelectedOption(selectedLanguage)
      onLanguageChange(selectedLanguage)
      setIsOpen(false)
    }

    useClickOutsideDetector([ref], isOpen, () => setIsOpen(false))

    return (
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          <FlagImg src={selectedOption.imgPath} alt="My place 4 parts logo" />
          <Label className="pl-3">
            <I18n name={selectedOption.label}></I18n>
          </Label>
          <ExpandMore />
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer ref={ref}>
            <DropDownList>
              {options.map((option) => (
                <ListItem
                  onClick={onOptionClicked(option.key)}
                  key={option.key}
                >
                  <FlagImg src={option.imgPath} alt="My place 4 parts logo" />
                  <Label className="pl-3">
                    <I18n name={option.label}></I18n>
                  </Label>
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    )
  }
)

const DropDownContainer = styled('div')`
  cursor: pointer;
`

const DropDownHeader = styled('div')`
  padding: 0.4em 2em 0.4em 0;
  background: transparent;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: ${zIndex.dropdown};
`

const DropDownList = styled('ul')`
  margin: 0;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #d3d2cf;
  border-radius: 3px;
  box-sizing: border-box;
  &:first-child {
    padding-top: 0.8em;
  }
`

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  text-align: left;
`
const Label = styled('span')`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
  text-transform: uppercase;
  color: #050505;
`

const FlagImg = styled('img')`
  width: 20px;
`
