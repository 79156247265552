import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: '#F9C100',
    primaryFade: '#fef0bc',
    primaryFadeAlt: '#FFFAE8',
    secondary: '#FFF',
    primaryText: '#000',
    secondaryText: '#000',
    text: '#050505',
    ddsCompleted: '#82B842',
    ddsCompletedFade: '#ECF5E1',
    border: '#D3D2CF',
    borderTable: '#e5e4e1',
    columnBorder: '#6b6b6b',
    disabledBackground: '#F3F2EE',
    disabledButton: '#D3D2CF',
    disabledButtonText: '#ADADAA',
    disclaimerBackground: '#fef7d9',
    green: '#7EBD34',
    red: '#D9001B',
    greyMedium: '#D3D2CF',
    greyDark: '#9B9B9B',
    greyLight: '#ADADAA',
    greyVeryLight: '#EDEDED',
    almostBlack: '#050505',
    siteBackground: '#EBEBEB',
    componentsBackground: '#FFFFFF',
    shoppingCartMessageHeader: '#f9c1001a',
    link: '#006bb8',
    yellowLight: '#fef6d9',
    yellowReSort: '#f9c100',
    greyBorder: '#e5e4e1',
    rowFeatured: '#3D91E6',
    rowFeaturedBackground: '#fafafa',
    selectedOption: '#777777',
    white: '#fff',
    blueLight: '#006bb8',
    black: '#000000',
    gfxBackground: '#F8FFF6',
    gfxBorder: '#52A63A',
    gfxFilterTitle: '#53a63a',
    shadowOpacity: 'rgba(0, 0, 0, 0.37)',
    partsCatalogDarkBackground: '#EDEDED',
    warningBackground: '#f9c100',
    informationBackground: '#d9864b',
    orange: '#f28500',
    dangerAlertBg: '#FBE6E8',
    dangerAlertBgBorder: '#e77182',
    success: '#527B22',
    lightBlue: '#E2EFFB',
    floatingMessage: '#FEF7DF',
  },
  fieldBorder: '1px solid #D3D2CF',
}
