import { Checkbox } from 'src/ui-components/forms/Checkbox'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Button, ButtonVariant } from 'src/ui-components/forms/Button'
import { useModal } from '@ebay/nice-modal-react'
import { chunk } from 'lodash-es'
import { I18n, Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import { Translate2 } from 'src/i18n/translate2'
import { Sanitized } from 'src/features/search/Results/utils/Sanitized'
import { Close, ArrowBack } from '@mui/icons-material'
import { useWindowSize } from 'src/hooks'
import InfoIcon from '@mui/icons-material/Info'
import { ReactElement } from 'react'
import { Hyperlink } from '../util/Hyperlink'
import { CircularProgress } from '@mui/material'
import { parseBoolean } from '../util/CommonFunctions'
import { MiscPreferencesKeys, useUserStore } from 'src/store/user/UserStore'
import { ManufacturerList } from 'src/store/models/SearchModels'
import { defaultTheme } from 'src/theme'

interface ManufacturerComponentProps {
  title: string
  subtitle: string
  borderBottomHeader: boolean
  selectedManufactureOptions: string[]
  manufactureToggleSelectAll: () => void
  borderTopFooter?: boolean
  manufactureDontAskThisAgainAction: () => void
  doNotAskManufactureType: boolean
  primaryButtonToTheLeft: boolean
  buttonsDirection?: 'column' | 'row'
  manufactureSkipHandler: () => void
  manufactureSelectionHandler: () => void
  primaryButtonVariant: ButtonVariant
  primaryButtonIcon?: React.ReactElement
  manufactureCheckboxHandler: (value: string) => void
  filteredBrands: string[]
  setModelTypeValue: (value: string) => void
  setSelectedPartTypeOptions: (value: string[]) => void
  isSelectedAllManufacturerOptions: boolean
  setIsSelectedAllPartTypeOptions: (value: boolean) => void
  setUserManufacturerTypes: React.Dispatch<
    React.SetStateAction<ManufacturerList>
  >
  setIsSelectedAllManufacturerOptions: (value: boolean) => void
}

const ManufacturerComponent = ({
  title,
  subtitle,
  borderBottomHeader,
  selectedManufactureOptions,
  manufactureToggleSelectAll,
  borderTopFooter,
  doNotAskManufactureType,
  manufactureDontAskThisAgainAction,
  primaryButtonToTheLeft,
  buttonsDirection,
  manufactureSkipHandler,
  manufactureSelectionHandler,
  primaryButtonVariant,
  primaryButtonIcon,
  manufactureCheckboxHandler,
  filteredBrands,
  setModelTypeValue,
  isSelectedAllManufacturerOptions,
  setIsSelectedAllPartTypeOptions,
  setUserManufacturerTypes,
  setIsSelectedAllManufacturerOptions,
}: ManufacturerComponentProps): JSX.Element => {
  const modal = useModal()
  const currentWindowSize = useWindowSize()
  const userStore = useUserStore()
  const numberOfManufacturersPerRow =
    currentWindowSize.width <= 430
      ? filteredBrands.length
      : filteredBrands.length <= 22
        ? 11
        : Math.ceil(filteredBrands.length / 2)
  const createManufacturerColumnWithRows = (
    data: string[],
    index: number
  ): ReactElement => (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '16px',
      }}
      key={index}
    >
      {data?.map((manufacturer, index) => (
        <Checkbox
          key={`${manufacturer}_${index}`}
          onClick={() => manufactureCheckboxHandler(manufacturer)}
          value={selectedManufactureOptions.includes(manufacturer).toString()}
          label={`${manufacturer}`}
          active={selectedManufactureOptions.includes(manufacturer)}
        />
      ))}
    </div>
  )

  return (
    <>
      <Header
        onHide={() => {
          modal.remove()
        }}
        $borderBottomHeader={borderBottomHeader}
      >
        <>
          <ManufactureHeaderWrapper>
            {parseBoolean(
              userStore.miscPreferences?.[
                MiscPreferencesKeys.INTERCHANGE_PARTTYPES
              ]
            ) && (
              <Hyperlink
                action={() => {
                  setModelTypeValue('PartType')
                  setIsSelectedAllPartTypeOptions(true)
                  setUserManufacturerTypes({
                    manufacturerList: [],
                    partsBinId: '',
                  })
                  setIsSelectedAllManufacturerOptions(true)
                }}
                underlined={false}
              >
                <ArrowBack style={{ marginRight: 5, width: 18 }} />
                <I18n name="backToPartType" style={{ fontSize: '16px' }} />
              </Hyperlink>
            )}
            <Title>
              <Sanitized html={Translate2(`${title}`, [`${subtitle}`])} />
            </Title>
          </ManufactureHeaderWrapper>
          <Close
            style={{
              color: '#0F172A',
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
            onClick={() => {
              modal.remove()
            }}
          />
        </>
      </Header>
      <Body className="modal-body">
        <PartTypeWrapper>
          <ChoosePartTypeWrapper>
            {Translate('selectFromManufacturer')}
          </ChoosePartTypeWrapper>
          <ManufacturerInfoWrapper>
            <InfoIcon
              sx={{
                fontSize: '1rem',
                color: 'rgb(107, 107, 107)',
                marginTop: '4px',
              }}
            />
            <ManufacturerInfoText>
              <I18n name="manufacturerInfo" />
            </ManufacturerInfoText>
          </ManufacturerInfoWrapper>
          {filteredBrands.length > 0 && (
            <SelectAllWrapper>
              <AnchorTagWrapper
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  manufactureToggleSelectAll()
                }}
              >
                {isSelectedAllManufacturerOptions
                  ? Translate('selectAll')
                  : Translate('unSelectAll')}
              </AnchorTagWrapper>
            </SelectAllWrapper>
          )}
        </PartTypeWrapper>
        {filteredBrands.length > 0 ? (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                maxHeight: '44vh',
                overflowY: 'auto',
              }}
            >
              {chunk(filteredBrands, numberOfManufacturersPerRow).map(
                (searchedManufacturerData, index) =>
                  createManufacturerColumnWithRows(
                    searchedManufacturerData,
                    index
                  )
              )}
            </div>
          </div>
        ) : (
          <CircularProgress
            style={{
              marginLeft: '50%',
              marginRight: '50%',
              marginTop: '10%',
            }}
          />
        )}
      </Body>
      <Footer $borderTopFooter={borderTopFooter}>
        <div>
          <Checkbox
            value="true"
            label={Translate2(`dontAskManufacturerAgain`)}
            onClick={manufactureDontAskThisAgainAction}
            active={!doNotAskManufactureType}
          />
        </div>

        <ButtonsWrapper
          primaryButtonToTheLeft={primaryButtonToTheLeft}
          buttonsDirection={buttonsDirection}
        >
          <Button
            onClick={() => {
              manufactureSkipHandler()
            }}
            minWidth="80px"
            text="skipAndSeeAllResults"
            variant="secondary"
          />
          <Button
            minWidth="80px"
            onClick={manufactureSelectionHandler}
            text="next"
            disabled={
              selectedManufactureOptions.length === 0 && doNotAskManufactureType
            }
            variant={primaryButtonVariant}
            icon={primaryButtonIcon}
          />
        </ButtonsWrapper>
      </Footer>
    </>
  )
}

export default ManufacturerComponent

interface HeaderProps {
  $borderBottomHeader: boolean
}

const Header = styled(Modal.Header)<HeaderProps>`
  border-bottom: 1px solid #d3d2cf;
  ${(p) =>
    p.$borderBottomHeader
      ? `box-shadow: inset 0 -1px 0 #d3d2cf;
           width: 100%;`
      : ''};
`

const Title = styled(Modal.Title)`
  font-family: Oswald, serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
`

interface FooterProps {
  $borderTopFooter: boolean
}

const Footer = styled(Modal.Footer)<FooterProps>`
  border-top: none;
  font-family: Oswald, serif;
  justify-content: space-between;
  ${(p) =>
    p.$borderTopFooter
      ? `box-shadow: inset 0 1px 0 #d3d2cf;
           width: 100%;`
      : ''};
`
interface ButtonsWrapperProps {
  primaryButtonToTheLeft: boolean
  buttonsDirection: 'column' | 'row'
}
const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  width: ${(p) => (p.buttonsDirection === 'column' ? '100%' : 'auto')};
  @media screen and (max-width: ${breakpoints.mobileSmall}px) {
    flex-wrap: wrap;
  }
`
const Body = styled.div`
  font-family: Roboto, serif;
  font-size: 18px;
  padding-bottom: 0;
  padding-top: 1rem;
  min-height: 470px;
  border-bottom: 1px solid #d3d2cf;
`

const PartTypeWrapper = styled.div`
  width: 100%;
`

const ChoosePartTypeWrapper = styled.p`
  font-family: Oswald, serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
`
const SelectAllWrapper = styled.p`
  color: blue;
  font-size: 16px;
  line-height: 24px;
`
const AnchorTagWrapper = styled.a`
  color: ${defaultTheme.colors.blueLight};
  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
  font-family: Roboto, serif;
`

const ManufactureHeaderWrapper = styled.div`
  width: 100%;
`

const ManufacturerInfoWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  padding: 7px 16px;
  box-sizing: border-box;
  background-color: #f3f2ee;
  margin-bottom: 10px;
`
const ManufacturerInfoText = styled.div`
  font-size: 14px;
  line-height: 24px;
`
