export interface Link {
  text: string
  action: () => void
}

// we can use an external library later for an extensive list
export enum KeyBoardKeys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Enter = 'Enter',
  Tab = 'Tab',
}

export enum ToastNotificationVariant {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum MessageBoxType {
  WARNING,
  ERROR,
}

export enum DuplicateVehicleActions {
  START_NEW = 'START NEW',
  MERGE = 'MERGE',
  CANCEL = 'CANCEL',
}

export enum Sorting {
  MFR_ASCENDANT = 'mfr-ascendant',
  MFR_DESCENDANT = 'mfr-descendant',
  PARTNUM_ASCENDANT = 'partNum-ascendant',
  PARTNUM_DESCENDANT = 'partNum-descendant',
  QTY_ASCENDANT = 'qty-ascendant',
  QTY_DESCENDANT = 'qty-descendant',
  LAST_USED_ASCENDANT = 'last-used-ascendant',
  LAST_USED_DESCENDANT = 'last-used-descendant',
  BRAND = 'BRAND',
  PARTNUMBER = 'PARTNUMBER',
  QTY = 'QTY',
  LASTUSED = 'LASTUSED',
  ASC = 'ASC',
  DESC = 'DESC',
  SELLER_MANAGED_LIST_LABEL = 'Seller Managed',
}

export enum DiscardAndStartNewOrderActions {
  START_NEW = 'START_NEW',
  CANCEL = 'CANCEL',
}

export const enum VehicleSelectorStep {
  START = 'start',
  TYPE = 'type',
  YEAR = 'year',
  MAKE = 'make',
  MODEL = 'model',
  ENGINE = 'engine',
  END = 'end',
}
