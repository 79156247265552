import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { I18n } from 'src/i18n'
import { CatalogNodeType } from 'src/store/partsCatalog/interfaces'
import { CheckboxSelectionsProps } from '../interfaces'
import { CheckboxList } from './CheckboxList'
import { CheckboxListGroups } from './CheckboxListGroups'

export const CheckboxSelections = observer(
  (props: CheckboxSelectionsProps): ReactElement => {
    const { type, title, loading, selectCount, partsCatalogStore } = props
    const { currentVehicle } = useSearchStore()
    const year = currentVehicle?.year?.value
    const showGfxNotAvailable = partsCatalogStore.showGfxNotAvailable(
      type,
      Number(year)
    )

    const renderCheckboxes = () => {
      if (loading) {
        return (
          <SkeletonWrapper>
            {new Array(18).fill(undefined).map((_n, idx) => (
              <Skeleton key={idx} />
            ))}
          </SkeletonWrapper>
        )
      }
      switch (type) {
        case CatalogNodeType.CATEGORY:
          return (
            <ListWrapper>
              <CheckboxList list={partsCatalogStore.categories} type={type} />
            </ListWrapper>
          )
        case CatalogNodeType.GROUP:
          return (
            <CheckboxListGroups
              groups={partsCatalogStore.selectedCategories}
              type={type}
              partsCatalogStore={partsCatalogStore}
            />
          )

        case CatalogNodeType.PART_TYPE:
          return (
            <CheckboxListGroups
              groups={partsCatalogStore.selectedGroups}
              type={CatalogNodeType.PART_TYPE}
              partsCatalogStore={partsCatalogStore}
            />
          )
        default:
          return null
      }
    }
    return (
      <Wrapper>
        <Header>
          <Label>
            <I18n name={title} />
          </Label>
          {showGfxNotAvailable && (
            <Message>
              <I18n name="gfxUnavailableForThisVehicle" />
            </Message>
          )}
          {selectCount !== undefined && <Count>{selectCount} selected</Count>}
        </Header>
        {renderCheckboxes()}
      </Wrapper>
    )
  }
)
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const Count = styled.span`
  /* P Small / Default */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  margin-right: 12px;

  /* Text Gray */

  color: #9b9b9b;
`
const Skeleton = styled.div`
  margin: 10px;
  min-width: 90%;
  align-self: center;
  height: 10px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 10%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
`

const Wrapper = styled.div`
  background: #fafafa;
  height: 100%;
  display: block;
`

const SkeletonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ListWrapper = styled.div`
  padding: 0 24px 24px 24px;
  max-height: 70vh;
  overflow: auto;
  width: 100%;
`

const Label = styled.span`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 169% */

  text-transform: capitalize;

  /* Almost Black */
  padding: 24px;

  color: #050505;
`
const Message = styled.p`
  margin: 0;
  padding: 24px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #9b9b9b;
`
