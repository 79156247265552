import { ReactElement, useEffect } from 'react'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { Box, TabScrollButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { StyledTab, StyledTabs } from 'src/ui-components/forms/TabPanel'
import { ExperianAutocheckButton } from 'src/features/customizeSearch/components/ExperianAutocheckButton'
import { YMMESelector } from './components/YMMESelector'
import { LicensePlateSearch } from './components/LicensePlateSearch'
import { VinSearch } from './components/VinSearch'
import { TabPanel } from '../../../ui-components'
import { defaultTheme } from '../../../theme'
import { useCustomizeSearchStore } from '../../../store/search/CustomizeSearchStore'
import { SearchOptions } from '../../customizeSearch/interfaces'
import { Translate } from '../../../i18n'
import { CustomizeSearchButton } from 'src/features/customizeSearch/components/CustomizeSearchButton'
import { useUserStore } from 'src/store/user/UserStore'
import { UserAttributeKey } from 'src/store/user/interfaces'

export const AdvancedVehicleSearch = observer((): ReactElement => {
  const userStore = useUserStore()
  const customizeSearchStore = useCustomizeSearchStore()
  const selectedTab = customizeSearchStore.getSelectedTab()
  const searchPreferences = userStore.getUserAttribute(
    UserAttributeKey.searchPreferences
  )
  const miscPreferences = userStore.getUserAttribute(
    UserAttributeKey.miscPreferences
  )

  useEffect(() => {
    if (searchPreferences) {
      customizeSearchStore.setSearchPreferences(JSON.parse(searchPreferences))
    }

    if (miscPreferences) {
      const parsedPreferences = JSON.parse(miscPreferences)
      if (
        parsedPreferences.defaultVehicleType &&
        parsedPreferences.defaultVehicleType.trim() !== ''
      ) {
        const vehicleType = JSON.parse(
          parsedPreferences.defaultVehicleType || {}
        )
        customizeSearchStore.setVehicleTypePreference(vehicleType)
      }
    }
  }, [customizeSearchStore, miscPreferences, searchPreferences])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <SpaceBetweenRow>
          <StyledTabs
            value={selectedTab.idx}
            onChange={(e) => {
              const tabTitle = (e.target as HTMLElement).getAttribute(
                'data-tab'
              )
              if (tabTitle) {
                customizeSearchStore.setSelectedTab(tabTitle)
              }
            }}
            aria-label="basic tabs example"
            sx={{
              '&.Mui-selected': {
                color: defaultTheme.colors.primary,
                borderBottomColor: defaultTheme.colors.primary,
              },
            }}
            ScrollButtonComponent={StyledScrollButtonComponent}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {customizeSearchStore
              .getVisibleSearchPreferences()
              .map((searchOption) => {
                return (
                  <StyledTab
                    key={searchOption.id}
                    label={Translate(searchOption.id)}
                    data-tab={searchOption.id}
                  />
                )
              })}
          </StyledTabs>
          <CustomizeSearch>
            <ExperianAutocheckButton />
            <CustomizeSearchButton />
          </CustomizeSearch>
        </SpaceBetweenRow>
      </Box>
      <TabPanel id={SearchOptions.vehicle_ymme} selectedId={selectedTab.title}>
        <YMMEWrapper>
          <YMMESelector />
        </YMMEWrapper>
      </TabPanel>
      <TabPanel id={SearchOptions.vin} selectedId={selectedTab.title}>
        <VinPlateWrapper>
          <VinSearch />
        </VinPlateWrapper>
      </TabPanel>
      <TabPanel id={SearchOptions.license_plate} selectedId={selectedTab.title}>
        <VinPlateWrapper>
          <LicensePlateSearch />
        </VinPlateWrapper>
      </TabPanel>
    </>
  )
})

AdvancedVehicleSearch.displayName = 'AdvancedVehicleSearch'

const YMMEWrapper = styled.div`
  width: 100%;
  background-color: inherit;
`

const VinPlateWrapper = styled.div`
  width: 500px;
  margin: 10px;
  margin-top: 20px;
  .text-input-wrapper {
    flex-grow: 1;
    margin-right: 0;
    width: auto;
    span {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-right: 0;
    }
  }
`

const Row = styled.div`
  display: flex;
`

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    padding: 0px;
  }
`

const CustomizeSearch = styled(SpaceBetweenRow)`
  gap: 17px;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const StyledScrollButtonComponent = styled(TabScrollButton)`
  width: 10px;
  display: ${(p) => (p.disabled ? 'none' : 'flex')};
  margin-right: ${(p) => (p.direction === 'left' ? '10px' : '0px')};
  margin-left: ${(p) => (p.direction === 'right' ? '10px' : '0px')};
`
