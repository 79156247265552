import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { Spinner } from 'react-bootstrap'
import { I18n } from 'src/i18n'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { CatalogNodeType } from 'src/store/partsCatalog/interfaces'
import { CheckboxListProps } from '../interfaces'
import { Checkbox } from './Checkbox'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { useSearchStore } from 'src/store/search/SearchStore'

export const CheckboxList = observer(
  (props: CheckboxListProps): ReactElement => {
    const { list, groupId, showGFX, type } = props
    const searchStore = useSearchStore()
    const uiStore = useUiStateStore()

    const parseAllEngines = (item: IdValueGeneric<string, string>) => {
      const allEnginesSelected =
        searchStore.currentVehicle?.engine?.value === 'All Engine'
      searchStore.setGfxItem({
        id: item.id,
        value: item.value,
      })
      searchStore.setGroupId(groupId)
      if (allEnginesSelected) {
        uiStore.setShowEnginesModal(true)
        searchStore.getEngineOptions()
      } else {
        searchStore.openGFX()
      }
    }

    return (
      <Wrapper>
        {list.map((item) => (
          <ItemWrapper key={item.value}>
            <Checkbox
              onChange={item.onClick}
              value={item.id}
              label={item.value}
              selected={item.selected}
              bold={type === CatalogNodeType.PART_TYPE && item.isConsolidated}
            />
            {showGFX && searchStore.gfxId !== item?.id && (
              <Button
                onClick={() => parseAllEngines(item)}
                variant="secondary"
                size="tiny"
                minWidth="50px"
                text="gfx"
              />
            )}
            {searchStore.gfxIconIsLoading &&
              searchStore.gfxId === item?.id &&
              type === CatalogNodeType.GROUP && (
                <SpinnerWrapper>
                  <Spinner animation="border" />
                </SpinnerWrapper>
              )}
          </ItemWrapper>
        ))}
      </Wrapper>
    )
  }
)

export const ErrorMessageBody = (groupName: string): ReactElement => (
  <>
    <I18n name="wereSorryAGraphicFor" />
    &nbsp;
    <b>{groupName}</b>
    &nbsp;
    <I18n name="isNotAvailableInDatabase" />
  </>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ItemWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const SpinnerWrapper = styled.div`
  width: 42.5156px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
