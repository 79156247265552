import { ReactElement } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { observer } from 'mobx-react-lite'
import { SimpleModal } from './SimpleModal'

export interface ModalNotificationData {
  title: string
  body?: () => ReactElement
  bodyString?: string
  confirmText: string
  onConfirm: () => void
  onCancel: () => void
}

export const ModalNotification = observer((): ReactElement => {
  const uiStore = useUiStateStore()
  const data: ModalNotificationData = uiStore.modalNotificationData ?? {
    body: () => undefined,
    confirmText: '',
    onCancel: () => undefined,
    onConfirm: () => undefined,
    title: '',
    bodyString: '',
  }
  return (
    <SimpleModal
      show={uiStore.showModalNotification}
      title={data.title}
      onSecondaryAction={data.onCancel}
      onPrimaryAction={data.onConfirm}
      primaryActionText={data.confirmText}
    >
      <>
        {!!data.body && data.body()}
        {data.bodyString}
      </>
    </SimpleModal>
  )
})
