import { ReactElement, useState } from 'react'
import { RadioGroup } from 'src/ui-components'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { SpecificConditionI } from 'src/store/models/SpecificConditions'
import styled from 'styled-components'
import { QuestionRef } from './QuestionRef'
import { QuestionState } from './GfxSpecificConditionsModal'
import { IdValuePair } from 'src/store/models/KeyValuePair'

interface SpecificConditionQuestionProps {
  question: SpecificConditionI
  questionList: Array<QuestionState>
  onAnswer: (answer: IdValuePair<string>) => void
}

export const SpecificConditionQuestion = (
  props: SpecificConditionQuestionProps
): ReactElement => {
  const { question, questionList, onAnswer } = props
  const { findAnswer } = useSpecificConditionStore()
  const answer = findAnswer(question)
  const initialValue = answer?.choice?.id
  const [hasAnswered, setHasAnswered] = useState(initialValue !== undefined)

  const handleAnswer = (selected: IdValuePair<string>) => {
    onAnswer(selected)
    setHasAnswered(true)
  }

  return (
    <QuestionRef
      hasAnswered={hasAnswered}
      questionList={questionList}
      questionId={`${question.id}`}
    >
      <QuestionLabel>{question.value}</QuestionLabel>
      <RadioGroup
        id={`${question.id}`}
        name={question.value}
        options={question.choices}
        onChange={handleAnswer}
        initialValue={initialValue}
      />
    </QuestionRef>
  )
}

const QuestionLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto, serif;
  margin-top: 15px;
  margin-bottom: 5px;
`
