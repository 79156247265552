import {
  ContactAdminInterface,
  ContactUsInfo,
  IContactUsResponse,
  PassWordResetError,
} from 'src/api/contactUs/contactusInterface'
import { BaseServiceProvider } from './BaseServiceProvider'
import axios, { AxiosError } from 'axios'

class SupportServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'support', '1.1', '')
  }

  public postContactUs = async (
    contactInfo: ContactUsInfo
  ): Promise<IContactUsResponse> => {
    try {
      const resp = await this.post<IContactUsResponse>('contactUs', contactInfo)
      return resp.data
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  public contactAdmin = async (
    contactAdminInfo: ContactAdminInterface
  ): Promise<IContactUsResponse> => {
    try {
      const resp = await axios.post<IContactUsResponse>(
        'passwordResetRequest',
        contactAdminInfo,
        { baseURL: this.getConstructedUrl() }
      )
      return resp.data
    } catch (error: unknown) {
      const { message }: PassWordResetError = (error as AxiosError).response
        ?.data as PassWordResetError
      throw new Error(message)
    }
  }
}

export default new SupportServiceProvider()
