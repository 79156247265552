class QueryParser {
  params: URLSearchParams

  constructor() {
    this.params = new URLSearchParams(window.location.search)
  }

  getParam(name: string): string {
    return this.params.get(name) || ''
  }

  getMultipleParams(params: string[]): string[] {
    return params.map((param) => this.params.get(param) || '')
  }

  getParamsMap(params: string[]): { [key: string]: string } {
    return params.reduce(
      (prev, curr) => ({ ...prev, [curr]: this.params.get(curr) }),
      {}
    )
  }
}

export default new QueryParser()
