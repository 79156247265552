import { Placement } from '@popperjs/core'
import { ReactElement, useRef } from 'react'
import { Tooltip as BootstrapTooltip, Overlay } from 'react-bootstrap'
import { I18n } from 'src/i18n'
import { zIndex } from 'src/theme/zIndex'

interface TooltipProps {
  message?: string
  children: ReactElement
  show?: boolean
  content?: ReactElement
  onClose?: () => void
  placement?: Placement
}

export const Tooltip = (props: TooltipProps): ReactElement => {
  const { children, message, show, content, onClose, placement = 'top' } = props
  const target = useRef<HTMLButtonElement>(null)

  if (!message && !content) {
    return children
  }

  return (
    <>
      <span ref={target}>{children}</span>
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose={onClose ? true : false}
        onHide={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        {(props) => (
          <BootstrapTooltip
            {...props}
            id="tooltip"
            style={{
              ...props.style,
              zIndex: zIndex.tooltipInModal,
              width: '185px',
            }}
          >
            <div style={{ textAlign: 'left' }}>
              <I18n name={message || ''} />
            </div>
            {content}
          </BootstrapTooltip>
        )}
      </Overlay>
    </>
  )
}

Tooltip.defaultProps = {
  show: true,
  content: undefined,
  message: undefined,
}
