import { Filter, Visitor } from './interfaces'
import { CatalogNode } from '../CatalogNode'

export class DeselectVisitor implements Visitor {
  private readonly filter

  constructor(filter?: Filter) {
    this.filter = filter
  }

  public visit(node: CatalogNode): void {
    if (!this.filter || this.filter(node)) {
      node.deselect()
    }
  }
}
