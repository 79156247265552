import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { QuestionState } from './GfxSpecificConditionsModal'

interface QuestionRefProps {
  questionId: string
  questionList: Array<QuestionState>
  hasAnswered: boolean
  children: React.ReactNode
}

export const QuestionRef = (props: QuestionRefProps): ReactElement => {
  const { questionId, hasAnswered, questionList, children } = props
  const ref = useRef(null)

  const addToList = useCallback(() => {
    if (!questionList.find((q) => q.questionId === questionId)) {
      questionList.push({
        questionId,
        ref,
        hasAnswered,
      })
    }
  }, [hasAnswered, questionId, questionList])

  useEffect(() => {
    addToList()
  }, [addToList])

  return <div ref={ref}>{children}</div>
}
