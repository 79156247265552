import axios from 'axios'
import { BaseServiceProvider } from './BaseServiceProvider'
import {
  ISendCodeResponse,
  ISendCodeUserDetails,
  IResetPasswordUserDetails,
  IResetPasswordResponse,
} from 'src/features/login/interfaces'

interface ICognitoUser {
  userName: string
  password: string
}

interface ICognitoUserResponse {
  message: string
  userId: string
  statusCode: number
  status: string
}

class AllianceConnectServiceProvider extends BaseServiceProvider {
  constructor() {
    super(
      import.meta.env.VITE_ALLIANCE_CONNECT_API_URL,
      '/o/aapa_jax_rest_services',
      '',
      ''
    )
  }

  createCognitoUser = async (reqData: ICognitoUser): Promise<void> => {
    await axios.post<ICognitoUserResponse>('organization/createUser', reqData, {
      baseURL: this.getConstructedUrl(),
    })
  }

  forgotPasswordOtp = async (
    reqData: ISendCodeUserDetails
  ): Promise<ISendCodeResponse> => {
    const response = await axios.post<ISendCodeResponse>(
      'cognito/send-forgotpassword-otp',
      reqData,
      { baseURL: this.getConstructedUrl() }
    )
    return response.data
  }

  resetPassword = async (
    reqData: IResetPasswordUserDetails
  ): Promise<IResetPasswordResponse> => {
    const response = await axios.post<IResetPasswordResponse>(
      'cognito/validateotp-changepassword',
      reqData,
      { baseURL: this.getConstructedUrl() }
    )
    return response.data
  }
}

export default new AllianceConnectServiceProvider()
