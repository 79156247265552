import { ReactElement } from 'react'
import styled from 'styled-components'
import { I18n } from 'src/i18n'
import { defaultTheme } from 'src/theme'

declare const __COMMIT_HASH__: string
function Footer(): ReactElement {
  const currentYear = new Date().getFullYear()
  return (
    <Container>
      <CopyrightNotice>
        &copy; 2006-{currentYear} <I18n name="aftermarketAutoPartsAlliance" />;{' '}
        <I18n name="allRightsReserved" />
      </CopyrightNotice>
      <VersionNumber>{__COMMIT_HASH__}</VersionNumber>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: flex-start;
  background: ${defaultTheme.colors.rowFeaturedBackground};
  box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media print {
    display: none;
  }
`

const CopyrightNotice = styled.div``

const VersionNumber = styled.div`
  color: ${defaultTheme.colors.almostBlack};
  font-weight: 700;
`
