import { Close } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { useSearchStore } from 'src/store/search/SearchStore'
import { defaultTheme } from 'src/theme'
import { zIndex } from 'src/theme/zIndex'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { CartVehicle } from 'src/store/models/ShoppingCartModels'

type IListCartVehicle = Omit<CartVehicle, 'products'>

const CartSelectorModal = observer((): React.ReactElement => {
  const {
    handleCloseCartSelectorModal,
    cartSelectorModal,
    setSelectedVehicle,
    addListPartsToCart,
  } = useListsStore()
  const onClose = handleCloseCartSelectorModal
  const show = cartSelectorModal
  const ref = useRef<HTMLInputElement>(null)
  const { vehicles } = useShoppingCart()
  const [allVehicles, setAllVehicles] = useState<IListCartVehicle[]>([
    ...vehicles,
  ])

  useClickOutsideDetector([ref], show, onClose)

  const { currentVehicle } = useSearchStore()

  useEffect(() => {
    const vehicleDetails = {
      vehicle: {
        ...currentVehicle,
      },
    }
    if (Object.keys(currentVehicle).length !== 0) {
      if (!vehicles.length) {
        setAllVehicles([vehicleDetails])
      } else {
        const index = vehicles.findIndex(
          (item) => item?.vehicle?.id === currentVehicle?.id
        )
        if (index < 0) setAllVehicles([vehicleDetails, ...vehicles])
        else setAllVehicles([...vehicles])
      }
    }
  }, [currentVehicle, vehicles])

  function defaultModal() {
    return (
      <Container ref={ref}>
        <FlexColumn style={{ paddingBottom: 20 }}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Title>
              <I18n name="selectCartToAddItem" />
            </Title>
            <StyledClose onClick={onClose} />
          </FlexRow>
        </FlexColumn>

        <Divider style={{ marginLeft: -24, marginRight: -24, height: 0.2 }} />
        <FlexColumn style={{ paddingBottom: 20, paddingTop: 34 }}>
          {allVehicles?.map(
            (item) =>
              // TODO: check if we can use `isMiscellaneousVehicle` here.
              item?.vehicle?.make?.value !== 'MISCELLANEOUS' && (
                <>
                  <ListAddToCar>
                    <ListAddToCarText>
                      {item?.vehicle?.year?.value}
                      {'\u00a0'}
                      {item?.vehicle?.model?.value}
                    </ListAddToCarText>
                    <ButtonWrapper>
                      <AddButton
                        onClick={() => {
                          setSelectedVehicle(item?.vehicle)
                          addListPartsToCart()
                          onClose()
                        }}
                        text="addToCart"
                      />
                    </ButtonWrapper>
                  </ListAddToCar>
                  <Divider
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  />
                </>
              )
          )}
        </FlexColumn>

        <FlexColumn>
          <ListAddToCar>
            <ListAddToCarText>
              <I18n name="miscellaneous" />
            </ListAddToCarText>
            <ButtonWrapper>
              <AddButton
                onClick={() => {
                  setSelectedVehicle(miscellaneousVehicle)
                  addListPartsToCart()
                  onClose()
                }}
                text="addToCart"
              />
            </ButtonWrapper>
          </ListAddToCar>
        </FlexColumn>
        <Divider style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }} />
      </Container>
    )
  }

  const width = window.innerWidth

  return (
    <ResponsiveDrawer
      anchor={width >= 1024 ? 'right' : 'bottom'}
      open={show}
      onClose={onClose}
      PaperProps={{
        square: false,
      }}
    >
      {defaultModal()}
    </ResponsiveDrawer>
  )
})

export default CartSelectorModal

const ResponsiveDrawer = styled(Drawer)`
  z-index: ${zIndex.modal};
`

const StyledClose = styled(Close)`
  font-size: 30px;
  cursor: pointer;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${defaultTheme.colors.componentsBackground};
  padding: 29px 24px 74px 24px;
  overflow-y: auto;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.span`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
const AddButton = styled(Button)`
  width: 100%;
`

const ListAddToCar = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  gap: 10px;
`

const ListAddToCarText = styled.div`
  font-weight: bold;
  width: 100%;
  p {
    margin: 0px;
  }
`
