import { clsx } from 'clsx'
import { ReactElement } from 'react'
import Select from 'react-select'
import styles from 'src/features/search/VehicleSearch/components/VehicleQuickFind/index.module.scss'
import type { Props } from 'react-select'

export const ReactSelect = (reactSelectProps: Props): ReactElement => {
  return (
    <Select
      {...reactSelectProps}
      classNames={{
        control: (state) => {
          const style = state.isFocused
            ? clsx(styles.controlClass_sta, styles.focused)
            : styles.controlClass_sta
          return style
        },
        indicatorSeparator: () => styles.indicatorSeparator_class,
        option: (state) => {
          const style = state.isSelected
            ? clsx(styles.option_class_sta, styles.selected_option_class_sta)
            : styles.option_class_sta
          return style
        },
      }}
    />
  )
}
