import { ReactElement, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import styled from 'styled-components'
import defaultLogoUrl from 'src/assets/images/logo.png'
import { Button, TextInput } from 'src/ui-components'
import { useHistory } from 'react-router-dom'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { LogoImage } from '../navigation/TopNavigation/components/CommonMenuItems'
import supportServiceProvider from 'src/services/SupportServiceProvider'

const ContactAdminScreen = (): ReactElement => {
  const { displayErrorNotification } = StoreInstances.uiStore
  const [resetRequested, setResetRequested] = useState(false)

  const [logoUrl] = useState(defaultLogoUrl)
  const [screenName, setScreenName] = useState('')
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const history = useHistory()

  const handleBackToLogin = (): void => {
    history.push('/login')
  }

  const handleRequestPasswordReset = async (): Promise<void> => {
    try {
      validate()
      const contactAdminInfo = {
        screenName,
        email,
        phoneWithExt: phone,
      }
      setButtonSpinner(true)
      const response =
        await supportServiceProvider.contactAdmin(contactAdminInfo)
      if (response.acknowledged) {
        setResetRequested(true)
        setScreenName('')
        setEmail('')
        setPhone('')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      displayErrorNotification(e.message)
      setResetRequested(false)
    } finally {
      setButtonSpinner(false)
    }
  }

  const validate = (): void => {
    if (!screenName) {
      throw new Error('screenNameRequired')
    }

    if (!email) {
      throw new Error('emailRequired')
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      throw new Error('invalidEmailAddress')
    }

    if (!phone) {
      throw new Error('phoneNumberRequired')
    }
  }

  return (
    <Wrapper>
      <Content>
        <>
          <LogoImageWrapper
            src={logoUrl}
            alt={Translate('myPlaceForPartsLogo')}
          />
          <h3>
            <I18n name="passwordReset" />
          </h3>
          {resetRequested ? (
            <>
              <InstructionsSpan>
                <I18n name="anAdminWillBeReachingOutToYou" />
              </InstructionsSpan>
              <BackToLoginButton
                onClick={handleBackToLogin}
                text="backToLogin"
                loading={false}
              />
            </>
          ) : (
            <>
              <InstructionsSpan>
                <I18n name="pleaseEnterYourContactDetailsAndAnAdminWillBeInContactWithYou" />
              </InstructionsSpan>
              <TextInputWrapper>
                <TextInput
                  placeholder="screenName"
                  label="screenName"
                  value={screenName}
                  onChange={(value) => setScreenName(value)}
                  width="100%"
                  noMargin
                />
              </TextInputWrapper>
              <TextInputWrapper>
                <TextInput
                  placeholder="bestEmailToReachYouAt"
                  label="emailAddress"
                  value={email}
                  onChange={(value) => setEmail(value)}
                  width="100%"
                  noMargin
                />
              </TextInputWrapper>
              <TextInputWrapper>
                <TextInput
                  placeholder="phoneNumber"
                  label="phoneNumber"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  width="100%"
                  noMargin
                  type="tel"
                />
              </TextInputWrapper>
              <RequestPasswordResetButton
                onClick={handleRequestPasswordReset}
                text="requestPasswordReset"
                loading={buttonSpinner}
              />
              <BackToLoginSection>
                <StyledText>
                  <Hyperlink action={handleBackToLogin} underlined={false}>
                    <I18n name="backToLogin" />
                  </Hyperlink>
                </StyledText>
              </BackToLoginSection>
            </>
          )}
        </>
      </Content>
    </Wrapper>
  )
}

export default ContactAdminScreen

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LogoImageWrapper = styled(LogoImage)`
  margin-bottom: 1.5rem;
`

const InstructionsSpan = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

const TextInputWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`

const RequestPasswordResetButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
`

const BackToLoginButton = styled(RequestPasswordResetButton)``

const BackToLoginSection = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`

const StyledText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
