import {
  AvailabilityError,
  PriceBreak,
  ProductModel,
} from '../models/ProductModel'

export enum Prices {
  COST = 'your price',
  CORE = 'core price',
  LIST = 'list price',
  CORE_LIST = 'core list price',
}

export interface ValidatedProduct {
  validations?: Array<AvailabilityError>
  product: ProductModel
}

export interface PartInfoRequest {
  lineNo: number
  lineCode: string
  manufacturerName: string
  partNumber: string
  brand: string
  description: string
  quantityRequested: number
  unitOfMeasure: string
  unitCorePrice: number
  unitCostPrice: number
  isPriceOverride: boolean
  priceOverrideMsg: string
  locationId: string
  locationDescription: string
}

export interface PartAvailabilityRequest {
  parts: Array<PartInfoRequest>
}

export interface PartInfoResponse {
  lineNo: 0
  lineCode: string
  status: string
  manufacturerName: string
  partNumber: string
  brand: string
  description: string
  locations: Array<LocationInfoResponse>
  height: number
  length: number
  weight: number
  width: number
}

export interface LocationInfoResponse {
  isSelected: true
  quantityRequested: number
  quantityAvailable: number
  unitOfMeasure: string
  unitCorePrice: number
  unitCostPrice: number
  unitListCore: number
  unitListPrice: number
  isPriceOverride: true
  priceOverrideMsg: string
  locationId: string
  locationDescription: string
  locationStatus: string
  minOrderQty: number
  incrementOrderQty: number
  priceBreaks?: Array<PriceBreak>
}

export interface PartAvailabilityResponse {
  parts: Array<PartInfoResponse>
}
