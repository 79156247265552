import { Checkbox } from 'src/ui-components/forms/Checkbox'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Button, ButtonVariant } from 'src/ui-components/forms/Button'
import { useModal } from '@ebay/nice-modal-react'
import { chunk } from 'lodash-es'
import { Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import { Translate2 } from 'src/i18n/translate2'
import { Sanitized } from 'src/features/search/Results/utils/Sanitized'
import { Close } from '@mui/icons-material'
import { useWindowSize } from 'src/hooks'
import { CircularProgress } from '@mui/material'
import { defaultTheme } from 'src/theme'

interface PartTypeComponentProps {
  title: string
  subtitle: string
  borderBottomHeader: boolean
  selectedPartTypeOptions: string[]
  partTypeToggleSelectAll: () => void
  filtredInterchangeOptions: string[]
  borderTopFooter?: boolean
  PartTypeDontAskThisAgainAction: () => void
  doNotAskPartType: boolean
  primaryButtonToTheLeft: boolean
  buttonsDirection?: 'column' | 'row'
  partTypeSkipHandler: () => void
  partTypeSelectionHandler: () => void
  primaryButtonVariant: ButtonVariant
  primaryButtonIcon?: React.ReactElement
  partTypeCheckboxHandler: (value: string) => void
  isSelectedAllPartTypeOptions: boolean
}

const PartTypesComponent = ({
  title,
  subtitle,
  borderBottomHeader,
  selectedPartTypeOptions,
  partTypeToggleSelectAll,
  filtredInterchangeOptions,
  borderTopFooter,
  doNotAskPartType,
  PartTypeDontAskThisAgainAction,
  primaryButtonToTheLeft,
  buttonsDirection,
  partTypeSkipHandler,
  partTypeSelectionHandler,
  primaryButtonVariant,
  primaryButtonIcon,
  partTypeCheckboxHandler,
  isSelectedAllPartTypeOptions,
}: PartTypeComponentProps): JSX.Element => {
  const modal = useModal()
  const currentWindowSize = useWindowSize()
  const numberOfpartTypesPerRow =
    currentWindowSize.width <= 430
      ? filtredInterchangeOptions.length
      : filtredInterchangeOptions.length <= 22
        ? 11
        : Math.ceil(filtredInterchangeOptions.length / 2)

  const createColumnWithRows = (data: string[], index: number) => (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '16px',
      }}
      key={index}
    >
      {data?.map((partType, index) => (
        <Checkbox
          key={`${partType}_${index}`}
          onClick={() => partTypeCheckboxHandler(partType)}
          value={selectedPartTypeOptions.includes(partType).toString()}
          label={`${partType}`}
          active={selectedPartTypeOptions.includes(partType)}
        />
      ))}
    </div>
  )

  return (
    <>
      <Header
        onHide={() => {
          modal.remove()
        }}
        $borderBottomHeader={borderBottomHeader}
      >
        <>
          <Title>
            <Sanitized html={Translate2(`${title}`, [`${subtitle}`])} />
          </Title>
          <Close
            style={{
              color: '#0F172A',
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
            onClick={() => {
              modal.remove()
            }}
          />
        </>
      </Header>
      <Body className="modal-body">
        <PartTypeWrapper>
          <ChoosePartTypeWrapper>
            {Translate('choosePartType')}
          </ChoosePartTypeWrapper>
          {filtredInterchangeOptions.length > 0 && (
            <SelectAllWrapper>
              <AnchorTagWrapper
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  partTypeToggleSelectAll()
                }}
              >
                {isSelectedAllPartTypeOptions
                  ? Translate('selectAll')
                  : Translate('unSelectAll')}
              </AnchorTagWrapper>
            </SelectAllWrapper>
          )}
        </PartTypeWrapper>
        {filtredInterchangeOptions.length > 0 ? (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                maxHeight: '53.5vh',
                overflowY: 'auto',
              }}
            >
              {chunk(filtredInterchangeOptions, numberOfpartTypesPerRow).map(
                (searchedPartTypeData, index) =>
                  createColumnWithRows(searchedPartTypeData, index)
              )}
            </div>
          </div>
        ) : (
          <CircularProgress
            style={{
              marginLeft: '50%',
              marginRight: '50%',
              marginTop: '10%',
            }}
          />
        )}
      </Body>
      <Footer $borderTopFooter={borderTopFooter}>
        <div>
          <Checkbox
            value="true"
            label={Translate2(`dontAskPartTypeAgain`)}
            onClick={PartTypeDontAskThisAgainAction}
            active={!doNotAskPartType}
          />
        </div>

        <ButtonsWrapper
          primaryButtonToTheLeft={primaryButtonToTheLeft}
          buttonsDirection={buttonsDirection}
        >
          <Button
            onClick={() => {
              partTypeSkipHandler()
            }}
            minWidth="80px"
            text="skipAndSeeAllResults"
            variant="secondary"
          />
          <Button
            minWidth="80px"
            onClick={partTypeSelectionHandler}
            text="next"
            disabled={selectedPartTypeOptions.length === 0 && doNotAskPartType}
            variant={primaryButtonVariant}
            icon={primaryButtonIcon}
          />
        </ButtonsWrapper>
      </Footer>
    </>
  )
}

export default PartTypesComponent

interface HeaderProps {
  $borderBottomHeader: boolean
}

const Header = styled(Modal.Header)<HeaderProps>`
  border-bottom: 1px solid #d3d2cf;
  ${(p) =>
    p.$borderBottomHeader
      ? `box-shadow: inset 0 -1px 0 #d3d2cf;
           width: 100%;`
      : ''};
`

const Title = styled(Modal.Title)`
  font-family: Oswald, serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
`

interface FooterProps {
  $borderTopFooter: boolean
}

const Footer = styled(Modal.Footer)<FooterProps>`
  border-top: none;
  font-family: Oswald, serif;
  justify-content: space-between;
  ${(p) =>
    p.$borderTopFooter
      ? `box-shadow: inset 0 1px 0 #d3d2cf;
           width: 100%;`
      : ''};
`
interface ButtonsWrapperProps {
  primaryButtonToTheLeft: boolean
  buttonsDirection: 'column' | 'row'
}
const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  width: ${(p) => (p.buttonsDirection === 'column' ? '100%' : 'auto')};
  @media screen and (max-width: ${breakpoints.mobileSmall}px) {
    flex-wrap: wrap;
  }
`
const Body = styled.div`
  font-family: Roboto, serif;
  font-size: 18px;
  padding-bottom: 0;
  padding-top: 1rem;
  min-height: 470px;
  border-bottom: 1px solid #d3d2cf;
`

const PartTypeWrapper = styled.div`
  width: 100%;
`

const ChoosePartTypeWrapper = styled.p`
  font-family: Oswald, serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
`
const SelectAllWrapper = styled.p`
  color: blue;
  font-size: 16px;
  line-height: 24px;
`
const AnchorTagWrapper = styled.a`
  color: ${defaultTheme.colors.blueLight};
  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
  font-family: Roboto, serif;
`
