import { ReactElement, ReactNode } from 'react'
import { AstPunchOutContext } from './astPunchOut/AstPunchOutStore'
import { ShoppingCartContext } from './cart/ShoppingCart'
import { JobsCatalogContext } from './jobs/JobsStore'
import { BannerPromotionContext } from './promotion/BannerPromotionStore'
import { QuoteContext } from './quote/QuoteStore'
import { SearchStoreContext } from './search/SearchStore'
import { SpecificConditionsContext } from './specificConditions/SpecificConditionsStore'
import { StoreInstances } from './StoreInstancesContainer'
import { VehicleSpecificationContext } from './vehicleSpecification/VehicleSpecificationStore'
import { PartsCatalogContext } from './partsCatalog/PartsCatalog'
import { LaborLookupContext } from './labor/LaborStore'
import { UiStateStoreContext } from './uiState/UiStateStore'
import { UserStoreContext } from './user/UserStore'
import { LanguageContextStore } from './language/LanguageContext'
import { VehicleMakesStoreContext } from 'src/features/search/VehicleSearch/store/VehicleMakesStore.ts'

export const Store = ({ children }: { children: ReactNode }): ReactElement => {
  StoreInstances.init()
  return (
    <UiStateStoreContext.Provider value={StoreInstances.uiStore}>
      <UserStoreContext.Provider value={StoreInstances.userStore}>
        <SearchStoreContext.Provider value={StoreInstances.searchStore}>
          <VehicleMakesStoreContext.Provider
            value={StoreInstances.vehicleMakesStore}
          >
            <AstPunchOutContext.Provider
              value={StoreInstances.astPunchOutStore}
            >
              <JobsCatalogContext.Provider value={StoreInstances.jobsCatalog}>
                <PartsCatalogContext.Provider
                  value={StoreInstances.partsCatalog}
                >
                  <LaborLookupContext.Provider
                    value={StoreInstances.laborStore}
                  >
                    <SpecificConditionsContext.Provider
                      value={StoreInstances.specificConditions}
                    >
                      <ShoppingCartContext.Provider value={StoreInstances.cart}>
                        <LanguageContextStore
                          userStoreRef={StoreInstances.userStore}
                        >
                          <BannerPromotionContext.Provider
                            value={StoreInstances.bannerPromotionStore}
                          >
                            <QuoteContext.Provider
                              value={StoreInstances.quoteStore}
                            >
                              <VehicleSpecificationContext.Provider
                                value={StoreInstances.vehicleSpecificationStore}
                              >
                                {children}
                              </VehicleSpecificationContext.Provider>
                            </QuoteContext.Provider>
                          </BannerPromotionContext.Provider>
                        </LanguageContextStore>
                      </ShoppingCartContext.Provider>
                    </SpecificConditionsContext.Provider>
                  </LaborLookupContext.Provider>
                </PartsCatalogContext.Provider>
              </JobsCatalogContext.Provider>
            </AstPunchOutContext.Provider>
          </VehicleMakesStoreContext.Provider>
        </SearchStoreContext.Provider>
      </UserStoreContext.Provider>
    </UiStateStoreContext.Provider>
  )
}
