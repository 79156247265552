import { AccumulatorVisitor } from './AccumulatorVisitor'
import { CatalogNodeType } from '../interfaces'

export class DepthCheckVisitor extends AccumulatorVisitor {
  constructor() {
    super((node) => node.isSelected)
  }

  public getMaxSelectedDepth = (): CatalogNodeType => {
    let maxDepth = 0
    for (const node of this.getAll()) {
      maxDepth = Math.max(maxDepth, node.type)
    }
    return maxDepth
  }
}
