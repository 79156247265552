import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { TopNavigationBar } from 'src/features'
import {
  BlackBackground,
  LogoImage,
  WhiteBackground,
} from 'src/features/navigation/TopNavigation/components/CommonMenuItems'

import { SearchField } from 'src/features/search/AllProductsSearch/components/SearchField'
import ContactUs from 'src/features/navigation/header/contactUs/ContactUs'
import {
  ISelectedOption,
  LanguageDropdown,
} from 'src/features/search/AllProductsSearch/LanguagesDropdown'
import { Translate } from 'src/i18n'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { HeaderProps } from './HeaderProps'
import AstPunchOutMenuItems from '../TopNavigation/components/MenuItemLinks/AstPunchOutMenuItems'
import {
  CatalogIndexTracker,
  CatalogLookupType,
} from 'src/api/metrics/CatalogIndexTracker'
import { SaytOption } from 'src/api/searchResults/interfaces'
import { isMobile } from 'react-device-detect'
import { useUserStore } from 'src/store/user/UserStore'
import { options } from 'src/features/search/AllProductsSearch/constants'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'
import AccountMenu from '../TopNavigation/components/accountMenu'
import { Translate2 } from 'src/i18n/translate2'

const AstHeader = observer((props: HeaderProps): ReactElement => {
  const { logoUrl } = props
  const { searchStore } = StoreInstances
  const { addBreadCrumb, cleanBreadCrumbs, breadCrumbs } = searchStore
  const history = useHistory()
  const user = useUserStore()
  const handleAddBreadCrumb = (breadcrumb) => {
    if (breadCrumbs.length === 0) {
      addBreadCrumb(breadcrumb)
    } else {
      cleanBreadCrumbs()
      addBreadCrumb(breadcrumb)
    }
  }
  const [language, setLangauge] = useState<ISelectedOption>(
    options.find((item) => item.key === user.preferences.language) || options[0]
  )
  const searchByText = (text: string): void => {
    searchStore.setShowPartsNotFitVehicleLabel(true)
    handleAddBreadCrumb({
      page: Translate2('allProducts'),
      breadcumb: text,
      url: () => searchStore.searchByText(text),
    })
    CatalogIndexTracker.setIndex(CatalogLookupType.AST_NO_MATCH)
    searchStore.searchByText(text)
    history.push('/searchResults')
  }

  const onSearch = (text: string): void => {
    searchByText(text)
  }

  const onSelectedOption = (saytOption: SaytOption): void => {
    searchByText(saytOption.label)
  }

  const onChangeLanguage = (selectedLanguage: ISelectedOption) => {
    const reqData = [
      {
        uuid: 'DEFAULT_LANGUAGE',
        domId: Number(user.preferences.userId),
        domType: 5,
        attrValue: selectedLanguage.key,
      },
    ]

    AttributesServiceProvider.saveOrUpdateAttribute(reqData)
    setLangauge(selectedLanguage)
  }

  return (
    <>
      <TopNavigationBar>
        <BlackBackground>
          {/* <MobileHamburguerMenu /> */}
          <NavLink to="/astPartsCatalog">
            <LogoImage src={logoUrl} alt="My place 4 parts logo" />
          </NavLink>
          <AstPunchOutMenuItems />
        </BlackBackground>
        <WhiteBackground>
          <AccountMenu />
        </WhiteBackground>
      </TopNavigationBar>

      <SubHeaderSection>
        <ASTSearchBox>
          <SearchField
            label={
              isMobile
                ? Translate('ast')
                : Translate('accessoriesSuppliesAndTools')
            }
            placeholder={Translate('searchPartBrandName')}
            onSearch={onSearch}
            onSelectOption={onSelectedOption}
            type="astOnly"
          />
        </ASTSearchBox>
        <RightWrapper>
          <ContactUs />
          <LanguageDropdown
            onLanguageChange={onChangeLanguage}
            selectedLanguage={language}
          />
        </RightWrapper>
      </SubHeaderSection>
    </>
  )
})

export default AstHeader

const SubHeaderSection = styled.div`
  width: 100%;
  height: 100px;
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.3);
  background-color: ${defaultTheme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${isMobile ? '0 15px' : '0 0 0 15px'};
`

const RightWrapper = styled.div`
  display: flex;
  margin: 20px 0px 0px 0px;
  align-items: baseline;
  flex-wrap: wrap;

  p {
    margin: 0px;
  }
`

const ASTSearchBox = styled.div`
  width: 519px;
`
