export interface IOrderTypeOptions {
  value: string
  label: string
}

export const orderTypeOptions: IOrderTypeOptions[] = [
  { value: 'R', label: 'Regular' },
  { value: 'U', label: 'Urgent' },
  /* Add courier Type if required or generate this entire object dynamically from transports API
  { value: 'C', label: 'Courier' },
  */
]
