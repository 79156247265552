import { Filter, Visitor } from './interfaces'
import { CatalogNode } from '../CatalogNode'

export class AccumulatorVisitor implements Visitor {
  private readonly visited: Array<CatalogNode>

  private readonly filter: Filter | null

  constructor(filter: Filter | null = null) {
    this.filter = filter
    this.visited = []
  }

  public visit(node: CatalogNode): void {
    if (this.filter) {
      const passes = this.filter(node)
      if (passes) {
        this.visited.push(node)
      }
    } else {
      this.visited.push(node)
    }
  }

  public getAll(): Array<CatalogNode> {
    return this.visited
  }
}
