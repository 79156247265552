import US from 'src/assets/images/US.png'
import MX from 'src/assets/images/MX.png'
import CA from 'src/assets/images/CA.png'
/* Fix to be done: we should use svgs instead of emojis */
export const options = [
  {
    label: 'english',
    key: 'en_US',
    imgPath: US,
  },
  {
    label: 'spanish',
    key: 'es_MX',
    imgPath: MX,
  },
  {
    label: 'french',
    key: 'fr_CA',
    imgPath: CA,
  },
]
