import { enUs } from './en_us'
import { esMx } from './es_mx'
import { frCa } from './fr_ca'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

const showMissingTranslations =
  import.meta.env.VITE_SHOW_MISSING_TRANSLATIONS === 'true'

export const Translate2 = (
  key: string,
  textReplacement?: Array<string | number>
): string => {
  const selectedLanguage =
    StoreInstances.userStore?.preferences?.language ?? 'en_US'

  let translated
  switch (selectedLanguage) {
    case 'en_US':
      translated = enUs[key]
      break
    case 'es_MX':
      translated = esMx[key]
      break
    case 'fr_CA':
      translated = frCa[key]
      break
    default:
      translated = enUs[key]
  }
  if (
    translated &&
    translated.indexOf('%') > -1 &&
    textReplacement !== undefined
  ) {
    for (let i = 0; i < textReplacement.length; i += 1) {
      translated = translated.replace('%', textReplacement[i])
    }
  }
  if (showMissingTranslations && translated) {
    return `TRANSLATION -> ${key}`
  }

  return translated === undefined ? key : translated
}
