import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FieldArray } from 'formik'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import CustomField from 'src/ui-components/forms/formik/InputField'
import styled from 'styled-components'
import { AddGroupPart } from 'src/store/lists/interfaces'

interface AddProductsToGroupTableProps {
  values: {
    items: Array<AddGroupPart>
  }
  validateNumberIntervalRequired?: (
    value: Array<unknown>,
    rowHasEntries: boolean,
    min: number,
    max: number
  ) => string
  validateLengthIntervalRequired?: (
    value: Array<unknown>,
    rowHasEntries: boolean,
    min: number,
    max: number
  ) => string
}

export const AddProductsToGroupTable = observer(
  (props: AddProductsToGroupTableProps): ReactElement => {
    const {
      values,
      validateLengthIntervalRequired,
      validateNumberIntervalRequired,
    } = props
    const isTablet = window.innerWidth <= breakpoints.tablet

    return (
      <>
        <DesktopContent>
          <Rows>
            <FieldArray
              name="items"
              render={(arrayHelpers) => {
                return values.items.map((item: AddGroupPart, index: number) => {
                  const rowHasEntries =
                    !!item.partNumber || item.stockQty !== 1 || !!item.comment

                  return (
                    <InputWrapper key={index}>
                      <TextInputWrapper width="15%">
                        <CustomField
                          name={`items[${index}].lineCode`}
                          type="text"
                          style={{ textTransform: 'uppercase' }}
                          maxSize={3}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="34%">
                        <CustomField
                          name={`items[${index}].partNumber`}
                          validate={(value) =>
                            validateLengthIntervalRequired(
                              value,
                              rowHasEntries,
                              1,
                              20
                            )
                          }
                          type="text"
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="14%">
                        <CustomField
                          name={`items[${index}].stockQty`}
                          validate={(value) =>
                            validateNumberIntervalRequired(
                              value,
                              rowHasEntries,
                              0 /* 0 must be valid and no negative values */,
                              999
                            )
                          }
                          type="number"
                          maxSize={3}
                          min={0}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="35%">
                        <CustomField
                          name={`items[${index}].comment`}
                          type="textarea"
                          maxSize={45}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper
                        width="5%"
                        style={{
                          display: isTablet ? 'none' : 'flex',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            return arrayHelpers.remove(index)
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TextInputWrapper>
                    </InputWrapper>
                  )
                })
              }}
            />
          </Rows>
        </DesktopContent>

        <MobileContent>
          <Rows>
            <FieldArray
              name="items"
              render={(arrayHelpers) => {
                return values.items.map((item: AddGroupPart, index: number) => {
                  const rowHasEntries =
                    !!item.partNumber || item.stockQty !== 1 || !!item.comment

                  return (
                    <InputWrapper key={index}>
                      <TextInputWrapper width="15%">
                        <CustomField
                          name={`items[${index}].lineCode`}
                          type="text"
                          style={{ textTransform: 'uppercase' }}
                          maxSize={3}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="34%">
                        <CustomField
                          name={`items[${index}].partNumber`}
                          validate={(value) =>
                            validateLengthIntervalRequired(
                              value,
                              rowHasEntries,
                              1,
                              20
                            )
                          }
                          type="text"
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="14%">
                        <CustomField
                          name={`items[${index}].stockQty`}
                          validate={(value) =>
                            validateNumberIntervalRequired(
                              value,
                              rowHasEntries,
                              0 /* 0 must be valid and no negative values */,
                              999
                            )
                          }
                          type="number"
                          maxSize={3}
                          min={0}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper width="35%">
                        <CustomField
                          name={`items[${index}].comment`}
                          type="textarea"
                          maxSize={45}
                        />
                      </TextInputWrapper>
                      <TextInputWrapper
                        width="5%"
                        style={{
                          display: isTablet ? 'none' : 'flex',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            return arrayHelpers.remove(index)
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TextInputWrapper>
                    </InputWrapper>
                  )
                })
              }}
            />
          </Rows>
        </MobileContent>
      </>
    )
  }
)
interface TextInputWrapperProps {
  width: string
}
const TextInputWrapper = styled.div<TextInputWrapperProps>`
  padding: 16px 16px 16px 0px;
  width: ${(p) => p.width};
`
const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${defaultTheme.colors.borderTable};
`
const DesktopContent = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: 100vh;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const MobileContent = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.laptop}px) {
    display: flex;
  }
`

const Rows = styled.div`
  display: block;
  height: 100%;
  max-width: 750px;
`
