import { sortBy } from 'lodash-es'
import {
  ProductLocationModel,
  ProductModel,
} from 'src/store/models/ProductModel'
import { SearchResultsResponse } from 'src/store/models/SearchModels'
import {
  AlternateLocation,
  LocalInventoryMessage,
  Part,
  PartsBin,
} from '../types/localInventory'

const getPrimaryLocation = (
  locations: ProductLocationModel[]
): ProductLocationModel => {
  return locations.find((loc) => loc.locationId === '100') || locations[0]
}

/**
 * @public prepares data for the parts dispaly panel and intializes the
 *         partsGridPanel with the partsGridPanelId
 * @param xmlResponse
 * @param isViaSearch
 * @param partsGridPanelid
 * @returns Updated parts data to support
 */
export const aesPartsToaesServerParts = (partsData: PartsBin): PartsBin => {
  const cartDataArr: PartsBin = JSON.parse(JSON.stringify(partsData))

  const partBasket: PartsBin = {
    ...cartDataArr,
    part: [],
    searchQualifier: cartDataArr.searchQualifier,
  }

  // add all fields of alternatePart as an item in cartDataArr.part
  if (cartDataArr && cartDataArr.part) {
    //#8423  V1.34
    for (let j = 0; j < cartDataArr.part.length; j++) {
      /**
       * Add internal parameters.
       * @partTypeNumber - partNumber for that part (Not using now but added for future implementations)
       * @parentPartNumber - empty for original part and original partNumber for alternate or replacement parts. - Used for parts count.
       */

      if (cartDataArr.part[j].partNumber) {
        cartDataArr.part[j].partTypeNumber = cartDataArr.part[j].partNumber
        cartDataArr.part[j].parentPartNumber = ''
        cartDataArr.part[j].parentPartDescription = '' //7797
        cartDataArr.part[j].myDescription =
          cartDataArr.part[j].description +
          '_' +
          cartDataArr.part[j].partNumber +
          '_' +
          cartDataArr.part[j].manufacturerCode //7797//8153
        cartDataArr.part[j].altRplPart = false //8230
      }

      //#8384

      const alternateParts = cartDataArr.part[j].alternatePart
      cartDataArr.part[j].alternatePart = []

      partBasket.part.push(cartDataArr.part[j])

      alternateParts.forEach(function (item) {
        if (item.partNumber) {
          item.partTypeNumber = item.partNumber
          item.parentPartNumber = cartDataArr.part[j].partNumber
          item.parentPartDescription =
            cartDataArr.part[j].description +
            '_' +
            cartDataArr.part[j].partNumber +
            '_' +
            cartDataArr.part[j].manufacturerCode //7797//8153
          if (item.description) {
            item.myDescription =
              item.description +
              '_' +
              item.partNumber +
              '_' +
              item.manufacturerCode //1.48 #24100
          } else {
            item.myDescription = ' '
          }
          item.altRplPart = true //8230
        }

        partBasket.part.push(item)
      })
    }
  }

  return partBasket
}

const extractAlternateParts = (partsData: Part[]): Part[] => {
  const newPartsData: Part[] = []
  for (const part of partsData) {
    const alternateLocations = part.alternatePart
    newPartsData.push({ ...part, alternatePart: [] })
    for (const altPart of alternateLocations || []) {
      newPartsData.push({ ...altPart })
    }
  }
  return newPartsData
}

export const partsBasketToSearchResults = (
  partsData: PartsBin,
  originalPartsData: ProductModel[]
): ProductModel[] => {
  const tempPartsData = extractAlternateParts(partsData.part || [])
  try {
    const data = originalPartsData.map((part) =>
      inventoryPartToProduct(part, tempPartsData)
    )
    // console.log(data)
    return data
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(partsData)
    // eslint-disable-next-line no-console
    console.log('Failed to convert the partsBasket to products')
    // eslint-disable-next-line no-console
    console.log(e)
    return []
  }
}

export const searchResultsToPartsBasket = (
  results: SearchResultsResponse
): LocalInventoryMessage => {
  const partsData = {
    responseType: null,
    schemaVersion: null,
    orderNumber: null,
    part: (results.parts || []).map(productToInventoryPart),
    ponumber: null,
    searchQualifier: {
      searchTxt: '',
      vehicleQualifier: {},
    },
  }
  return {
    localInventory: true,
    partsData: aesPartsToaesServerParts(partsData),
  }
}

const inventoryPartToProduct = (
  part: ProductModel,
  alteredParts: Part[]
): ProductModel => {
  const inventoryPart = alteredParts.find(
    (altPart) =>
      altPart.manufacturerCode === part.lineCode &&
      altPart.partNumber === part.partNumber
  )
  if (!inventoryPart) {
    return {
      ...part,
      alternateParts: (part.alternateParts || []).map((altPart) =>
        inventoryPartToProduct(altPart, alteredParts)
      ),
      replacementParts: (part.replacementParts || []).map((replacementPart) =>
        inventoryPartToProduct(replacementPart, alteredParts)
      ),
    }
  }

  const { alternateLocation, description } = inventoryPart

  const originalLocations: ProductLocationModel[] = [...(part.location || [])]

  /**
   * Flag to track if the host replaced the location in the part.
   * Any location with locationId less than 100 is from host.
   */
  const isPartLocationReplaced = Number(inventoryPart.locationId) < 100

  /**
   * Array to collect the locations added by the host.
   * Any location with locationId less than 100 is from host.
   */
  const hostLocations: AlternateLocation[] = []

  /**
   * Some host application (eg: our aesShost.dll) only return available quantity.
   * Use the reference location to set the costs and any other info.
   */
  let referenceLocation: ProductLocationModel =
    originalLocations.find((location) => location.locationId === '100') ||
    originalLocations[0]

  if (isPartLocationReplaced) {
    referenceLocation = getHostLocationFromPartOrLocation(
      inventoryPart,
      referenceLocation
    )
    hostLocations.push({
      ...inventoryPart,
      isBuyDirect: false,
      altBuyIncr: false,
    })
  }

  alternateLocation.forEach((altLocation) => {
    if (Number(altLocation.locationId) < 100) {
      hostLocations.push(altLocation)
    }
  })

  const transformedHostLocations: ProductLocationModel[] = hostLocations.map(
    (hostLoc): ProductLocationModel => {
      return getHostLocationFromPartOrLocation(hostLoc, referenceLocation)
    }
  )

  let sortedLocations = sortBy(
    [...transformedHostLocations, ...originalLocations],
    'locationId'
  )

  sortedLocations = sortedLocations.map((productLocation) => ({
    ...productLocation,
    isSelected: false,
  }))

  let locationSelected = false

  for (const location of sortedLocations) {
    if (location.qtyAvailable >= (location.displayMinQty || 1)) {
      location.isSelected = true
      locationSelected = true
      break
    }
  }
  if (!locationSelected) {
    sortedLocations[0] = { ...sortedLocations[0], isSelected: true }
  }

  return {
    ...part,
    description,
    location: sortedLocations,
    alternateParts: (part.alternateParts || []).map((altPart) =>
      inventoryPartToProduct(altPart, alteredParts)
    ),
    replacementParts: (part.replacementParts || []).map((replacementPart) =>
      inventoryPartToProduct(replacementPart, alteredParts)
    ),
  }
}

// TODO: check if we can merge this and `orderRespItemToPBItem`.
const productToInventoryPart = (part: ProductModel): Part => {
  const { location, alternateParts, replacementParts, ...rest } = part
  const primaryLocation = productLocationToPartLocation(
    getPrimaryLocation(location || [])
  )
  const alternateLocation = (location || [])
    .filter((loc) => loc.locationId !== primaryLocation.locationId)
    .map(productLocationToPartLocation)

  return {
    ...rest,
    alternateLocation,
    manufacturerCode: part.lineCode || '',
    partCategory: '',
    quantityAvailable: primaryLocation.quantityAvailable,
    unitCorePrice: primaryLocation.unitCorePrice,
    unitCostPrice: primaryLocation.unitCostPrice,
    unitListPrice: primaryLocation.unitListPrice,
    unitListCore: primaryLocation.unitListCore,
    priceOverride: false,
    priceOverrideMsg: '',
    locationId: primaryLocation.locationId,
    locationDescription: primaryLocation.locationDescription,
    locationViewOnly: primaryLocation.locationViewOnly,
    minOrderQty: primaryLocation.minOrderQty,
    qtyBuyIncr: primaryLocation.qtyBuyIncr,
    unitOfMeasure: primaryLocation.unitOfMeasure,
    alternatePart: [...(alternateParts || []), ...(replacementParts || [])].map(
      productToInventoryPart
    ),
    brandId: 0,
    partId: '0',
    buyQty: primaryLocation.buyQty,
    isSelected: primaryLocation.isSelected,
    gfx: part.isGfx || false,
    partNumber: part.partNumber || '',
    brand: part.brand || '',
    description: part.description || '',
    status: part.status || '',
    attribute: [],
  }
}

const getHostLocationFromPartOrLocation = (
  part: Part | AlternateLocation,
  referenceLocation: ProductLocationModel
): ProductLocationModel => {
  return {
    called: part.locationDescription,
    coreCost: part.unitCorePrice || referenceLocation.coreCost,
    coreList: part.unitListCore || referenceLocation.coreList,
    cost: part.unitCostPrice || referenceLocation.cost,
    isSelected: false,
    list: part.unitListPrice || referenceLocation.list,
    locationId: part.locationId,
    qtyAvailable: part.quantityAvailable,
    buyIncrement: part.qtyBuyIncr || referenceLocation.buyIncrement,
    locType: '',
    buyQty: part.buyQty || referenceLocation.buyQty,
    displayMinQty: part.minOrderQty || referenceLocation.displayMinQty,
    estimatedDelivery: '',
    minQty: part.minOrderQty || referenceLocation.minQty,
    originalBuyIncrement:
      part.qtyBuyIncr || referenceLocation.originalBuyIncrement,
    originalUom: part.unitOfMeasure || referenceLocation.originalUom,
    otherPrices: [],
    unitOfMeasure: part.unitOfMeasure || referenceLocation.unitOfMeasure,
  }
}

const productLocationToPartLocation = (
  location: ProductLocationModel
): AlternateLocation => {
  return {
    ...location,
    quantityAvailable: location.qtyAvailable,
    locationDescription: location.called,
    locationViewOnly: false,
    minOrderQty: location.minQty || 1,
    qtyBuyIncr: location.buyIncrement || 1,
    isBuyDirect: true,
    unitCorePrice: location.coreCost,
    unitCostPrice: location.cost,
    unitListPrice: location.list,
    unitListCore: location.coreList,
    isSelected: location.isSelected,
    altBuyIncr: false,
    unitOfMeasure: location.unitOfMeasure || '',
    buyQty: location.buyQty || 1,
  }
}
