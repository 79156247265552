import { SwapHoriz } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import { I18n } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { zIndex } from 'src/theme/zIndex'

interface InterchangeOverlayProps {
  wrapperRef: React.RefObject<HTMLInputElement>
}

export const InterchangeOverlay = (
  props: InterchangeOverlayProps
): ReactElement => {
  const { wrapperRef } = props

  return (
    <ResultsWrapper data-testid="results" ref={wrapperRef}>
      <DataWrapper>
        <DisclaimerWrapper>
          <SwapHorizStyled />
          <DisclaimerText>
            <I18n name="interchangeDisclaimer" />
          </DisclaimerText>
        </DisclaimerWrapper>
      </DataWrapper>
    </ResultsWrapper>
  )
}

const ResultsWrapper = styled.div`
  width: 359px;
  top: 110%;
  min-height: 36px;
  position: absolute;
  background: white;
  border-radius: 3px;
  border: 1px solid ${defaultTheme.colors.border};
  z-index: ${zIndex.dropdown};
  left: 0;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const DisclaimerWrapper = styled.div`
  height: 31px;
  width: 100%;
  background: ${defaultTheme.colors.disclaimerBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`

const SwapHorizStyled = styled(SwapHoriz)`
  color: ${defaultTheme.colors.primary};
  font-size: 8px;
  margin-right: 6px;
`

const DisclaimerText = styled.span`
  /* P Tiny / Default */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  color: #000000;
`
