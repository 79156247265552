import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { I18n } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import { Button, SimpleModal } from 'src/ui-components'
import styled from 'styled-components'

import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import { PromotionResponse } from 'src/api/promotion/interface'

interface BannerPromotionProps {
  promotionData: PromotionResponse[]
}

export const BannerPromotion = createNiceModal<BannerPromotionProps>(
  observer(({ promotionData }): ReactElement => {
    const modal = useModal()

    const firstPromotionData =
      promotionData && promotionData.length > 0 ? promotionData[0] : undefined

    if (!firstPromotionData) {
      return null
    }
    const promotionLink = firstPromotionData?.promotionLinks[0]?.url

    return (
      <SimpleModal
        show={modal.visible}
        title=""
        primaryButtonVariant="primary"
        size="lg"
        onSecondaryAction={() => {
          modal.remove()
        }}
      >
        <MainContainer>
          <PromotionImage
            src={firstPromotionData?.splashScreen}
            alt="Promotion Image"
          />
          <DetailsContainer>
            <StyledText>
              <I18n
                name="eachAndEveryPerfectStopProductPurchaseearnsanenrty"
                style={{ fontSize: '22px' }}
              />
              <I18n
                name="visitTheWebsiteForOrderDetails"
                style={{ fontSize: '15px', marginTop: '15px' }}
              />
            </StyledText>
            {promotionLink && (
              <Button
                text="viewDetails"
                onClick={() => window.open(promotionLink)}
                variant="primary"
                size="small"
                style={{ marginTop: '15px' }}
              />
            )}
          </DetailsContainer>
        </MainContainer>
      </SimpleModal>
    )
  })
)

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`
const DetailsContainer = styled.div`
  flex-direction: column;
  margin-left: 15px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    align-items: center;
    display: flex;
  }
`
interface StyledTextProps {
  lightText?: boolean
  normal?: boolean
}
const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => (p.normal ? '13px' : '22px')};
  font-family: ${(p) => (p.normal ? 'Roboto, serif' : 'Oswald, serif')};
  color: ${(p) => (p.lightText ? 'gray' : 'black')};
  font-weight: ${(p) => (p.normal ? '500' : 'bold')};
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    align-items: center;
    text-align: center;
  }
`
const PromotionImage = styled.img`
  width: 300px;
  height: 300px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    min-width: 25vw;
  }
`
