import { useEffect, ReactElement } from 'react'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CheckboxSelections } from 'src/features/partsCatalog/Selections/components/CheckboxSelections'
import {
  VisibleStep,
  usePartsCatalog,
} from 'src/store/partsCatalog/PartsCatalog'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { CatalogNodeType } from 'src/store/partsCatalog'
import styled from 'styled-components'

const PartsCatalog = observer((): ReactElement => {
  const { lookup: laborPartsCatalog } = useLaborLookup()
  const visibleStep = laborPartsCatalog.getVisibleStep()
  const categoryGroups = laborPartsCatalog.categoryGroups()
  const { categories, fetchCategories, groupPartTypes } = laborPartsCatalog
  const partsCatalog = usePartsCatalog()
  const { fetchGroups, resetStore } = laborPartsCatalog

  useEffect(() => {
    if (partsCatalog.categories && laborPartsCatalog) {
      partsCatalog.categories.forEach((item) => {
        if (item.selected) {
          laborPartsCatalog?.selectElement(item.id, CatalogNodeType.CATEGORY)
        } else {
          laborPartsCatalog?.unSelectElement(item.id, CatalogNodeType.CATEGORY)
        }
      })
      fetchGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborPartsCatalog.categories, partsCatalog.selectedCategories])

  useEffect(() => {
    if (categories?.length === 0) {
      fetchCategories()
    }
  }, [categories, fetchCategories])

  useEffect(() => {
    resetStore()
  }, [resetStore])

  return (
    <StyledGrid container spacing={2}>
      <CategoriesSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep === VisibleStep.START ||
            laborPartsCatalog.loadingCategories
          }
          title="categories"
          type={CatalogNodeType.CATEGORY}
          list={laborPartsCatalog.categories}
          partsCatalogStore={laborPartsCatalog}
        />
      </CategoriesSection>
      <GroupsSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep <= VisibleStep.CATEGORY ||
            laborPartsCatalog.loadingCategoryGroups
          }
          title="group"
          type={CatalogNodeType.GROUP}
          groups={categoryGroups}
          partsCatalogStore={laborPartsCatalog}
          showGfxButtons={false}
        />
      </GroupsSection>
      <DescriptionsSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep <= VisibleStep.GROUP ||
            laborPartsCatalog.loadingGroupPartTypes
          }
          title="description"
          type={CatalogNodeType.PART_TYPE}
          groups={groupPartTypes}
          partsCatalogStore={laborPartsCatalog}
        />
      </DescriptionsSection>
    </StyledGrid>
  )
})

const StyledGrid = styled(Grid)`
  margin-left: 0;
`

const CategoriesSection = styled(StyledGrid)`
  padding-left: 0px !important;
`

const GroupsSection = styled(StyledGrid)``

const DescriptionsSection = styled(StyledGrid)``

export default PartsCatalog
