import React, { ReactElement, useState, useRef } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import { zIndex } from 'src/theme/zIndex'
import { Add, Delete, Edit } from '@mui/icons-material'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { observer } from 'mobx-react-lite'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useUserStore } from 'src/store/user/UserStore'
import { SimpleDeleteModal } from './SimpleDeleteModal'
import { SimpleGroupAddEditModal } from './SimpleGroupAddEditModal'
import { SELLER_MANAGED_LIST_LABEL } from '../interfaces'

export const ListGroupsTabs = observer((): ReactElement => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const {
    setSelectedGroup,
    selectedList,
    showAddEditGroupModal,
    setShowAddEditGroupModal,
    selectedListGroups,
    selectedGroupValue,
    setSelectedGroupValue,
    createGroup,
    updateGroup,
    deleteGroup,
    getSelectedCategoryLabel,
  } = useListsStore()

  const groups = selectedListGroups

  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [modalName, setModalName] = useState('')

  const userStore = useUserStore()
  const parentOrgId = userStore?.preferences?.parentOrgId
  const isAdmin = Number(parentOrgId) === 0
  const isSellerManagedCategory =
    getSelectedCategoryLabel() === SELLER_MANAGED_LIST_LABEL
  const enableListOptions = isAdmin || !isSellerManagedCategory

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedGroupValue(newValue)
    setSelectedGroup(groups[newValue])
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuAnchorEl)
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleAddEditGroupModal = () => {
    setShowAddEditGroupModal(!showAddEditGroupModal)
  }

  const addGroup = (labelValue: string) => {
    createGroup(selectedList.id, labelValue)
  }
  const editGroup = (labelValue: string) => {
    updateGroup(labelValue)
  }

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteAction = () => {
    deleteGroup()
    setShowDeleteModal(!showDeleteModal)
  }

  const handleOnRename = () => {
    if (!enableListOptions) return
    handleMenuClose()
    setModalName('reName')
    setSelectedGroupName(groups[selectedGroupValue].value)
    handleAddEditGroupModal()
  }

  const handleOnDelete = () => {
    if (!enableListOptions) return
    handleMenuClose()
    handleDeleteModal()
  }

  const disableActionIcon = !enableListOptions
  const overlayRef = useRef(null)

  return (
    <Container>
      <IconButtonWrapper
        onClick={() => {
          setModalName('new')
          setSelectedGroupName('')
          handleAddEditGroupModal()
        }}
        disableRipple
        disabled={!enableListOptions}
        title={Translate('createGroup')}
      >
        <StyledAddGroupIcon />
      </IconButtonWrapper>
      <Tabs
        value={selectedGroupValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        style={{
          alignItems: 'center',
          height: '45px',
        }}
        TabIndicatorProps={{
          style: {
            background: defaultTheme.colors.primary,
            height: '5px',
            marginBottom: '15px',
          },
        }}
        textColor="inherit"
      >
        {groups?.map((group) => {
          return (
            <Tab
              label={group.value}
              style={{
                fontWeight: 'bold',
                textTransform: 'none',
              }}
              iconPosition="end"
              icon={
                <div>
                  {group?.value === groups[selectedGroupValue]?.value ? (
                    group.value.toLowerCase() === 'base' ? null : (
                      <StyledMoreVertIcon
                        style={{ width: '20px' }}
                        onClick={(e) => {
                          handleMenuClick(e)
                        }}
                      />
                    )
                  ) : null}
                </div>
              }
              key={group.id}
              disableRipple
              sx={{ padding: 0 }}
            />
          )
        })}
      </Tabs>
      <StyledMenu
        enable={enableListOptions}
        style={{ marginTop: '40px' }}
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={6}
      >
        <StyledMenuItem enable={enableListOptions} onClick={handleOnRename}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <StyledText normal>
            <I18n name="reName" />
          </StyledText>
        </StyledMenuItem>
        <StyledMenuItem enable={enableListOptions} onClick={handleOnDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <StyledText normal>
            <I18n name="delete" />
          </StyledText>
        </StyledMenuItem>
      </StyledMenu>

      <ResponsiveDrawer
        anchor="bottom"
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{
          square: false,
        }}
        style={{
          width: '800px',
        }}
      >
        <ContainerWrapper ref={overlayRef}>
          <HeaderContainer>
            <span>
              <I18n name="group" />:{' '}
              <I18n name={groups[selectedGroupValue]?.value} />
            </span>

            <StyledCloseIcon onClick={handleMenuClose} />
          </HeaderContainer>
          <ItemsContainer>
            <SelectItem
              disableActionIcon={disableActionIcon}
              onClick={handleOnRename}
            >
              <StyledEdit disableActionIcon={disableActionIcon} />
              <SelectItemText disableActionIcon={disableActionIcon}>
                <I18n name="renameGroup" />
              </SelectItemText>
            </SelectItem>
            <SelectItem
              disableActionIcon={disableActionIcon}
              onClick={handleOnDelete}
            >
              <StyledDelete disableActionIcon={disableActionIcon} />
              <SelectItemText disableActionIcon={disableActionIcon}>
                <I18n name="deleteGroup" />
              </SelectItemText>
            </SelectItem>
          </ItemsContainer>
          <ItemsContainer
            style={{
              borderTop: `1px solid ${defaultTheme.colors.greyBorder}`,
            }}
          >
            <SelectItem
              disableActionIcon={disableActionIcon}
              onClick={() => {
                if (!disableActionIcon) {
                  handleMenuClose()
                  setModalName('new')
                  handleAddEditGroupModal()
                }
              }}
            >
              <StyledAdd disableActionIcon={disableActionIcon} />
              <SelectItemText disableActionIcon={disableActionIcon}>
                <I18n name="newGroup" />
              </SelectItemText>
            </SelectItem>
          </ItemsContainer>
        </ContainerWrapper>
      </ResponsiveDrawer>

      <SimpleGroupAddEditModal
        showGroupAddEditModal={showAddEditGroupModal}
        handleGroupAddEditModal={handleAddEditGroupModal}
        modalName={modalName}
        groupName={selectedGroupName}
        addGroup={addGroup}
        editGroup={editGroup}
      />
      <SimpleDeleteModal
        modalName="group"
        showDeleteModal={showDeleteModal}
        handleDeleteModal={handleDeleteModal}
        handleDeleteAction={handleDeleteAction}
      />
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  border-bottom: 1px solid ${defaultTheme.colors.border};
`

interface StyledTextProps {
  lightText?: boolean
  normal?: boolean
}
const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => (p.normal ? '13px' : '22px')};
  font-family: ${(p) => (p.normal ? 'Roboto, serif' : 'Oswald, serif')};
  color: ${(p) => (p.lightText ? 'gray' : 'black')};
  font-weight: ${(p) => (p.normal ? '500' : 'bold')};
  display: block;
  text-align: left;
`

const StyledMoreVertIcon = styled(MoreVertIcon)`
  color: gray;
  &:hover {
    color: black;
    cursor: pointer;
  }
`

const StyledAddGroupIcon = styled(AddCircleOutlineIcon)`
  color: gray;
  &:hover {
    color: black;
    cursor: pointer;
  }
`

interface MenuProps {
  enable: boolean
}

const StyledMenu = styled(Menu)<MenuProps>`
  ${({ enable }) =>
    !enable &&
    `
      span, svg {
        fill: ${defaultTheme.colors.greyMedium};
        color:${defaultTheme.colors.greyMedium};
      }
    `}
  @media screen and (max-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

const StyledMenuItem = styled(MenuItem)<MenuProps>`
  ${({ enable }) =>
    !enable &&
    `
      &:hover {
      background-color: ${defaultTheme.colors.white};
      cursor: not-allowed;
      }
    `}
`

const ResponsiveDrawer = styled(Drawer)`
  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 24px;
    border-bottom: 1px solid ${defaultTheme.colors.greyBorder};
  }
`

const ItemsContainer = styled.div`
  margin: 24px;
`

interface DisableListTabProps {
  disableActionIcon: boolean
}

const SelectItem = styled.div<DisableListTabProps>`
  width: 100%;
  height: 45px;
  cursor: pointer;
  &:hover {
    background: ${defaultTheme.colors.rowFeaturedBackground};
  }
  display: flex;
  align-items: center;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
    &:hover {
    color: ${defaultTheme.colors.greyMedium};
    background: ${defaultTheme.colors.white};
    cursor: not-allowed;
  } `}
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`

const StyledEdit = styled(Edit)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`

interface ContainerProps {
  height?: string
  width?: string
}

const ContainerWrapper = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  /* padding: 24px; */
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`
const SelectItemText = styled.span<DisableListTabProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Black */

  color: ${defaultTheme.colors.primaryText};
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
  } `}
`
const StyledDelete = styled(Delete)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
    cursor: not-allowed;
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`
const StyledAdd = styled(Add)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  margin-left: 7px;
  ${({ disableActionIcon }) =>
    disableActionIcon &&
    `color: ${defaultTheme.colors.greyMedium};
     cursor: not-allowed;
    &:hover {
      color: ${defaultTheme.colors.greyMedium};
  } `}
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin: 0px;
  }
`
const IconButtonWrapper = styled(IconButton)`
  @media screen and (max-width: ${breakpoints.laptop}px) {
    display: none;
  }
`
