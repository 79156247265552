import React, { ReactElement } from 'react'
import { Translate } from './translate'

interface I18nProps {
  name: string
  style?: React.CSSProperties
  textReplacement?: Array<string | number>
}

export const I18n = (props: I18nProps): ReactElement => {
  const { name, textReplacement, style } = props
  return <span style={style}>{Translate(name, textReplacement)}</span>
}

I18n.defaultProps = {
  style: {},
  textReplacement: [],
}
