import { ReactElement, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { Button, TextInput } from 'src/ui-components'
import defaultLogoUrl from 'src/assets/images/logo.png'
import styled from 'styled-components'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import { useHistory } from 'react-router-dom'
import { useUserStore } from 'src/store/user/UserStore'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { LogoImage } from '../navigation/TopNavigation/components/CommonMenuItems'

const ResetPasswordScreen = (): ReactElement => {
  const history = useHistory()
  const userStore = useUserStore()
  const [loading, setLoading] = useState(false)
  const [logoUrl] = useState(defaultLogoUrl)
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [passwordNotMatchError, setPasswordNotMatchError] = useState(false)
  const { displayErrorNotification } = StoreInstances.uiStore

  const handleResetPassword = async (): Promise<void> => {
    if (newPassword !== newPasswordConfirmation) {
      setPasswordNotMatchError(true)
      displayErrorNotification('passwordsMustMatch')
      return
    }

    try {
      setLoading(true)
      const userName = userStore.resetCredentialsUserName
      await userStore.resetPassword(code, userName, newPassword)
      setLoading(false)
      history.push('/login')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (error: any) {
      displayErrorNotification(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleReSendCode = (): void => {
    history.push('/sendCode')
  }

  return (
    <Wrapper>
      <Content>
        <>
          <LogoImageWrapper
            src={logoUrl}
            alt={Translate('myPlaceForPartsLogo')}
          />
          <h3>
            <I18n name="resetYourPassword" textReplacement={['']} />
          </h3>
          <InstructionsSpan>
            <I18n
              name="pleaseEnterTheCodeThatWasSentTo"
              textReplacement={[userStore.resetCredentialsDestination]}
            />
          </InstructionsSpan>
          <TextInputWrapper>
            <TextInput
              placeholder="enterCode"
              label="code"
              value={code}
              onChange={(value) => setCode(value)}
              width="100%"
              type="password"
              noMargin
            />
          </TextInputWrapper>
          <TextInputWrapper>
            <TextInput
              placeholder="enterNewPassword"
              label="newPassword"
              value={newPassword}
              onChange={(value) => {
                setPasswordNotMatchError(false)
                setNewPassword(value)
              }}
              width="100%"
              type="password"
              error={passwordNotMatchError}
              noMargin
            />
          </TextInputWrapper>
          <TextInputWrapper>
            <TextInput
              placeholder="confirmNewPassword"
              label="confirmNewPassword"
              value={newPasswordConfirmation}
              onChange={(value) => {
                setPasswordNotMatchError(false)
                setNewPasswordConfirmation(value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleResetPassword()
                }
              }}
              width="100%"
              type="password"
              error={passwordNotMatchError}
              noMargin
            />
          </TextInputWrapper>
          <ResetPasswordButton
            onClick={handleResetPassword}
            text="update"
            loading={loading}
          />
          <InstructionsSpan>
            <I18n name="ifYouDoNotReceiveEmailWithCode" />
          </InstructionsSpan>
          <ResetCodeSection>
            <StyledText>
              <Hyperlink action={handleReSendCode} underlined={false}>
                <I18n name="requestNewCode" />
              </Hyperlink>
            </StyledText>
          </ResetCodeSection>
        </>
      </Content>
    </Wrapper>
  )
}

export default ResetPasswordScreen

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ResetPasswordButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
`

const LogoImageWrapper = styled(LogoImage)`
  margin-bottom: 1.5rem;
`

const InstructionsSpan = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

const ResetCodeSection = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`

const StyledText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const TextInputWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`
