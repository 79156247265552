import {
  CheckEligibilityResponse,
  IEstimateReqData,
  IPurolateErrorMessage,
  IPurolatorEstimatesRespBody,
} from 'src/api/shipment/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import { AxiosError } from 'axios'

class ShipmentServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'shipment', '1.1', '')
  }

  fetchPurolatorEligibility = async (
    locationIds: number[]
  ): Promise<CheckEligibilityResponse> => {
    const reqBody = {
      sellnwLocations: locationIds,
    }
    const resp = await this.post<CheckEligibilityResponse>(
      'checkEligibility',
      reqBody
    )
    return resp?.data
  }

  fetchPurolatorEstimates = async (
    estimateRequestData: IEstimateReqData[]
  ): Promise<IPurolatorEstimatesRespBody> => {
    const reqBody = {
      estimateRequestData,
    }
    try {
      const resp = await this.post<IPurolatorEstimatesRespBody>(
        'getEstimates',
        reqBody
      )

      return resp?.data
    } catch (e) {
      throw this.handlePurolatorErrorObject(e)
    }
  }

  handlePurolatorErrorObject = (error): string => {
    try {
      const { message }: IPurolateErrorMessage = (error as AxiosError).response
        .data as IPurolateErrorMessage
      /* Below 2 lines of code is required, as the current API failure response includes classPath, http server error code, 
        custom error code and error message. To derive the error message, below 2 statements are written.
        */
      const parts = message.split(':')
      return parts.slice(4).join(':').trim()
    } catch {
      return 'unknownErrorPurolatorEstimates'
    }
  }
}

export default new ShipmentServiceProvider()
