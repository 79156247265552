import {
  DirectionsCar,
  ExpandLess,
  ExpandMore,
  LocalShipping,
} from '@mui/icons-material'

import { ReactElement } from 'react'
import { I18n, Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import styled, { css } from 'styled-components'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from '../../VehicleSearch/store/VehicleWidgetStore'
import { defaultTheme } from 'src/theme'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useHistory } from 'react-router-dom'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import ClearVehicleMenu from './ClearVehicleMenu'
import { VehicleType } from 'src/store/search/CustomizeSearchStore'
import { LookupType } from 'src/helpers/lookupType'

interface VehicleInfoProps {
  type?: VehicleType
  year?: string
  model?: string
  engine?: string
  onClick: () => void
  open: boolean
}

const VehicleInfo = (props: VehicleInfoProps): ReactElement => {
  const { type, year, model, engine, open, onClick } = props

  const searchStore = useSearchStore()
  const history = useHistory()
  const { currentVehicle } = searchStore

  const subModel = currentVehicle.vehicleSpecs?.find(
    (obj) => obj.label === 'Series'
  )?.value

  const primaryText =
    year && model ? `${year} ${model}` : Translate('addVehicle')

  const engineInfo = year && model ? `${engine}` : ''

  const clearText = year && model ? true : false

  const vehicleWidgetStore = useVehicleWidgetStore()

  const handleDeleteVehicle = () => {
    if (
      location.pathname === '/partsCatalog' ||
      location.pathname === '/searchResults'
    )
      history.push('home')
    searchStore.deleteCurrentVehicle()
    vehicleWidgetStore.setIsDrawerOpened(false)
    vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
    StoreInstances.uiStore.displaySuccessNotification(
      'vehicleSuccessfullyDeleted'
    )
  }

  return (
    <VehicleRow aria-controls="collapse-vehicle-widget" aria-expanded={open}>
      {type?.id === LookupType.HEAVY_DUTY ? (
        <LocalShippingIcon />
      ) : (
        <DirectionsCarIcon />
      )}
      <VehicleInfoContainerDesktop>
        <SelectedVehicleRow>
          <MainText>{primaryText}</MainText>
          <ModelContainer>
            {subModel?.length > 0 && subModel && (
              <SubmodelText>{subModel}</SubmodelText>
            )}
            <SecondaryText>{engineInfo}</SecondaryText>
          </ModelContainer>
        </SelectedVehicleRow>
        {!clearText && (
          <SelectVehicleRow>
            <SelectVehicleText onClick={onClick}>
              {Translate('selectAVehicle')}
            </SelectVehicleText>
            {!open ? (
              <ExpandMore onClick={onClick} />
            ) : (
              <ExpandLess onClick={onClick} />
            )}
          </SelectVehicleRow>
        )}

        {clearText && (
          <ClearVehicleRow>
            <ClearText
              onClick={() => {
                vehicleWidgetStore.setIsDrawerOpened(true)
                vehicleWidgetStore.setDisplayState(
                  VehicleWidgetDisplayState.edit
                )
              }}
            >
              <I18n name="change"></I18n>
            </ClearText>
            <ClearTextDevider></ClearTextDevider>
            <ClearText onClick={handleDeleteVehicle}>
              <I18n name="vehicleClear"></I18n>
            </ClearText>
            <ClearVehicleMenu></ClearVehicleMenu>
            {!open ? (
              <ExpandMore onClick={onClick} />
            ) : (
              <ExpandLess onClick={onClick} />
            )}
          </ClearVehicleRow>
        )}
      </VehicleInfoContainerDesktop>
    </VehicleRow>
  )
}

VehicleInfo.defaultProps = {
  year: '',
  model: '',
}

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media print {
    display: none;
  }
`

const SelectedVehicleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  cursor: pointer;

  @media print {
    display: none;
  }

  @media screen and (width > ${breakpoints.laptopXLarge}px) {
    width: 500px;
  }

  @media screen and (${breakpoints.laptopLarge}px < width <= ${breakpoints.laptopXLarge}px) {
    min-width: 257px;
    max-width: max-content;
  }

  @media screen and (${breakpoints.laptopMedium}px < width <= ${breakpoints.laptopLarge}px) {
    min-width: 127px;
    max-width: 257px;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    min-width: 127px;
    max-width: 223px;
  }

  @media screen and (${breakpoints.tabletLarge}px <= width <= ${breakpoints.laptop}px) {
    min-width: 127px;
    max-width: 127px;
  }

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    min-width: 170px;
    max-width: 500px;
  }
`

const ClearVehicleRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  width: 100%;
  max-width: 180px;
  min-width: 180px;

  @media print {
    display: none;
  }

  @media screen and (${breakpoints.laptop}px < width <= ${breakpoints.laptopMedium}px) {
    max-width: fit-content;
    min-width: 72px;
    gap: 24px;
  }

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    min-width: 40px;
    max-width: fit-content;
  }
`

const VehicleRow = styled(Row)`
  height: 56px;
  padding: 12px 16px;
  border: 1px solid ${defaultTheme.colors.greyBorder};
  border-radius: 3px;
  width: 100%;
`

const VehicleInfoContainerDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding-left: 10px;
  width: inherit;
  gap: 16px;

  @media screen and (${breakpoints.tabletLarge}px < width < ${breakpoints.laptopMedium}px) {
    min-width: 227px;
    max-width: 311px;
  }

  @media screen and (width <= ${breakpoints.tabletLarge}px) {
    width: 100%;
  }
`

const MainText = styled.div`
  align-items: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SecondaryText = styled.span`
  align-items: left;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
`

const SubmodelText = styled.span`
  color: ${defaultTheme.colors.almostBlack};
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-right: 8px;
`

const ClearText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  @media (${breakpoints.tabletLarge}px <= width <= ${breakpoints.laptopMedium}px) {
    display: none;
  }

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const ClearTextDevider = styled.div`
  height: 24px;
  border: ${defaultTheme.fieldBorder};

  @media (${breakpoints.tabletLarge}px <= width <= ${breakpoints.laptopMedium}px) {
    display: none;
  }

  @media screen and (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const SelectVehicleRow = styled.div`
  display: flex;
  gap: 16px;
`

const SelectVehicleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-wrap: nowrap;

  @media (${breakpoints.tabletLarge}px <= width <= ${breakpoints.laptopMedium}px) {
    display: none;
  }

  @media (width < ${breakpoints.mobileLarge}px) {
    display: none;
  }
`

const ModelContainer = styled.div`
  display: inline;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const BaseIcon = css`
  font-size: 32px;
  margin-left: -6px;

  @media screen and (width < ${breakpoints.tabletLarge}px) {
    font-size: 22px;
    margin: 3px 11px 0px 0px;
  }
`

const DirectionsCarIcon = styled(DirectionsCar)`
  ${BaseIcon}
`

const LocalShippingIcon = styled(LocalShipping)`
  ${BaseIcon}
`

export default VehicleInfo
