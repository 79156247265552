import axios from 'axios'
import { BaseServiceProvider } from './BaseServiceProvider'
import { PartsBasketCreds, TokensFromAPI } from 'src/api/security/interfaces'

class TokenServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'token', '1.1', '')
  }

  getTokens = (creds: PartsBasketCreds): Promise<TokensFromAPI> => {
    return axios
      .post<TokensFromAPI>('token', creds, {
        baseURL: this.getConstructedUrl(),
        headers: {
          'x-token-api-key': import.meta.env.VITE_TOKEN_API_KEY,
        },
      })
      .then((resp) => resp?.data)
  }
}

export default new TokenServiceProvider()
