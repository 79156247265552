import {
  Delete,
  DriveFileMove,
  Edit,
  ExpandLess,
  ExpandMore,
  MoreVert,
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useUserStore } from 'src/store/user/UserStore'
import ListNode from 'src/store/lists/ListNode'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { Translate } from 'src/i18n'
import { CategoryOptions } from './CategoryOptions'
import { breakpoints } from 'src/theme/breakpoints'

interface CategoryItemProps {
  category: ListNode
  selectedList: ListNode
  setSelectedList: (id: ListNode) => void
}

const CategoryItem = observer(
  (props: CategoryItemProps): React.ReactElement => {
    const { category, selectedList, setSelectedList } = props

    const ref = useRef(null)
    const [showOptions, setShowOptions] = useState(false)
    const {
      moveListModal,
      deleteListModal,
      setMoveListModal,
      setDeleteListModal,
      nameListModal,
      setNameListModal,
      setDeleteSelectedList,
      setMoveSelectedList,
      setShowListGroups,
      setNameSelectedList,
      setListNameModalType,
      setSelectedCategory,
      setNewListCategoryId,
      toggleCategory,
    } = useListsStore()

    const userStore = useUserStore()
    const parentOrgId = userStore?.preferences?.parentOrgId
    const isAdmin = Number(parentOrgId) === 0

    const [disableActionIcon, setDisableActionIcon] = useState(false)

    const disableListTab = category.value === 'Seller Managed' && !isAdmin

    const handleMoreVert = () => {
      if (category.value === 'Seller Managed' && !isAdmin) {
        setDisableActionIcon(true)
      }
      setShowOptions(!showOptions)
      setNewListCategoryId(category.id)
    }

    return (
      <>
        <ExpanderWrapper borderBottom={category.open} ref={ref}>
          <ExpandTitle>{category.value}</ExpandTitle>
          <ExpanderActionsWrapper>
            <IconButton
              style={{
                color: defaultTheme.colors.disabledButtonText,
                cursor: 'pointer',
              }}
              onClick={handleMoreVert}
              title={Translate('moreOptions')}
            >
              <MoreVert />
            </IconButton>

            {category.open ? (
              <IconButton
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  toggleCategory(category)
                }}
                title={Translate('closeGroups')}
              >
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  toggleCategory(category)
                }}
                title={Translate('openGroups')}
              >
                <ExpandMore />
              </IconButton>
            )}
          </ExpanderActionsWrapper>
        </ExpanderWrapper>
        <CategoryOptions
          parentRef={ref}
          show={showOptions}
          setShow={setShowOptions}
          category={category}
          disableActionIcon={disableActionIcon}
        />
        {category.open && (
          <List>
            {category.getChildren().map((list) => {
              const active = list?.id === selectedList?.id
              return (
                <ListItem active={active} key={list.id}>
                  <ListTitle
                    onClick={() => {
                      setSelectedCategory(category)
                      setSelectedList(list)
                      setShowListGroups(true)
                    }}
                    active={active}
                  >
                    {list.value}
                  </ListTitle>
                  {active && (
                    <ListActions>
                      <IconButton
                        size="small"
                        style={iconStyle}
                        onClick={() => {
                          if (!disableListTab) {
                            setDeleteListModal(!deleteListModal)
                            setDeleteSelectedList({
                              id: list.id,
                              label: list.value,
                            })
                          }
                        }}
                        title={Translate('deleteList')}
                      >
                        <StyledDelete disableListTab={disableListTab} />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={iconStyle}
                        onClick={() => {
                          if (!disableListTab) {
                            setMoveListModal(!moveListModal)
                            setMoveSelectedList({
                              id: list.id,
                              label: list.value,
                              category: category.id,
                            })
                          }
                        }}
                        title={Translate('moveList')}
                      >
                        <StyledDriveFileMove disableListTab={disableListTab} />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={iconStyle}
                        onClick={() => {
                          if (!disableListTab) {
                            setNameListModal(!nameListModal)
                            setNameSelectedList({
                              id: list.id,
                              label: list.value,
                              category: category.value,
                            })
                            setListNameModalType('rename')
                          }
                        }}
                        title={Translate('renameList')}
                      >
                        <StyledEdit disableListTab={disableListTab} />
                      </IconButton>
                    </ListActions>
                  )}
                </ListItem>
              )
            })}
          </List>
        )}
      </>
    )
  }
)

export default CategoryItem

interface ExpanderWrapperProps {
  borderBottom?: boolean
}

const iconStyle = { borderRadius: '20%', padding: 0, marginLeft: 10 }
const ExpanderWrapper = styled.div<ExpanderWrapperProps>`
  border-top: 1px solid ${defaultTheme.colors.greyBorder};
  border-bottom: ${(p) =>
    p.borderBottom ? `1px solid ${defaultTheme.colors.greyBorder}` : 'none'};

  padding: 12px 16px 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  @media screen and (max-width: ${breakpoints.laptop}px) {
    width: 90%;
  }
`
const ExpandTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: ${defaultTheme.colors.primaryText};
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ExpanderActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
`
const List = styled.div`
  padding: 8px 0px;
  background: ${defaultTheme.colors.rowFeaturedBackground};
`
interface ListItemProps {
  active: boolean
}
const ListItem = styled.div<ListItemProps>`
  padding: ${(p) => (p.active ? `12px 16px 12px 32px` : '12px 16px 12px 40px')}}
  background: inherit;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-left: ${(p) =>
    p.active ? `8px solid ${defaultTheme.colors.primary}` : 'none'}}
 
`

interface ListTitleProps {
  active: boolean
}

const ListTitle = styled.span<ListTitleProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(p) => (p.active ? '700' : '400')};
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  color: ${defaultTheme.colors.primaryText};
  text-align: start;
  &:hover {
    font-weight: 700;
  }
`

interface DisableListTabProps {
  disableListTab: boolean
}

const ListActions = styled.div`
  display: none;
  ${ListItem}:hover & {
    display: flex;
    alisn-self: flex-end;
  }
`

const StyledDelete = styled(Delete)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  ${({ disableListTab }) =>
    disableListTab &&
    `
    &:hover {
      color:${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
  } `}
`
const StyledDriveFileMove = styled(DriveFileMove)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  ${({ disableListTab }) =>
    disableListTab &&
    `
   
    &:hover {
      color:${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
  } `}
`
const StyledEdit = styled(Edit)<DisableListTabProps>`
  color: ${defaultTheme.colors.disabledButtonText};
  &:hover {
    color: ${defaultTheme.colors.primaryText};
  }
  cursor: pointer;
  ${({ disableListTab }) =>
    disableListTab &&
    `
    &:hover {
      color:${defaultTheme.colors.greyMedium};
      cursor: not-allowed;
  } `}
`
