import { ReactElement } from 'react'
import { SortSelect } from '../SortSelect'
import { Box } from '@mui/material'
import { I18n } from 'src/i18n'
import { SingleOption } from 'src/features/orderHistory/components/SortSelect'
import styled from 'styled-components'

interface Props {
  itemsPerPage: number
  pageOptions: SingleOption[]
  onChange: (selectedOption: number) => void
}

const PageSizeSelector = (props: Props): ReactElement => {
  const { itemsPerPage, onChange, pageOptions } = props
  return (
    <Box display="flex" alignItems="center">
      <Text style={{ textTransform: 'capitalize' }}>
        <I18n name="itemsPerPage" />
      </Text>
      <SortSelect
        mobile
        label=""
        width="55px"
        height="32px"
        onChange={(selectedOption) => onChange(Number(selectedOption))}
        value={itemsPerPage.toString()}
        defaultValue="itemsPerPageScale"
        options={pageOptions}
      />
    </Box>
  )
}

const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-right: 1rem;
`

export default PageSizeSelector
