import { AxiosError } from 'axios'
import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { PartMatchingResponse } from 'src/api/lists/interfaces'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import ListServiceProvider, {
  ListImportResponse,
} from 'src/services/ListServiceProvider'
import { breakpoints } from 'src/theme/breakpoints'
import { StoreInstances } from '../StoreInstancesContainer'
import { IdNamePair, IdValuePair } from '../models/KeyValuePair'
import { ProductLocationModel } from '../models/ProductModel'
import { PartAttributes, SortOrder } from '../models/SearchModels'
import { Vehicle } from '../models/Vehicles'
import ListNode from './ListNode'
import {
  AddGroupPart,
  AddPartToList,
  CheckedListParts,
  DeleteGroupParts,
  GroupPart,
  GroupPartResponse,
  ListImportDetails,
  ListResponseError,
  MoveGroupParts,
  Product,
  RequestBodyNewList,
  UpdateGroupPart,
  ValidateListPart,
  ValidatePartsRequest,
} from './interfaces'
import { showCartPartComparisionNotification } from '../cart/Utils'
import history from 'src/helpers/history'
import {
  LISTS_IMPORT_RETRIES_INTERVAL,
  LISTS_IMPORT_RETRIES_LIMIT,
} from 'src/config/constants'
import { getValidatedPartsList } from './Utils'

interface SelectedList {
  id: number
  label: string
}

interface SelectedCategory {
  id: number
  label: string
}

interface MoveSelectedList {
  id: number
  label: string
  category: number
}

interface NameSelectedList {
  id?: number
  label?: string
  category: string
}

export const DEFAULT_SORT_ORDER: SortOrder = [
  {
    property: 'Mfr',
    label: 'Mfr (A-Z)',
    dir: 'asc',
  },
  {
    property: 'PartNumber',
    label: 'Part Number (A-Z)',
    dir: 'asc',
  },
]
interface IUpdateGroupPartPaylod {
  isSelected?: boolean
  stockQty?: number
}

export enum ImportStatus {
  STARTED = 'started',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export class ListsStore {
  public categoryNameField = ''

  public categoryNameModal = false

  public categoryNameModalType: 'new' | 'rename'

  public categoryToRename: IdValuePair<string>

  public deleteCategoryModal = false

  public deleteListModal = false

  public deleteSelectedCategory: SelectedCategory

  public deleteSelectedList: SelectedList

  public deleteSelectedGroupPart: SelectedList

  public listNameModalType: 'new' | 'rename'

  public showListGroups = false

  public loadingCategories = false

  public loadingGroups = false

  public moveListModal = false

  public matchingFilteredSearchTerms: PartMatchingResponse = []

  public moveSelectedList: MoveSelectedList

  public nameListModal = false

  public nameListNameField = ''

  public nameListNotesField = ''

  public nameSelectedList: NameSelectedList

  public selectedList: ListNode = undefined

  public selectedCategory: ListNode = undefined

  public selectedGroup: ListNode = undefined

  public showBottomDrawer = false

  public showDeleteItemsFromListsModal = false

  public selectedItems = 0

  public selectedItemsList: GroupPart[] = []

  public checkedListParts: Array<CheckedListParts> = []

  public changeLocationParts: Array<ProductLocationModel> = []

  public cartSelectorModal = false

  public root: ListNode = new ListNode(0, 'root')

  public listImportRespData: ListImportResponse

  public selectedVehicle: Vehicle = miscellaneousVehicle

  public importListPartsLoading = false

  public newListCategoryId = undefined

  public showAddEditGroupModal = false

  public selectedListGroups = []

  public selectedGroupValue = 0

  /**
   *  selectedGroupParts property refers to the parts that are fetched from API for a given list and group
   */
  public selectedGroupParts: Array<GroupPart> = []

  public selectedGroupPartsCount = 0
  /**
   *  selectedPartsList property refers to the parts that are selected from the table.
   */
  public selectedPartsList: Array<GroupPart> = []

  public groupPartsLoading = false

  public searchTerm = undefined

  public moveListGroups: Array<IdNamePair<number, string>> = []

  public thumbnails = []

  public currentPage = 0

  public itemsPerPage = 10

  public sortOrder: SortOrder = DEFAULT_SORT_ORDER

  // public enableSortIcons = ''

  public listImportDetails: ListImportDetails = undefined

  onCategoryDeleted = false

  onListDeleted = false

  public arePartsBeingAddedToCart = false

  private _retryInterval = 0
  private _retryLimit = 0

  constructor() {
    makeAutoObservable(this)
    this.initializeListStoreFromUIStore()
    this.initRetryConfig()
  }

  private initRetryConfig() {
    const interval = Number(LISTS_IMPORT_RETRIES_INTERVAL)
    const limit = Number(LISTS_IMPORT_RETRIES_LIMIT)

    this._retryLimit = limit / interval // How many retries can be made in the given time period
    this._retryInterval = interval
  }

  public initializeListStoreFromUIStore = (): void => {
    //selectedList - used to store the selected list from the category
    this.selectedList = StoreInstances.uiStore.listStoreProps.selectedList

    //selectedGroupValue - contains by default 0 and value will be updated based on the selected group index
    this.selectedGroupValue =
      StoreInstances.uiStore.listStoreProps.selectedGroupValue

    //selectedGroup - constains the selected group value
    this.selectedGroup = StoreInstances.uiStore.listStoreProps.selectedGroup

    //selectedListGroups - contains all the groups of the selected list.
    this.selectedListGroups =
      StoreInstances.uiStore.listStoreProps.selectedListGroups

    //selectedCategories - contains all the categories based on selected category.
    this.selectedCategory =
      StoreInstances.uiStore.listStoreProps.selectedCategory
  }

  public handlePaginationLimit = (start: number, limit: number): void => {
    this.currentPage = start
    this.itemsPerPage = limit
  }

  setSortOrder = (sortOrder: SortOrder): void => {
    this.sortOrder = sortOrder
    this.getListGroupParts()
  }

  public handleCloseCartSelectorModal = (): void => {
    this.cartSelectorModal = false
  }

  public setThumbnails = (thumbnails: Array<PartAttributes>): void => {
    this.thumbnails = thumbnails
  }

  public getThumbnailUrl = (partNumber: string, lineCode: string): string => {
    const thumbnailUrl = this.thumbnails?.find(
      (item) =>
        item?.partNumber === partNumber &&
        item?.lineCode?.toUpperCase() === lineCode?.toUpperCase()
    )

    if (thumbnailUrl) {
      return thumbnailUrl?.partImages?.[0]?.url
    }
    return ''
  }

  public setShowListGroups = (show: boolean): void => {
    this.showListGroups = show
  }

  public setSearchTerm = async (searchTerm: string): Promise<void> => {
    if (searchTerm === '') {
      this.resetListSearchResults()
      this.getCategories()
    } else {
      this.searchTerm = searchTerm
      this.loadingCategories = true
      this.matchingFilteredSearchTerms =
        await ListServiceProvider.getMatchingParts(searchTerm)
      this.handleListCategories()
    }
  }

  public resetListSearchResults = (): void => {
    this.searchTerm = undefined
    this.matchingFilteredSearchTerms = []
    this.selectedPartsList = []
  }

  public resetSelectedPartsList = (partIds?: Array<number>): void => {
    const filteredSelectedPartsList = this.selectedPartsList.filter(
      (selectedPart) => {
        return !partIds.find((partId) => {
          return selectedPart.id === partId
        })
      }
    )
    // this.selectedPartsList = []
    this.selectedPartsList = filteredSelectedPartsList
  }

  private handleListCategories = (): void => {
    this.root.deleteAllChildren()
    this.matchingFilteredSearchTerms.forEach((list) => {
      let matchingCategoryNode = this.root.findChildren(
        list.category.id,
        list.category.name
      )
      if (!matchingCategoryNode) {
        matchingCategoryNode = new ListNode(
          list.category.id,
          list.category.name,
          'category'
        )
        this.root.addChild(matchingCategoryNode)
      }
      const listNode = new ListNode(list.id, list.name, 'list')
      this.root
        .findChildren(list.category.id, list.category.name)
        .addChild(listNode)
    })

    this.loadingCategories = false
  }

  public setCartSelectorModal = (set: boolean): void => {
    this.cartSelectorModal = set
  }

  public setSelectedVehicle = (vehicle: Vehicle): void => {
    this.selectedVehicle = vehicle
  }

  public setShowBottomDrawer = (modal: boolean): void => {
    this.showBottomDrawer = modal
  }

  public setSelectedItems = (selectedItems?: number): void => {
    this.selectedItems = selectedItems
  }

  public setShowDeleteItemsFromListsModal = (modal: boolean): void => {
    this.showDeleteItemsFromListsModal = modal
  }

  public setShowSelectedItemsList = (selectedItemsList: GroupPart[]): void => {
    this.selectedItemsList = selectedItemsList
  }

  public setDeleteListModal = (modal: boolean): void => {
    this.deleteListModal = modal
  }

  public setDeleteCategoryModal = (modal: boolean): void => {
    this.deleteCategoryModal = modal
  }

  public setNameListNameField = (nameString: string): void => {
    this.nameListNameField = nameString
  }

  public setCategoryNameModal = (modal: boolean): void => {
    if (!modal) {
      this.categoryNameField = ''
      this.categoryToRename = null
    }
    this.categoryNameModal = modal
  }

  public setNameListNotesField = (notes: string): void => {
    this.nameListNotesField = notes
  }

  public setCategoryNameModalType = (type: 'new' | 'rename'): void => {
    this.categoryNameModalType = type
  }

  public setListNameModalType = (type: 'new' | 'rename'): void => {
    this.listNameModalType = type
    if (type === 'new') {
      this.nameListNameField = ''
      this.nameListNotesField = ''

      /*  In below step is necessary because, when we create a new list, we are resetting the fields to empty. So we are overriding
      previosly selected list's values in nameListNameField, nameListNameField with empty strings. To get those values in these properties we need
      to reselect the list. Then only a fresh API call(getListDetails) will happen and these values will be set.

      Otherwise we should make a fresh API call(getListDetails) for the selectedList to restore the nameListNameField & nameListNotesField values after we clonse new list modal.
       */
      if (this.selectedList) {
        this.selectedList = undefined
      }
    }
  }

  public setCategoryNameField = (name: string): void => {
    this.categoryNameField = name
  }

  public setNameListModal = (modal: boolean): void => {
    this.nameListModal = modal
  }

  public setMoveListModal = (modal: boolean): void => {
    this.moveListModal = modal
  }

  public setSelectedList = async (selected: ListNode): Promise<void> => {
    this.selectedList = selected
    if (selected) {
      await this.getListGroupDetails(selected.id)
    }
    if (this.selectedListGroups.length > 0) {
      this.setSelectedGroupValue(0)
    }
    StoreInstances.uiStore.listStoreProps.selectedList = selected
  }

  public setSelectedCategory = (selected: ListNode): void => {
    this.selectedCategory = selected
    StoreInstances.uiStore.listStoreProps.selectedCategory = selected
  }

  public setSelectedGroup = (selected: ListNode): void => {
    this.selectedGroup = selected
    StoreInstances.uiStore.listStoreProps.selectedGroup = selected
    /* @TODO add get products endpoint */

    this.getListGroupParts()
  }

  public setSelectedGroupValue = (newValue: number): void => {
    this.currentPage = 0
    this.selectedGroupValue = newValue
    StoreInstances.uiStore.listStoreProps.selectedGroupValue = newValue
  }

  public setDeleteSelectedList = (selected: SelectedList): void => {
    this.deleteSelectedList = selected
  }

  public setDeleteSelectedCategory = (selected: SelectedCategory): void => {
    this.deleteSelectedCategory = selected
  }

  public setMoveSelectedList = (selected: MoveSelectedList): void => {
    this.moveSelectedList = selected
  }

  public setNameSelectedList = (selected: NameSelectedList): void => {
    this.nameSelectedList = selected
  }

  public setCategoryToRename = (category: IdValuePair<string>): void => {
    this.categoryToRename = category
  }

  public onDeleteList = async (selected: number): Promise<void> => {
    this.deleteListModal = false
    this.deleteSelectedList = null

    try {
      const resp = await ListServiceProvider.deleteList(selected)

      if (resp.status === 200) {
        this.setListDeleted(true)
        this.getCategories()
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully deleted list'
        )
      }
    } catch (e: unknown) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listDeletionError')
      }
    }
  }

  public onDeleteGroupPart = async (
    deletingPartIds: Array<number>
  ): Promise<void> => {
    const partIdDelete: DeleteGroupParts = {
      partIds: deletingPartIds,
    }
    this.deleteSelectedGroupPart = null

    try {
      const status = await ListServiceProvider.deleteGroupPart(partIdDelete)

      if (status === 200) {
        this.resetSelectedPartsList(deletingPartIds)
        this.getListGroupParts()
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully deleted part'
        )
        if (this.isInSearchMode() !== undefined) {
          this.setSearchTerm(this.searchTerm)
        }
      }
    } catch (e: unknown) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listDeletionError')
      }
    }
  }

  public onDeleteCategory = async (selected: number): Promise<void> => {
    const category = this.deleteSelectedCategory
    this.deleteCategoryModal = false
    this.deleteSelectedCategory = null

    try {
      const resp = await ListServiceProvider.deleteCategory(selected)

      if (resp.status === 200) {
        this.setCategoryDeleted(true)
        this.getCategories()
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyDeletedCategory'
        )
      }
    } catch (_e) {
      const message = 'cannotDeleteCategory'
      const messageParams = [category?.label]
      StoreInstances.uiStore.displayErrorNotification(
        // TODO: refactor the toast notification to accept message parameters because it's too risky right now
        message,
        undefined, // undefined link
        undefined, // undefined time
        messageParams
      )
    }
  }

  public onMoveList = (listId: number, targetCategoryId: number): void => {
    this.moveListToAnotherCategory(listId, targetCategoryId)
  }

  public moveListToAnotherCategory = async (
    listId: number,
    targetCategoryId: number
  ): Promise<void> => {
    try {
      const status = await ListServiceProvider.moveListToAnotherCategory(
        listId,
        targetCategoryId
      )

      if (status === 200) {
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyListMoved'
        )

        this.moveListModal = false
        this.moveSelectedList = null

        this.setListDeleted(true)
        this.getCategories()
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      } else {
        StoreInstances.uiStore.displayErrorNotification('errorMovingList')
      }
    }
  }

  public onNameList = (selected?: number): void => {
    if (this.listNameModalType === 'rename') {
      this.updateList(selected)
    } else {
      this.selectedGroup = undefined
      this.selectedListGroups = []
      this.createList()
    }
  }

  public createCategory = async (name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.createCategory(name)
      if (id !== null && id > 0) {
        this.setCategoryNameField('')
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully created category'
        )
      }
      this.getCategories()
      this.categoryNameModal = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public updateCategory = async (
    name: string,
    categoryId: number
  ): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.updateCategory(name, categoryId)
      if (id !== null && id > 0) {
        this.setCategoryNameField('')

        if (this.selectedCategory.id === categoryId) {
          this.selectedCategory.value = name
        }
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully updated category'
        )
      }
      this.getCategories()
      this.categoryNameModal = false
      this.categoryToRename = null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public setNewListCategoryId = (categoryId: number): void => {
    this.newListCategoryId = categoryId
  }

  public createList = async (): Promise<void> => {
    const requestData: RequestBodyNewList = {
      name: this.nameListNameField,
      note: this.nameListNotesField,
      categoryId: this.newListCategoryId,
    }
    try {
      const newlyCreatedList = await ListServiceProvider.createList(requestData)
      const updatedListNode = new ListNode(
        newlyCreatedList?.id,
        newlyCreatedList?.name,
        'list'
      )

      this.setSelectedList(updatedListNode)
      if (newlyCreatedList?.id !== null && newlyCreatedList?.id > 0) {
        this.getCategories()
        this.setNameListNameField('')
        this.setNameListNotesField('')
        this.nameListModal = false
        this.nameSelectedList = null
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyCreatedList'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      }
    }
  }

  public cutText = (text: string): string => {
    const maxLength = 4
    let cutText = text.substr(0, maxLength)

    // Check if the cut text ends with a period
    if (cutText.endsWith('.')) {
      return cutText
    }

    cutText = cutText.trim()

    // Check if there are spaces within the cut text
    const lastSpaceIndex = cutText.lastIndexOf(' ')
    if (lastSpaceIndex !== -1) {
      cutText = cutText.substr(0, lastSpaceIndex)
    }

    return cutText + '...'
  }

  public createListAndGroup = async (
    requestData: {
      name: string
      note: string
      categoryId: number
    },
    groupData: string,
    req: Array<AddPartToList>
  ): Promise<void> => {
    try {
      const responseCreateList =
        await ListServiceProvider.createList(requestData)
      let groupDefaultId = responseCreateList?.groups[0]?.id

      if (responseCreateList.id !== null && responseCreateList.id > 0) {
        this.getCategories()
        this.setNameListNameField('')
        this.setNameListNotesField('')
        this.nameListModal = false
        this.nameSelectedList = null

        if (groupData) {
          const { id: groupId } = await ListServiceProvider.createGroup(
            responseCreateList.id,
            groupData
          )
          groupDefaultId = groupId
        }

        const status = await ListServiceProvider.addPartToGroup(
          responseCreateList.id,
          groupDefaultId,
          req
        )
        if (status === 200 || status === 206) {
          let description = `${req[0]?.partDescription} (${req[0]?.partNumber})`
          const isTablet = window.innerWidth < breakpoints.tablet
          if (isTablet) {
            description = this.cutText(description)
          }
          StoreInstances.uiStore.displaySuccessNotification(
            `${description} was added to list: ${this.selectedList?.value} `,
            {
              text: 'View Lists',
              action: req[0]?.handleListLocation,
            }
          )
          this.getListGroupParts()
          req[0]?.handleClose()
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 403) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        const responseMessage = message.split(':')
        StoreInstances.uiStore.displayErrorNotification(responseMessage[1])
      }
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification(
          'List name already exists'
        )
      }
    }
  }

  public updateList = async (listId: number): Promise<void> => {
    const requestData: RequestBodyNewList = {
      name: this.nameListNameField,
      note: this.nameListNotesField,
    }

    try {
      const { id } = await ListServiceProvider.updateList(requestData, listId)
      if (id !== null && id > 0) {
        this.getCategories(true)
        this.nameListModal = false

        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyUpdatedList'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }

      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      }
    }
  }

  public createGroup = async (listId: number, name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.createGroup(listId, name)
      if (id !== null && id > 0) {
        this.getListGroupDetails(listId, true)
        this.setShowAddEditGroupModal(false)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyCreatedGroup'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 403) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }

      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification(
          'groupNameAlreadyExists'
        )
      }
    }
  }

  public updateGroup = async (name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.updateGroup(
        name,
        this.selectedGroup.id
      )
      if (id !== null && id > 0) {
        this.getListGroupDetails(this.selectedList.id, true)
        this.setShowAddEditGroupModal(false)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyUpdatedGroup'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public deleteGroup = async (): Promise<void> => {
    try {
      const status = await ListServiceProvider.deleteGroup(
        this.selectedGroup.id
      )

      if (status === 200) {
        this.getListGroupDetails(this.selectedList.id)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyDeletedGroup'
        )
        this.setSelectedGroupValue(0)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public onNameCategory = (id?: number): void => {
    if (this.categoryNameModalType === 'rename') {
      this.updateCategory(this.categoryNameField, id)
    } else {
      this.createCategory(this.categoryNameField)
    }
  }

  public getListGroupDetails = async (
    listId: number,
    noGroupSelection = false
  ): Promise<void> => {
    this.selectedList.deleteAllChildren()
    try {
      const { groups, name, note } = await ListServiceProvider.getListDetails(
        this.selectedCategory.id,
        listId
      )
      this.setNameListNameField(name)
      this.setNameListNotesField(note)
      groups.forEach((group) => {
        const groupNode = new ListNode(group.id, group.name)
        this.selectedList.addChild(groupNode)
      })
      this.selectedListGroups = this.selectedList.getChildren()
      StoreInstances.uiStore.listStoreProps.selectedListGroups =
        this.selectedList.getChildren()
      if (!noGroupSelection) {
        this.setSelectedGroup(this.selectedList.getFirstChild())
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    }
  }

  public getListGroups = async (
    categoryId: number,
    listId: number
  ): Promise<void> => {
    try {
      const { groups } = await ListServiceProvider.getListDetails(
        categoryId,
        listId
      )
      this.moveListGroups = groups
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    }
  }

  public onMoveGroupParts = async (
    targetGroupId: number,
    movingGroupPartIds: Array<number>
  ): Promise<void> => {
    const movingGroupParts: MoveGroupParts = {
      partIds: movingGroupPartIds,
    }

    try {
      const status = await ListServiceProvider.movePartsToAnotherGroup(
        targetGroupId,
        movingGroupParts
      )

      if (status === 200) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully parts have been moved'
        )
        if (this.isInSearchMode() !== undefined) {
          this.setSearchTerm(this.searchTerm)
          return
        }
        this.getListGroupDetails(this.selectedList.id)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('Error moving parts')
      }
    }
  }

  public getCategories = async (isListUpdated?: boolean): Promise<void> => {
    /* GET for the side panel */
    this.loadingCategories = true
    const { categories } =
      await ListServiceProvider.getCategoriesAndLists() /* @TODO: get categories list */
    this.root.deleteAllChildren()
    for (const category of categories) {
      const categoryNode = new ListNode(category.id, category.name, 'category')
      if (categoryNode?.id === this.selectedCategory?.id)
        categoryNode.open = true
      for (const list of category.lists) {
        const listNode = new ListNode(list.id, list.name, 'list')
        categoryNode.addChild(listNode)
      }
      this.root.addChild(categoryNode)
    }
    const allCategories: ListNode[] = this.root.getChildren()
    const firstNonEmptyCategory: ListNode = allCategories.find(
      (eachCategory) => eachCategory.getChildren().length !== 0
    )
    if (!this.selectedCategory && firstNonEmptyCategory) {
      firstNonEmptyCategory.open = true
      this.setSelectedCategory(firstNonEmptyCategory)
    }

    const firstList = firstNonEmptyCategory?.getFirstChild()
    if (!this.selectedList && firstList) this.setSelectedList(firstList)

    // this is required to re-render updated list label and also to maintain selected category and list as per (MP4P-954)
    if (isListUpdated) {
      const upDatedList = categories
        ?.find((item) => item.id === this.selectedCategory?.id)
        ?.lists.find((item) => item.id === this.selectedList?.id)
      const updatedListNode = new ListNode(
        upDatedList.id,
        upDatedList.name,
        'list'
      )
      this.setSelectedList(updatedListNode)
    }

    if (this.onCategoryDeleted || this.onListDeleted) {
      this.setSelectedCategory(firstNonEmptyCategory)
      this.setSelectedList(firstList)
    }

    this.loadingCategories = false
  }

  public setCategoryDeleted = (value: boolean): void => {
    this.onCategoryDeleted = value
  }

  public setListDeleted = (value: boolean): void => {
    this.onListDeleted = value
  }

  public getSelectedCategoryLabel = (): string => {
    return this.selectedCategory?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
  public getSelectedCategoryItems = (): any => {
    return this.selectedCategory?.getChildren()
  }

  public getSelectedListLabel = (): string => {
    return this.selectedList?.value
  }

  private prepareSortData = (): string => {
    return this.sortOrder
      .map((item) => `${item.property}_${item.dir === 'asc' ? 0 : 1}`)
      .join()
  }

  public getListGroupParts = async (): Promise<void> => {
    try {
      this.setGroupPartsLoading(true)
      if (!this.selectedList || !this.selectedGroup) {
        this.setGroupPartsLoading(false)
        return
      }

      const fetchParts = async () => {
        return await ListServiceProvider.getGroupDetails(
          this.selectedList.id,
          this.selectedGroup.id,
          {
            start: this.currentPage,
            limit: this.itemsPerPage,
            sortBy: this.prepareSortData(),
          }
        )
      }
      let response = await fetchParts()

      /*
      The below code block till the end of the `if` block is written for bug/MPV3-2423.
      After adding a part without linecode(MFR) to a given group under a list, we need to validate 
      the part to add parts with same part number and multiple MFRs to the Group. For example if a part with 
      id 121949779 is added to group without MFR, then if we validate the part, then a new set of parts 
      be created with Ids from 121949780 onwards and 121949779 part will be deleted from the group. The new 
      set of parts will have all the possible lineCodes(MFRs). This is my observation from the code behaviour.
      */
      const partsWithNoLineCode = response.items
        .filter((item) => !item.lineCode)
        .map((item) => item.id)
      if (partsWithNoLineCode && partsWithNoLineCode.length > 0) {
        await ListServiceProvider.validateParts({
          partIds: partsWithNoLineCode,
        })
        response = await fetchParts()
      }
      const { items, totalCount } = response
      this.setGroupPartsLoading(false)
      setTimeout(() => {
        StoreInstances.userStore.refetchUserPreferences()
      }, 3000)
      const temp = items.map((part) => {
        return {
          ...part,
          isSelected: this.selectedPartsList.some(
            (sPart) => sPart.id === part.id
          ),
        }
      })
      this.selectedGroupParts = temp
      if (totalCount === undefined) {
        this.selectedGroupPartsCount = 0
      } else {
        this.selectedGroupPartsCount = totalCount
      }
    } catch (error) {
      const isEmpty = Object.entries(error).length === 0

      if (isEmpty) {
        this.selectedGroupParts = []
        this.selectedGroupPartsCount = 0
      } else {
        const { message }: ListResponseError = (error as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
    }
    this.setCategoryDeleted(false)
    this.setListDeleted(false)
    this.setGroupPartsLoading(false)
  }

  public resetSelectedGroupParts = (): void => {
    this.selectedPartsList = []
  }

  public unSelectGroupParts = (): void => {
    const unSelectionGroupParts = this.selectedGroupParts?.map((part) => {
      return {
        ...part,
        isSelected: false,
      }
    })
    this.selectedGroupParts = unSelectionGroupParts
  }

  public isInSearchMode = (): boolean => {
    return this.searchTerm
  }

  public getMultiSearchParts = (): (Product & {
    isSelected: boolean
  })[] => {
    const allParts = []
    this.matchingFilteredSearchTerms.forEach((list) => {
      list.groups.forEach((group) => {
        group.items.forEach((part) => {
          allParts.push(part)
        })
      })
    })
    return allParts
  }

  public getMultiSearchSelectedParts = (): (Product & {
    isSelected: boolean
  })[] => {
    const selectedParts = this.getMultiSearchParts()?.filter(
      (part) => part.isSelected
    )
    return selectedParts
  }

  private setGroupPartsLoading = (isLoading: boolean) => {
    this.groupPartsLoading = isLoading
  }

  public addPartToGroup = async (req: Array<AddGroupPart>): Promise<void> => {
    try {
      const status = await ListServiceProvider.addPartToGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully added item to group'
        )
        this.getListGroupParts()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public addPartToList = async (req: Array<AddPartToList>): Promise<void> => {
    try {
      const status = await ListServiceProvider.addPartToGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        let description = `${req[0]?.partDescription} (${req[0]?.partNumber})`
        const isTablet = window.innerWidth < breakpoints.tablet
        if (isTablet) {
          description = this.cutText(description)
        }
        StoreInstances.uiStore.displaySuccessNotification(
          `${description} was added to list: ${this.selectedList?.value} `,
          {
            text: 'View Lists',
            action: req[0]?.handleListLocation,
          }
        )

        this.getListGroupParts()
        req[0]?.handleClose()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public updatePartInGroup = async (
    req: Array<UpdateGroupPart>
  ): Promise<void> => {
    try {
      const status = await ListServiceProvider.updatePartInGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully updated part'
        )
        this.getListGroupParts()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
      throw error
    }
  }

  public sortGroupParts = (sortList: Array<GroupPart>): void => {
    this.selectedGroupParts = sortList
  }

  public selectingAllGroupParts = (selection: boolean): void => {
    const temp = this.selectedGroupParts.map((part) => {
      return {
        ...part,
        isSelected: selection,
      }
    })
    this.selectedGroupParts = temp

    if (selection) {
      let result
      if (!this.selectedPartsList.length) {
        result = temp
      } else {
        result = temp.filter(
          (o1) => !this.selectedPartsList.some((o2) => o1?.id === o2?.id)
        )
      }
      this.selectedPartsList = [...this.selectedPartsList, ...result]
    } else {
      const result = this.selectedPartsList.filter(
        (o1) => !temp.some((o2) => o1?.id === o2?.id)
      )
      this.selectedPartsList = [...result]
    }
  }

  public selectAllPartsFromAList = (
    isSelected: boolean,
    listId: number
  ): void => {
    const temp = this.matchingFilteredSearchTerms.map((list) => {
      if (list.id === listId) {
        return {
          ...list,
          groups: list.groups.map((group) => {
            return {
              ...group,
              items: group.items.map((part) => {
                return { ...part, isSelected }
              }),
            }
          }),
        }
      }
      return list
    })
    this.matchingFilteredSearchTerms = temp
  }

  public countAllPartsInAList = (listId: number): number => {
    let count = 0
    this.matchingFilteredSearchTerms.forEach((list) => {
      if (list.id === listId) {
        list.groups.forEach((group) => {
          group.items.forEach(() => {
            count += 1
          })
        })
      }
    })
    return count
  }

  public addOrRemoveParts = (
    id: number,
    selection: boolean,
    part: GroupPart
  ): void => {
    const idx = this.selectedPartsList.findIndex((part) => part.id === id)
    if (selection) {
      if (idx < 0) {
        this.selectedPartsList = [...this.selectedPartsList, part]
      } else {
        this.selectedPartsList = [...this.selectedPartsList].map((spart) => {
          if (spart.id === id) {
            const updatedPart: GroupPart = { ...spart, ...part }
            return updatedPart
          }
          return spart
        })
      }
    } else {
      if (idx >= 0) {
        this.selectedPartsList = this.selectedPartsList.filter(
          (part) => part.id !== id
        )
      }
    }
  }

  public onSelectGroupPart = (id: number, isSelected: boolean): void => {
    const updatedPart = this.updateGroupPart(id, { isSelected })
    this.addOrRemoveParts(id, isSelected, updatedPart)
  }

  private updateGroupPart = (
    id: number,
    payload: IUpdateGroupPartPaylod
  ): GroupPart => {
    let updatedPart: GroupPart
    if (this.searchTerm) {
      const temp = this.matchingFilteredSearchTerms.map((list) => {
        return {
          ...list,
          groups: list.groups.map((group) => {
            return {
              ...group,
              items: group.items.map((part) => {
                if (part.id === id) {
                  updatedPart = { ...part, ...payload }
                  return updatedPart
                }
                return part
              }),
            }
          }),
        }
      })
      this.matchingFilteredSearchTerms = temp
    } else {
      const temp = this.selectedGroupParts.map((part) => {
        if (part.id === id) {
          updatedPart = { ...part, ...payload }
          return updatedPart
        }
        return part
      })
      this.selectedGroupParts = temp
    }
    return updatedPart
  }

  public updatePartQty = (
    stockQty: number,
    id: number,
    isSelected: boolean
  ): void => {
    this.updateGroupPart(id, { stockQty })
    this.onSelectGroupPart(id, isSelected)
  }

  public sortListParts = (sortList: Array<CheckedListParts>): void => {
    this.checkedListParts = sortList
  }

  public selectAllListParts = (checked: boolean): void => {
    const temp = this.checkedListParts.map((part) => {
      return {
        ...part,
        checked,
      }
    })
    this.checkedListParts = temp
  }

  public selectedListPart = (id: number, checked: boolean): void => {
    const temp = this.checkedListParts.map((part) => {
      if (part.id === id) {
        return { ...part, checked }
      }
      return part
    })
    this.checkedListParts = temp
  }

  setImportListPartsLoading = (loading: boolean): void => {
    this.importListPartsLoading = loading
  }

  public importPartsList = async (
    listId: string,
    fileToImport: File
  ): Promise<ImportStatus> => {
    try {
      this.setImportListPartsLoading(true)
      this.listImportRespData = await ListServiceProvider.importListParts(
        listId,
        fileToImport
      )
      const queueId = this.listImportRespData?.queueId
      return await this.monitorQueueStatus(queueId)
    } catch (_e) {
      this.listImportRespData = {
        queueId: '',
      }
      return ImportStatus.FAILED
    } finally {
      this.setImportListPartsLoading(false)
    }
  }

  public monitorQueueStatus = async (
    queueId: string,
    counter = 0
  ): Promise<ImportStatus> => {
    const { STARTED, PROCESSING, FAILED } = ImportStatus

    await new Promise((resolve) => setTimeout(resolve, this._retryInterval))

    counter++
    if (counter > this._retryLimit) {
      StoreInstances.uiStore.displayErrorNotification('listImportTimeOutMsg')
      return FAILED
    }

    const status = await this.checkImportStatus(queueId)
    if (status == STARTED || status == PROCESSING)
      return this.monitorQueueStatus(queueId, counter)
    return status
  }

  public checkImportStatus = async (queueId: string): Promise<ImportStatus> => {
    const { PROCESSING, FAILED, SUCCESS } = ImportStatus

    const resp = await ListServiceProvider.getListImportDetails(queueId)

    if (!resp) return PROCESSING //  Assume processing due to observed API behavior

    const error = resp?.find(({ status }) => status === FAILED)
    if (error) {
      const message = error?.message
      StoreInstances.uiStore.displayErrorNotification(message)
      return FAILED
    }

    const success = resp?.find(({ status }) => status === SUCCESS)
    if (success) {
      this.listImportDetails = success
      this.getListGroupDetails(this.selectedList.id)
      ListServiceProvider.deleteListQueue(queueId)
      return SUCCESS
    }

    return PROCESSING
  }

  private isAutoLocationChangeEnabled = (): boolean => {
    return (
      StoreInstances.userStore?.preferences?.findit_orderIfNotAvail === 'true'
    )
  }

  public setChangeLocationParts = (
    changeLocationParts: Array<ProductLocationModel>
  ): void => {
    this.changeLocationParts = changeLocationParts
  }

  public setShowAddEditGroupModal = (value: boolean): void => {
    this.showAddEditGroupModal = value
  }

  public getListByIdAndGroupId = async (
    listId: number,
    groupId: number
  ): Promise<GroupPartResponse> => {
    this.groupPartsLoading = true
    const groupDetails = await ListServiceProvider.getGroupDetails(
      listId,
      groupId,
      {
        start: 0,
        limit: 1000,
      }
    )
    this.groupPartsLoading = false
    return groupDetails
  }

  public toggleCategory = (category: ListNode): void => {
    const cat = this.root?.getChildren()?.find((c) => c.id === category.id)
    cat.open = !cat?.open
  }

  public resetSelectedListGroups = (): void => {
    this.selectedListGroups = []
  }

  public handleAddToCart = (listParts: ValidateListPart[]): void => {
    const { cart, uiStore } = StoreInstances
    cart.addVehicleToCart(this.selectedVehicle)
    const allParts = this.isInSearchMode()
      ? this.getMultiSearchSelectedParts()
      : this.selectedPartsList

    // bug/MPV3-3790 parts with min quantity should be updated in the cart.
    let hasPartQtyChanged = false
    try {
      const cartParts = listParts.map((part) => {
        const stockQty = allParts.find((p) => p.id === part.id).stockQty
        const minQty = part.location?.find((loc) => loc.isSelected).minQty

        const hasMinQuantity = stockQty >= minQty

        const partQuantity = hasMinQuantity ? stockQty : minQty

        if (!hasMinQuantity) {
          hasPartQtyChanged = !hasMinQuantity
        }

        return {
          product: part,
          locationId: part.location?.[0]?.locationId,
          quantity: partQuantity,
          vehicle: this.selectedVehicle,
          autoLocationChange: this.isAutoLocationChangeEnabled(),
        }
      })

      cart
        .setQtyAtLocationBulk(cartParts, false)
        .then(() => {
          const shoppingCartVehicle = cart.findCartVehicle(this.selectedVehicle)
          if (hasPartQtyChanged)
            uiStore.displaySuccessNotification(`quantityRequestedHasChanged`)
          showCartPartComparisionNotification(
            listParts,
            shoppingCartVehicle,
            [],
            [],
            {
              text: 'view cart', // TODO: Make trannslations work outside react components.
              action: () => {
                history.push('/cart')
              },
            }
          )
          this.unSelectGroupParts()
          this.resetSelectedGroupParts()
          this.setListPartsToCartLoading(false)
        })
        .catch((e) => {
          uiStore.displayErrorNotification(e.message)
        })
        .finally(() => {
          this.setListPartsToCartLoading(false)
        })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      uiStore.displayErrorNotification(e.message)
    }
  }

  public validateAndAddToCart = async (
    req: ValidatePartsRequest
  ): Promise<void> => {
    try {
      const validatedListParts = await getValidatedPartsList(req)
      return this.handleAddToCart(validatedListParts || [])
    } catch (e) {
      StoreInstances.uiStore.displayErrorNotification(
        `No Part was added to the cart`
      )
      throw e
    }
  }

  public addListPartsToCart = (): void => {
    this.setListPartsToCartLoading(true)
    const partsToValidate = this.isInSearchMode()
      ? this.getMultiSearchSelectedParts()?.map((part) => part.id) || []
      : this.selectedPartsList?.map((part) => part.id) || []
    this.validateAndAddToCart({
      partIds: partsToValidate,
    }).catch(() => {
      StoreInstances.uiStore.displayErrorNotification('errorValidatingList')
    })
  }

  public setListPartsToCartLoading = (value: boolean): void => {
    this.arePartsBeingAddedToCart = value
  }
}

export const ListsStoreContext = createContext<ListsStore>(undefined)

export const useListsStore = (): ListsStore => {
  return useContext(ListsStoreContext)
}
