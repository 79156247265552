import { ReactElement, useMemo } from 'react'
import { I18n } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

interface Props {
  title: string
  totalItems: number
  itemsPerPage: number
  page: number
}

const ItemsIntervalDescription = (props: Props): ReactElement => {
  const { title, totalItems, itemsPerPage, page } = props

  const interval = useMemo((): string => {
    const currentPage = page - 1 // To make calculations easier
    const startingPoint = currentPage * itemsPerPage
    const firstElement = startingPoint + 1
    const lastElement = Math.min(startingPoint + itemsPerPage, totalItems)
    return ` ${firstElement} - ${lastElement} `
  }, [page, itemsPerPage, totalItems])

  return (
    <ResponsiveInterval>
      <Text style={{ textTransform: 'capitalize' }}>
        <I18n name="showing" />
        {interval}
        <I18n name="outOf" />
        {` ${totalItems} `}
        <I18n name={title} />
      </Text>
    </ResponsiveInterval>
  )
}

const ResponsiveInterval = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

export default ItemsIntervalDescription
