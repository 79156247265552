import styled from 'styled-components'

export const H6 = styled.div`
  font-family: Oswald;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
`
