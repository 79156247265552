export class CantBeFulfilledException extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CantBeFulfilledException'
  }
}

export class CantBeFulfilledFromSelectedLocationException extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CantBeFulfilledFromSelectedLocationException'
  }
}
