import { Assignment } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { show as showNiceModal } from '@ebay/nice-modal-react'
import { ImportListPartsModal } from './RightPanel/ImportListPartsModal'
import { AddProductsToGroup } from './RightPanel/AddProductsToGroup'
import { defaultTheme } from 'src/theme'

const EmptyGroupScreen = observer((): ReactElement => {
  const listStore = useListsStore()

  const handleImportFile = (): void => {
    // TODO: logic to be replaced in an upcoming story
    showNiceModal(ImportListPartsModal, {
      listId: listStore.selectedList?.id?.toString(),
      store: listStore,
    })
  }

  const handleAddManually = (): void => {
    // TODO: logic to be replaced in an upcoming story
    showNiceModal(AddProductsToGroup, {
      categoryLabel: listStore.getSelectedCategoryLabel(),
      listLabel: listStore.getSelectedListLabel(),
      selectedGroup: listStore.selectedGroup,
      selectedList: listStore.selectedList,
      getListGroupParts: listStore.getListGroupParts,
    })
  }

  return (
    <Wrapper>
      <StyledAssignment />
      <Title>
        <I18n name="thisGroupIsEmpty" />
      </Title>
      <Instructions>
        <I18n name="clickButtonBelowToBeginAddingItemsToThisGroup" />
      </Instructions>
      <Flex>
        <StyledButton
          text="importFile"
          variant="secondary"
          onClick={handleImportFile}
        />
        <StyledButton
          text="addManually"
          variant="primary"
          onClick={handleAddManually}
        />
      </Flex>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
`

const StyledAssignment = styled(Assignment)`
  width: 54px;
  height: 60px;
  color: ${defaultTheme.colors.greyMedium};
`

const Title = styled.div`
  font-family: Oswald;
  font-size: 22px;
  font-weight: 700;
  line-height: 27.5px;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 0rem 1rem 0rem;
`

const Instructions = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 0rem 0rem 1rem 0rem;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  margin: 0rem 0.5rem 0rem 0.5rem;
  height: 48px;
`

export default EmptyGroupScreen
