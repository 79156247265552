import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { JobsCatalogAPI } from '../partsCatalog/api/JobsCatalogAPI'
import { PartsCatalog } from '../partsCatalog/PartsCatalog'

export const JobsCatalogContext = createContext<JobsCatalogStore>(undefined)

export const useJobsCatalog = (): JobsCatalogStore =>
  useContext(JobsCatalogContext)

export class JobsCatalogStore {
  catalog: PartsCatalog

  api: JobsCatalogAPI

  constructor() {
    makeAutoObservable(this)
    this.api = new JobsCatalogAPI()
    this.catalog = new PartsCatalog(this.api)
  }
}
