import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { ButtonVariant } from 'src/ui-components/forms/Button'
import { zIndex } from 'src/theme/zIndex'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import { MiscPreferencesKeys, useUserStore } from 'src/store/user/UserStore'
import { parseBoolean } from '../util/CommonFunctions'
import { useSearchStore } from 'src/store/search/SearchStore'
import PartTypesComponent from './PartTypesComponents'
import ManufacturerComponent from './ManufacturerComponent'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useHistory } from 'react-router-dom'
import { getPartTypes } from 'src/helpers/HeavyDutyProvider'
import { getManufacturers } from 'src/helpers/HeavyDutyProvider'
interface PartTypeModalProps {
  title: string
  subtitle: string
  modeltype: string
  primaryActionText?: string
  primaryButtonVariant?: ButtonVariant
  primaryButtonToTheLeft?: boolean
  primaryButtonIcon?: React.ReactElement
  secondaryActionText?: string
  dontAskThisAgainText?: string
  size?: 'lg' | 'sm' | 'xl'
  borderBottomHeader?: boolean
  borderTopFooter?: boolean
  onCancel?: () => void
  loading?: boolean
  buttonsDirection?: 'column' | 'row'
}

interface PartsList {
  partTypes: string[]
  partsBinId: string
}
interface ManufacturerList {
  manufacturerList: string[]
  partsBinId: string
}

export const PartTypeModal = createNiceModal<PartTypeModalProps>(
  (props: PartTypeModalProps): ReactElement => {
    const modal = useModal()

    const {
      title,
      subtitle,
      modeltype,
      primaryButtonVariant,
      borderBottomHeader = false,
      borderTopFooter,
      size,
      primaryButtonToTheLeft = false,
      primaryButtonIcon,
      buttonsDirection = 'row',
    } = props
    const uiStore = useUiStateStore()
    const history = useHistory()
    const [userPartTypes, setUserPartTypes] = useState<PartsList>({
      partTypes: [],
      partsBinId: '',
    })
    const [isSelectedAllPartTypeOptions, setIsSelectedAllPartTypeOptions] =
      useState<boolean>(true)
    const [
      isSelectedAllManufacturerOptions,
      setIsSelectedAllManufacturerOptions,
    ] = useState<boolean>(true)
    const [userManufacturerTypes, setUserManufacturerTypes] =
      useState<ManufacturerList>({ manufacturerList: [], partsBinId: '' })
    const [filtredInterchangeOptions, setFiltredInterchangeOptions] = useState<
      string[]
    >([])
    const [selectedPartTypeOptions, setSelectedPartTypeOptions] = useState<
      string[]
    >([])
    const [selectedManufactureOptions, setManufactureOptions] = useState<
      string[]
    >([])
    const [filteredBrands, setFilteredBrands] = useState<string[]>([])
    const userStore = useUserStore()
    const searchStore = useSearchStore()
    useEffect(() => {
      setModelTypeValue(modeltype)
    }, [modeltype])
    const [modelTypeValue, setModelTypeValue] = useState<string>('')
    const [doNotAskPartType, setDoNotAskPartType] = useState<boolean>(true)
    const [doNotAskManufactureType, setDoNotAskManufactureType] =
      useState<boolean>(true)

    useEffect(() => {
      const uniquePartTypes = [
        ...new Set(userPartTypes?.partTypes?.map((part) => part)),
      ]
      setFiltredInterchangeOptions(uniquePartTypes)
    }, [userPartTypes.partTypes])
    useEffect(() => {
      if (modeltype && modeltype === 'PartType') {
        getPartTypes(subtitle, history)
          .then((res) => {
            if (res?.partTypes?.length === 0) {
              modal.remove()
              searchStore.getInterchangeApiRequest(
                subtitle,
                [],
                [],
                userPartTypes?.partsBinId,
                parseBoolean(
                  userStore.miscPreferences?.[
                    MiscPreferencesKeys.INTERCHANGE_PARTTYPES
                  ]
                ),
                parseBoolean(
                  userStore.miscPreferences?.[
                    MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
                  ]
                )
              )
            } else {
              setUserPartTypes(res)
            }
          })
          .catch((err) => {
            uiStore.displayErrorNotification(
              err.response?.data?.message || 'unableToFetchPartTypes'
            )
            modal.remove()
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeltype])
    useEffect(() => {
      if (modeltype && modeltype === 'Manufacture') {
        const selectedParts = {
          partTypes: [],
          partsBinId: '',
          interchangeText: subtitle,
        }
        getManufacturers(selectedParts, history)
          .then((res) => {
            if (res?.manufacturerList?.length === 0) {
              modal.remove()
              searchStore.getInterchangeApiRequest(
                subtitle,
                [],
                [],
                userManufacturerTypes?.partsBinId,
                parseBoolean(
                  userStore.miscPreferences?.[
                    MiscPreferencesKeys.INTERCHANGE_PARTTYPES
                  ]
                ),
                parseBoolean(
                  userStore.miscPreferences?.[
                    MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
                  ]
                )
              )
            } else {
              setUserManufacturerTypes(res)
            }
          })
          .catch((err) => {
            uiStore.displayErrorNotification(
              err.response?.data?.message || 'unableToFetchManufacturer'
            )
            modal.remove()
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modeltype])
    useEffect(() => {
      const uniqueManufacturers = [
        ...new Set(userManufacturerTypes.manufacturerList.map((item) => item)),
      ].sort()
      setFilteredBrands(uniqueManufacturers)
    }, [userManufacturerTypes.manufacturerList])
    const partTypeSelectionHandler = useCallback(() => {
      if (
        parseBoolean(
          userStore.miscPreferences?.[
            MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
          ]
        )
      ) {
        if (
          selectedPartTypeOptions.length === filtredInterchangeOptions.length
        ) {
          const selectedParts = {
            partTypes: [],
            partsBinId: userPartTypes?.partsBinId,
            interchangeText: subtitle,
          }
          getManufacturers(selectedParts, history)
            .then((res) => {
              if (res?.manufacturerList?.length === 0) {
                modal.remove()
                searchStore.getInterchangeApiRequest(
                  subtitle,
                  [],
                  [],
                  userManufacturerTypes?.partsBinId,
                  parseBoolean(
                    userStore.miscPreferences?.[
                      MiscPreferencesKeys.INTERCHANGE_PARTTYPES
                    ]
                  ),
                  parseBoolean(
                    userStore.miscPreferences?.[
                      MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
                    ]
                  )
                )
              } else {
                setManufactureOptions([])
                setUserManufacturerTypes(res)
              }
            })
            .catch((err) => {
              uiStore.displayErrorNotification(
                err.response?.data?.message || 'unableToFetchManufacturer'
              )
              modal.remove()
            })
          setModelTypeValue('Manufacture')
        } else {
          const selectedParts = {
            partTypes: selectedPartTypeOptions,
            partsBinId: userPartTypes?.partsBinId,
            interchangeText: subtitle,
          }
          getManufacturers(selectedParts, history)
            .then((res) => {
              if (res?.manufacturerList?.length === 0) {
                modal.remove()
                searchStore.getInterchangeApiRequest(
                  subtitle,
                  [],
                  [],
                  userManufacturerTypes?.partsBinId,
                  parseBoolean(
                    userStore.miscPreferences?.[
                      MiscPreferencesKeys.INTERCHANGE_PARTTYPES
                    ]
                  ),
                  parseBoolean(
                    userStore.miscPreferences?.[
                      MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
                    ]
                  )
                )
              } else {
                setManufactureOptions([])
                setUserManufacturerTypes(res)
              }
            })
            .catch((err) => {
              uiStore.displayErrorNotification(
                err.response?.data?.message || 'unableToFetchManufacturer'
              )
              modal.remove()
            })
          setModelTypeValue('Manufacture')
        }
      } else {
        if (
          filtredInterchangeOptions.length === selectedPartTypeOptions.length
        ) {
          searchStore.getInterchangeApiRequest(
            subtitle,
            [],
            [],
            userPartTypes?.partsBinId,
            doNotAskPartType,
            doNotAskManufactureType
          )
          modal.remove()
          history.push('/searchResults')
          setManufactureOptions([])
        } else {
          searchStore.getInterchangeApiRequest(
            subtitle,
            selectedPartTypeOptions,
            [],
            userPartTypes?.partsBinId,
            doNotAskPartType,
            doNotAskManufactureType
          )
          modal.remove()
          history.push('/searchResults')
          setManufactureOptions([])
        }
      }
    }, [
      doNotAskManufactureType,
      doNotAskPartType,
      filtredInterchangeOptions.length,
      history,
      modal,
      searchStore,
      selectedPartTypeOptions,
      subtitle,
      uiStore,
      userManufacturerTypes?.partsBinId,
      userPartTypes?.partsBinId,
      userStore.miscPreferences,
    ])
    useEffect(() => {
      if (filtredInterchangeOptions.length === 1) {
        if (
          parseBoolean(
            userStore.miscPreferences?.[
              MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
            ]
          )
        ) {
          setSelectedPartTypeOptions(filtredInterchangeOptions)
          setModelTypeValue('Manufacture')
          partTypeSelectionHandler()
        } else {
          searchStore.getInterchangeApiRequest(
            subtitle,
            filtredInterchangeOptions,
            [],
            userPartTypes?.partsBinId,
            doNotAskPartType,
            doNotAskManufactureType
          )
          history.push('/searchResults')
          modal.remove()
        }
      }
    }, [
      doNotAskManufactureType,
      doNotAskPartType,
      filtredInterchangeOptions,
      filtredInterchangeOptions.length,
      history,
      modal,
      partTypeSelectionHandler,
      searchStore,
      subtitle,
      userPartTypes?.partsBinId,
      userStore.miscPreferences,
    ])
    useEffect(() => {
      if (filteredBrands.length === 1) {
        searchStore.getInterchangeApiRequest(
          subtitle,
          selectedPartTypeOptions,
          filteredBrands,
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      }
    }, [
      doNotAskManufactureType,
      doNotAskPartType,
      filteredBrands,
      history,
      modal,
      searchStore,
      selectedPartTypeOptions,
      subtitle,
      userManufacturerTypes?.partsBinId,
    ])

    const partTypeToggleSelectAll = () => {
      if (isSelectedAllPartTypeOptions) {
        setSelectedPartTypeOptions(filtredInterchangeOptions)
      } else {
        setSelectedPartTypeOptions([])
      }
      setIsSelectedAllPartTypeOptions(!isSelectedAllPartTypeOptions)
    }
    const manufactureToggleSelectAll = () => {
      if (isSelectedAllManufacturerOptions) {
        setManufactureOptions(filteredBrands)
      } else {
        setManufactureOptions([])
      }
      setIsSelectedAllManufacturerOptions(!isSelectedAllManufacturerOptions)
    }

    const partTypeCheckboxHandler = (value: string) => {
      setSelectedPartTypeOptions((prevSelected) =>
        prevSelected.includes(value)
          ? prevSelected.filter((optId) => optId !== value)
          : [...prevSelected, value]
      )
    }
    useEffect(() => {
      if (
        filtredInterchangeOptions.length > 0 &&
        filtredInterchangeOptions.length === selectedPartTypeOptions.length
      ) {
        setIsSelectedAllPartTypeOptions(false)
      }
    }, [filtredInterchangeOptions.length, selectedPartTypeOptions])

    useEffect(() => {
      if (
        filtredInterchangeOptions.length > 0 &&
        selectedPartTypeOptions.length > 0
      ) {
        if (
          filtredInterchangeOptions.length !== selectedPartTypeOptions.length
        ) {
          setIsSelectedAllPartTypeOptions(true)
        } else {
          setIsSelectedAllPartTypeOptions(false)
        }
      }
      if (filteredBrands.length > 0 && selectedManufactureOptions.length > 0) {
        if (filteredBrands.length !== selectedManufactureOptions.length) {
          setIsSelectedAllManufacturerOptions(true)
        } else {
          setIsSelectedAllManufacturerOptions(false)
        }
      }
    }, [
      filteredBrands.length,
      filtredInterchangeOptions.length,
      selectedManufactureOptions.length,
      selectedPartTypeOptions.length,
    ])
    const manufactureCheckboxHandler = (value: string) => {
      setManufactureOptions((prevSelected) =>
        prevSelected.includes(value)
          ? prevSelected.filter((optId) => optId !== value)
          : [...prevSelected, value]
      )
    }
    const partTypeSkipHandler = () => {
      searchStore.getInterchangeApiRequest(
        subtitle,
        [],
        [],
        userPartTypes?.partsBinId,
        doNotAskPartType,
        doNotAskManufactureType
      )
      modal.remove()
      history.push('/searchResults')
    }
    const manufactureSelectionHandler = () => {
      if (
        filtredInterchangeOptions.length === selectedPartTypeOptions.length &&
        filteredBrands.length === selectedManufactureOptions.length
      ) {
        searchStore.getInterchangeApiRequest(
          subtitle,
          [],
          [],
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      } else if (
        filtredInterchangeOptions.length !== selectedPartTypeOptions.length &&
        filteredBrands.length === selectedManufactureOptions.length
      ) {
        searchStore.getInterchangeApiRequest(
          subtitle,
          selectedPartTypeOptions,
          [],
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      } else if (
        filtredInterchangeOptions.length === selectedPartTypeOptions.length &&
        filteredBrands.length !== selectedManufactureOptions.length
      ) {
        searchStore.getInterchangeApiRequest(
          subtitle,
          [],
          selectedManufactureOptions,
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      } else {
        searchStore.getInterchangeApiRequest(
          subtitle,
          selectedPartTypeOptions,
          selectedManufactureOptions,
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      }
    }
    const manufactureSkipHandler = () => {
      if (
        parseBoolean(
          userStore.miscPreferences?.[MiscPreferencesKeys.INTERCHANGE_PARTTYPES]
        )
      ) {
        if (
          filtredInterchangeOptions.length === selectedPartTypeOptions.length
        ) {
          searchStore.getInterchangeApiRequest(
            subtitle,
            [],
            [],
            userManufacturerTypes?.partsBinId,
            doNotAskPartType,
            doNotAskManufactureType
          )
          modal.remove()
          history.push('/searchResults')
        } else {
          searchStore.getInterchangeApiRequest(
            subtitle,
            selectedPartTypeOptions,
            [],
            userManufacturerTypes?.partsBinId,
            doNotAskPartType,
            doNotAskManufactureType
          )
          modal.remove()
          history.push('/searchResults')
        }
      } else {
        searchStore.getInterchangeApiRequest(
          subtitle,
          selectedPartTypeOptions,
          [],
          userManufacturerTypes?.partsBinId,
          doNotAskPartType,
          doNotAskManufactureType
        )
        modal.remove()
        history.push('/searchResults')
      }
    }
    const PartTypeDontAskThisAgainAction = () => {
      setDoNotAskPartType(!doNotAskPartType)
    }
    const manufactureDontAskThisAgainAction = () => {
      setDoNotAskManufactureType(!doNotAskManufactureType)
    }
    useEffect(() => {
      const partTypeMiscValue =
        userStore.miscPreferences?.[MiscPreferencesKeys.INTERCHANGE_PARTTYPES]
      const manufacturerMiscValue =
        userStore.miscPreferences?.[
          MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
        ]
      setDoNotAskPartType(parseBoolean(partTypeMiscValue))
      setDoNotAskManufactureType(parseBoolean(manufacturerMiscValue))
    }, [userStore.miscPreferences])

    return (
      <Modal
        size={size}
        centered
        show={modal.visible}
        style={{ zIndex: zIndex.modal }}
      >
        {modelTypeValue === 'PartType' && (
          <PartTypesComponent
            title={title}
            subtitle={subtitle}
            borderBottomHeader={borderBottomHeader}
            selectedPartTypeOptions={selectedPartTypeOptions}
            partTypeToggleSelectAll={partTypeToggleSelectAll}
            filtredInterchangeOptions={filtredInterchangeOptions}
            borderTopFooter={borderTopFooter}
            PartTypeDontAskThisAgainAction={PartTypeDontAskThisAgainAction}
            doNotAskPartType={doNotAskPartType}
            primaryButtonToTheLeft={primaryButtonToTheLeft}
            buttonsDirection={buttonsDirection}
            partTypeSkipHandler={partTypeSkipHandler}
            partTypeSelectionHandler={partTypeSelectionHandler}
            primaryButtonVariant={primaryButtonVariant}
            primaryButtonIcon={primaryButtonIcon}
            partTypeCheckboxHandler={partTypeCheckboxHandler}
            isSelectedAllPartTypeOptions={isSelectedAllPartTypeOptions}
          />
        )}
        {modelTypeValue === 'Manufacture' && (
          <ManufacturerComponent
            title={title}
            subtitle={subtitle}
            borderBottomHeader={borderBottomHeader}
            selectedManufactureOptions={selectedManufactureOptions}
            manufactureToggleSelectAll={manufactureToggleSelectAll}
            borderTopFooter={borderTopFooter}
            manufactureDontAskThisAgainAction={
              manufactureDontAskThisAgainAction
            }
            doNotAskManufactureType={doNotAskManufactureType}
            primaryButtonToTheLeft={primaryButtonToTheLeft}
            buttonsDirection={buttonsDirection}
            manufactureSkipHandler={manufactureSkipHandler}
            manufactureSelectionHandler={manufactureSelectionHandler}
            primaryButtonVariant={primaryButtonVariant}
            primaryButtonIcon={primaryButtonIcon}
            manufactureCheckboxHandler={manufactureCheckboxHandler}
            filteredBrands={filteredBrands}
            setModelTypeValue={setModelTypeValue}
            setSelectedPartTypeOptions={setSelectedPartTypeOptions}
            isSelectedAllManufacturerOptions={isSelectedAllManufacturerOptions}
            setIsSelectedAllPartTypeOptions={setIsSelectedAllPartTypeOptions}
            setUserManufacturerTypes={setUserManufacturerTypes}
            setIsSelectedAllManufacturerOptions={
              setIsSelectedAllManufacturerOptions
            }
          />
        )}
      </Modal>
    )
  }
)

PartTypeModal.defaultProps = {
  primaryActionText: undefined,
  secondaryActionText: undefined,
  primaryButtonVariant: undefined,
  dontAskThisAgainText: undefined,
  size: 'lg',
  borderBottomHeader: false,
  borderTopFooter: false,
  primaryButtonToTheLeft: false,
  primaryButtonIcon: undefined,
  onCancel: undefined,
  loading: false,
  buttonsDirection: 'row',
}
