import React, { ReactElement } from 'react'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

interface HyperlinkProps {
  action: (e: unknown) => void
  underlined?: boolean
  bold?: boolean
  color?: string
}

export const Hyperlink = (
  props: React.PropsWithChildren<HyperlinkProps>
): ReactElement => {
  const { action, underlined, bold, children, color } = props
  return (
    <ButtonWrapper
      onClick={action}
      underlined={underlined}
      bold={bold}
      color={color}
    >
      {children}
    </ButtonWrapper>
  )
}

Hyperlink.defaultProps = {
  underlined: true,
  bold: false,
}

interface ButtonWrapperProps {
  underlined?: boolean
  bold?: boolean
}

const ButtonWrapper = styled.span<ButtonWrapperProps>`
  width: fit-content;
  color: ${(p) => p.color ?? defaultTheme.colors.link};
  cursor: pointer;
  font-family: Roboto, serif;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  border-bottom: ${(p) =>
    p.underlined ? `1px solid ${p.color ?? defaultTheme.colors.link}` : 'none'};

  @media screen and (max-width: ${breakpoints.tablet}px) {
    align-self: flex-start;
    border-bottom: none;
  }

  @media print {
    display: none;
  }
`
