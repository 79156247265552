import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'

export class VehicleMakesStore {
  private _selectedMakes: Set<string> = new Set()
  private _selectedMakesBuffer: Set<string> = new Set() // Required to allow the user rollback/cancel
  private _isMakesFilterEnabled: boolean
  private _showMakesToggle: boolean
  private _isLoadingMakes: boolean

  constructor() {
    makeAutoObservable(this)
  }

  public setSelectedMakes(makes: Array<string>): void {
    this._selectedMakes = new Set(makes)
    this._selectedMakesBuffer = new Set(makes)
  }

  public getSelectedMakes(): Array<string> {
    return Array.from(this._selectedMakesBuffer)
  }

  public addMake(make: string): void {
    this._selectedMakesBuffer.add(make)
  }

  public deleteMake(make: string): void {
    this._selectedMakesBuffer.delete(make)
  }

  public isMakesFilterEnabled(): boolean {
    return this._isMakesFilterEnabled
  }

  public setMakesFilterEnabled(enabled: boolean): void {
    this._isMakesFilterEnabled = enabled
  }

  public showMakesToggle(): boolean {
    return this._showMakesToggle
  }

  public setShowMakesToggle(show: boolean): void {
    this._showMakesToggle = show
  }

  public isLoadingMakes(): boolean {
    return this._isLoadingMakes
  }

  public setIsLoadingMakes(loading: boolean): void {
    this._isLoadingMakes = loading
  }

  public persistBuffer(): void {
    this._selectedMakes = new Set(this._selectedMakesBuffer)
  }

  public clearBuffer(): void {
    this._selectedMakesBuffer = new Set(this._selectedMakes)
  }

  public unSelectAllMakes(): void {
    this._selectedMakesBuffer = new Set()
  }
}

const vehicleMakesStore = new VehicleMakesStore()

export const VehicleMakesStoreContext =
  createContext<VehicleMakesStore>(vehicleMakesStore)

export const useVehicleMakesStore = (): VehicleMakesStore => {
  return useContext(VehicleMakesStoreContext)
}
