import { SearchStore } from 'src/store/search/SearchStore'
import { PartsCatalog } from 'src/store/partsCatalog/PartsCatalog'

export const BreadCrumbsSelection = (
  searchStore: SearchStore,
  partsCatalog: PartsCatalog,
  searchByFunction: (number) => void
): void => {
  const { breadCrumbs, addBreadCrumb, cleanBreadCrumbs } = searchStore

  const categoryGroupsFiltered = []

  const selectedPartTypes = partsCatalog?.searchedPartTypes?.filter(
    (item) => item.isSelected
  )

  if (selectedPartTypes.length > 0) {
    selectedPartTypes.forEach((item) => categoryGroupsFiltered.push(item))
  }

  if (partsCatalog?.categoryGroups()?.length > 0) {
    partsCatalog?.categoryGroups()?.forEach((group) => {
      group
        .getChildren()
        .forEach((item) =>
          item.selected ? categoryGroupsFiltered.push(item) : null
        )
    })
  }

  const breadCrumText = handlePartsCatalogBreadcrumb(categoryGroupsFiltered)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
  const newBreadCrumb: any = {
    page: 'partsCatalog',
    breadcumb: breadCrumText,
    url: searchByFunction,
  }

  if (breadCrumbs?.length === 0) {
    addBreadCrumb(newBreadCrumb)
  } else {
    cleanBreadCrumbs()
    addBreadCrumb(newBreadCrumb)
  }
}

function handlePartsCatalogBreadcrumb(categoryGroupsFiltered) {
  const space = '\u00A0\u00A0'
  let breadCrumbText = ''
  if (categoryGroupsFiltered?.length < 3) {
    categoryGroupsFiltered.map((group, index) => {
      breadCrumbText += group.value

      if (index === categoryGroupsFiltered?.length - 1) {
        breadCrumbText += `${space}`
      } else {
        breadCrumbText += `${space}|${space}`
      }

      return group
    })

    return breadCrumbText
  }

  if (categoryGroupsFiltered?.length > 2) {
    categoryGroupsFiltered?.slice(0, 2).map((group, index) => {
      breadCrumbText += group.value
      if (index === categoryGroupsFiltered?.length - 1) {
        breadCrumbText += `${space}`
      } else {
        breadCrumbText += `${space}|${space}`
      }

      return group
    })

    const aditionalItems = `+${categoryGroupsFiltered.length - 2}  more`

    return breadCrumbText + aditionalItems
  }
  return breadCrumbText
}
