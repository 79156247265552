import { ReactElement } from 'react'
import { Button } from 'src/ui-components'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'
import { Translate } from 'src/i18n'

interface NoResultsI {
  HeaderText: string
  BodyText: string
  buttonText: string
  action: () => void
}

const NoResults = (props: NoResultsI): ReactElement => {
  const { HeaderText, BodyText, buttonText, action } = props
  return (
    <NoResultsWrapper>
      <SearchIconStyled />
      <NoFilterResultsText>{Translate(HeaderText)}</NoFilterResultsText>
      <AdjustFiltersText>{Translate(BodyText)}</AdjustFiltersText>
      <Button text={buttonText} onClick={action} />
    </NoResultsWrapper>
  )
}

export default NoResults

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  min-height: 500px;
  background-color: white;
  justify-content: center;
`
const SearchIconStyled = styled(SearchIcon)`
  color: #d3d2cf;
  font-size: 100px !important;
  margin-bottom: 40px;
`

const NoFilterResultsText = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 10px;
`

const AdjustFiltersText = styled.span`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 10px;
`
