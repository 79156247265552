import React from 'react'
import { render } from 'react-dom'

import './index.scss'
import App from './App'

import init from './aesInit'

import { Store } from './store/Store'
import { GoogleTagManager } from './config/analytics/GoogleTagManager'
import { IParsedGoShoppingParams } from './api/embedded/types/partsBasketSpec'
import { EmbeddedCommunicationsManager } from './api/embedded/EmbeddedCommunicationsManager'
import { IS_INTEGRATED_VERSION } from './config/ConfigManager'
import { initSentry } from './helpers/sentry'

const loadApp = () => {
  render(
    <React.StrictMode>
      <Store>
        <App />
      </Store>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

if (IS_INTEGRATED_VERSION) {
  init()
    .then((data: IParsedGoShoppingParams) => {
      return EmbeddedCommunicationsManager.init(data)
    })
    .then(() => {
      GoogleTagManager.init()
      initSentry()
      loadApp()
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
} else {
  try {
    GoogleTagManager.init()
    initSentry()
    loadApp()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    // eslint-disable-next-line no-alert
    alert(`Error ${JSON.stringify(e)}`)
  }
}
