import { useMediaQuery } from '@mui/material'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import ItemsIntervalDescription from './ItemsIntervalDescription'
import PageSizeSelector from './PageSizeSelector'
import { SingleOption } from 'src/features/orderHistory/components/SortSelect'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { I18n, Translate } from 'src/i18n'

interface PaginationProps {
  totalItems: number
  itemsPerPageScale: number
  onPageChange: (start: number, limit: number) => void
  currentPageSize: number
  paginationTitle?: string
  pageOptions?: SingleOption[]
  start?: number
}

interface ButtonWrapperProps {
  active: boolean
}

const PageDistances = { 5: 2, 3: 1 } // if pages 5, distances 2

const Pager = (props: PaginationProps): ReactElement => {
  const {
    totalItems,
    itemsPerPageScale,
    onPageChange,
    currentPageSize,
    paginationTitle = '',
    pageOptions = [],
    start = 1,
  } = props

  const [itemsPerPage, setItemsPerPage] = useState<number>(currentPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const isTabletSize = useMediaQuery(`(max-width: ${breakpoints.tablet}px)`)
  const paginationCap = 50
  const showPageSizeSelector = useMemo(() => totalItems > 10, [totalItems])

  const numPagesToDisplay = useMemo(
    () => (isTabletSize ? 3 : 5),
    [isTabletSize]
  ) // Only display 3 pages on screens < tablet screen, otherwise display 5
  const distance = useMemo(
    () => PageDistances[numPagesToDisplay],
    [numPagesToDisplay]
  )

  useEffect(() => {
    if (start) setCurrentPage(start)
  }, [start, totalItems])

  useEffect(() => {
    setItemsPerPage(currentPageSize)
  }, [currentPageSize])

  const pageSizeOptions = useMemo(() => {
    if (pageOptions.length > 0) return pageOptions

    const options = []
    const cap = Math.min(paginationCap, totalItems)
    for (let i = 1; i * itemsPerPageScale <= cap; i++) {
      const multiple = (i * itemsPerPageScale).toString()
      options.push({ label: multiple, value: multiple })
    }

    return options
  }, [pageOptions, totalItems, itemsPerPageScale])

  const finalPage = useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [itemsPerPage, totalItems]
  )

  const getStartPage = (numPagesToDisplay: number, limit: number): number => {
    const pageOnRightEdge = currentPage >= limit - distance
    if (pageOnRightEdge) return limit - numPagesToDisplay + 1

    return currentPage - distance
  }

  const calculatePagesToDisplay = (): number[] => {
    const endPage = Math.min(currentPage + numPagesToDisplay, finalPage)
    const startPage = getStartPage(numPagesToDisplay, endPage)
    const allPossiblePages = [...Array(endPage + 1).keys()].filter((p) => p > 0)

    const pages = allPossiblePages
      .filter((p) => p >= startPage)
      .slice(0, numPagesToDisplay)

    return pages
  }

  const pages = calculatePagesToDisplay()

  const handlePageChange = (page: number, newPageSize?: number) => {
    if (page <= 0 || page > finalPage) return

    setCurrentPage(page)
    const pageSize = newPageSize ?? itemsPerPage
    onPageChange(page, pageSize)
  }

  const handlePageSizeChange = (newSize: number) => {
    setItemsPerPage(Number(newSize))
    handlePageChange(1, newSize) // Go back to page 1 with the new page size
  }

  const showFirstPageButton = useMemo(
    () => currentPage > (isTabletSize ? 2 : 3) && finalPage > 5,
    [currentPage, finalPage, isTabletSize]
  )

  const showLastPageButton = useMemo(
    () => currentPage < finalPage - distance && finalPage > 5,
    [currentPage, distance, finalPage]
  )

  const FirstPageButton = () => (
    <>
      <ButtonWrapper
        key={1}
        onClick={() => handlePageChange(1)}
        active={1 === currentPage}
      >
        1
      </ButtonWrapper>
      <Ellipsis>...</Ellipsis>
    </>
  )

  const LastPageButton = () => (
    <>
      <Ellipsis>...</Ellipsis>
      <ButtonWrapper
        key={finalPage}
        onClick={() => handlePageChange(finalPage)}
        active={finalPage === currentPage}
      >
        {finalPage}
      </ButtonWrapper>
    </>
  )

  return (
    <Wrapper>
      <ItemsIntervalDescription
        title={paginationTitle || ''}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        page={currentPage}
      />
      <Flex>
        {showPageSizeSelector && (
          <PageSizeSelector
            itemsPerPage={itemsPerPage}
            pageOptions={pageSizeOptions}
            onChange={handlePageSizeChange}
          />
        )}
        <ResponsivePagination>
          {showPageSizeSelector && <Divider />}

          <ButtonWrapper
            active={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            title={Translate('goToPreviousPage')}
          >
            <ChevronLeft />
          </ButtonWrapper>
          {!isTabletSize && (
            <span style={{ textTransform: 'capitalize', marginLeft: 10 }}>
              <I18n name="page" />:
            </span>
          )}
          {showFirstPageButton && <FirstPageButton />}
          {pages?.map((page) => (
            <ButtonWrapper
              key={page}
              onClick={() => handlePageChange(page)}
              active={currentPage === page}
            >
              {page}
            </ButtonWrapper>
          ))}
          {showLastPageButton && <LastPageButton />}
          <ButtonWrapper
            active={currentPage === finalPage}
            onClick={() => handlePageChange(currentPage + 1)}
            title={Translate('goToNextPage')}
          >
            <ChevronRight />
          </ButtonWrapper>
        </ResponsivePagination>
      </Flex>
    </Wrapper>
  )
}

Pager.defaultProps = {
  paginationTitle: 'orders',
  filterByPage: false,
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`

const ResponsivePagination = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-left: 0;
    margin-top: 20px;
  }
`

const Divider = styled.div`
  width: 1px;
  height: 16px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
  background: #e5e4e1;
`

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  width: 29px;
  height: 32px;
  border: 1px solid ${(p) => (p.active ? ' #D3D2CF' : 'black')};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: white;
  color: ${(p) => (p.active ? ' #D3D2CF' : 'black')};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
`

const Ellipsis = styled.span`
  margin-left: 10px;
`

export default Pager
