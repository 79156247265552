import { chunk, flatten } from 'lodash-es'
import { ValidateListPart, ValidatePartsRequest } from './interfaces'
import ListServiceProvider from 'src/services/ListServiceProvider'
import { SELL_NETWORK_BATCH_SIZE } from 'src/config/constants'

export const getValidatedPartsList = async ({
  partIds,
}: ValidatePartsRequest): Promise<ValidateListPart[]> => {
  // sending chunks of partIds for validation, as validate end point handles not more than 30 items per request
  const chunks = chunk(partIds, SELL_NETWORK_BATCH_SIZE)
  return Promise.all(
    chunks.map((partsChunk) =>
      ListServiceProvider.validateParts({
        partIds: partsChunk,
      })
    )
  ).then((updatedPartChunks) =>
    flatten<ValidateListPart>(
      updatedPartChunks.map((partChunk) => partChunk.parts || [])
    )
  )
}
