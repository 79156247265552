import { observer } from 'mobx-react-lite'
import {
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Spinner } from 'react-bootstrap'
import { Route, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Config, IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { useUserStore } from '../../store/user/UserStore'
import { useScrollToTop } from '../useScrollToTop'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { StorageKeys } from 'src/config/constants'
// These pages are so small that including them in the main bundle is better than lazy loading them.
import { useSearchStore } from 'src/store/search/SearchStore'
import { EmbeddedCommunicationsManager } from '../../api/embedded/EmbeddedCommunicationsManager'
import PublicRoutes from './PublicRoutes'
import AuthenticatedRoutes from './AuthenticatedRoutes'

export const Routes = observer((): ReactElement => {
  const userStore = useUserStore()
  const cartStore = useShoppingCart()
  const searchStore = useSearchStore()
  const location = useLocation()
  useScrollToTop()

  const appHasLoaded = useMemo(
    () =>
      Config.isNotCartOnlyMode
        ? userStore.userHasLoaded
        : cartStore.checkAvailabilityLoaded && userStore.userHasLoaded,
    [cartStore.checkAvailabilityLoaded, userStore.userHasLoaded]
  )

  // TODO: Look into replacing useCallback with useMemo.
  const getLandingPage = useCallback((): string => {
    if (IS_INTEGRATED_VERSION) {
      // The order of the conditions matter here.
      if (!Config.isNotCartOnlyMode) {
        return '/cart'
      }
      if (EmbeddedCommunicationsManager.isAstPunchOut) {
        return '/astPartsCatalog'
      }

      if (searchStore.currentVehicle && searchStore.currentVehicle.engine?.id) {
        return '/partsCatalog'
      }
    }
    return '/home'
  }, [searchStore.currentVehicle])

  const landingPage: string = useMemo(
    () => (appHasLoaded ? getLandingPage() : '/home'),
    [appHasLoaded, getLandingPage]
  )

  const [urlToRedirect, setUrlToRedirect] = useState('')

  const isUserImpersonated = localStorage.getItem(
    StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY
  )

  const history = useHistory()
  useEffect(() => {
    if (
      appHasLoaded &&
      (userStore.preferences?.EULA_accepted || '').toLowerCase() !== 'true' &&
      isUserImpersonated !== 'true'
    ) {
      history.push('/terms-and-conditions')
    }
  }, [
    appHasLoaded,
    isUserImpersonated,
    history,
    userStore.preferences?.EULA_accepted,
  ])

  useEffect(() => {
    if (location.pathname !== '/login') {
      setUrlToRedirect(`${location.pathname}${location.search}`)
    }
  }, [location])

  return (
    <Suspense
      fallback={
        <Wrapper>
          <Spinner animation="border" />
        </Wrapper>
      }
    >
      <Route>
        <PublicRoutes
          urlToRedirect={urlToRedirect}
          setUrlToRedirect={setUrlToRedirect}
          redirectToLogin={userStore?.redirectToLogin}
        />
        <AuthenticatedRoutes
          landingPage={landingPage}
          appHasLoaded={appHasLoaded}
          redirectToLogin={userStore?.redirectToLogin}
        />
      </Route>
    </Suspense>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`
