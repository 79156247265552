import { LineType, PartsBasketAction } from './types/partsBasketSpec'
import { OrderPart } from '../cart/interfaces'
import { IPartsBasket12 } from './types/partsBasket-1.2'
import { IPartsBasketV10 } from './types/partsBasket-1.0'
import {
  IPartsBasket11,
  IPartsBasketLine11,
  IPartsBasketSearchInfo,
  IPartsBasketVehicleInfo,
} from './types/partsBasket-1.1'
import { EmbeddedCommunicationsManager } from './EmbeddedCommunicationsManager'
import { LaborItem } from 'src/store/models/LaborModel'
import { VehicleSpecificationItem } from 'src/store/models/VehicleSpecification'
import { getLaborRateFromCurrencyString } from 'src/helpers/utils'

export class PartsBasketConverter {
  public static isPartsBasket10(
    _partsBasket: IPartsBasketV10 | IPartsBasket11 | IPartsBasket12
  ): _partsBasket is IPartsBasketV10 {
    return EmbeddedCommunicationsManager.getPartsBasketVersion() === '1.0'
  }

  public static getPartsBasketInfo(
    partsBasket: IPartsBasketV10 | IPartsBasket11 | IPartsBasket12
  ): {
    searchInfo?: IPartsBasketSearchInfo
    vehicleInfo?: IPartsBasketVehicleInfo
  } {
    if (PartsBasketConverter.isPartsBasket10(partsBasket)) {
      const { searchQualifier } = partsBasket
      return {
        searchInfo: {
          catalogId: searchQualifier?.catalogId,
          indexId: searchQualifier?.indexId,
          searchTxt: searchQualifier?.searchTxt,
        },
        vehicleInfo: searchQualifier?.vehicleQualifier,
      }
    } else {
      return {
        searchInfo: partsBasket.searchInfo,
        vehicleInfo: partsBasket.vehicleInfo,
      }
    }
  }

  public static setAction = (
    partsBasket: IPartsBasket11 | IPartsBasket12,
    action: PartsBasketAction
  ): IPartsBasket11 | IPartsBasket12 => {
    return { ...partsBasket, action }
  }

  public static orderRespItemToPBItem = (
    part: OrderPart
  ): IPartsBasketLine11 => {
    const line: IPartsBasketLine11 = {
      ...part,
      seqNo: 1,
      locationId: String(part.locationId),
      manufacturerCode: part.lineCode,
      priceOverride: part.isPriceOverride,
      quantityAvailable: part.quantityShipped,
      status: part.status || '',
    }
    return line
  }

  public static getLaborPartFromLaborItem(
    labor: LaborItem
  ): IPartsBasketLine11 {
    const laborRate = Number(getLaborRateFromCurrencyString(labor.laborRate))
    return {
      description: `${labor.laborDescription}${
        labor.miscellaneousText ? `\n${labor.miscellaneousText}` : ''
      }`,
      unitListPrice: laborRate,
      unitCostPrice: laborRate,
      quantityRequested: Number(labor.hours),
      brand: 'Labor',
      partNumber: labor.laborId.toString(),
      quantityAvailable: Number(labor.hours),
      seqNo: 1,
      type: LineType.labor,
      partCategory: labor.skillLevel,
      skillLevel: labor.skillLevel,
      warrantyHrs: Number(labor.warrantyHrs),
      hrlyCostRate: laborRate,
      hrlyListRate: laborRate,
      manufacturerCode: '',
      priceOverride: false,
      priceOverrideMsg: '',
      unitCorePrice: 0,
      unitListCore: 0,
      locationId: '',
      locationDescription: '',
      minOrderQty: 0,
      incrementalOrderQty: 0,
      unitOfMeasure: '',
      locationStatus: '',
      status: 'fnd',
      laborCode: '',
      quantityShipped: 0,
      mitchellHrs: 0,
      value: '',
    }
  }

  public static get10LaborPartFromLaborItem(
    labor: LaborItem
  ): IPartsBasketLine11 {
    const laborRate = Number(getLaborRateFromCurrencyString(labor.laborRate))
    return {
      description: `${labor.laborDescription}${
        labor.miscellaneousText ? `\n${labor.miscellaneousText}` : ''
      }`,
      unitListPrice: laborRate,
      unitCostPrice: laborRate,
      quantityRequested: Number(labor.hours),
      quantityAvailable: Number(labor.hours),
      // Noticed that the actual response if a string.
      // quantityRequested: labor.hours,
      // quantityAvailable: labor.hours,
      brand: 'Labor',
      partNumber: labor.laborId.toString(),
      partCategory: labor.skillLevel,
      status: '1',
      // Noticed that the actual response if a number.
      // status: 1,
    }
  }

  public static getSpecificationfromSpecificationItem(
    specification: VehicleSpecificationItem
  ): IPartsBasketLine11 {
    // Copied from AES servlet source.
    let extDesc = ''

    if (specification && specification.extendedDescription) {
      extDesc += specification.extendedDescription
    }
    let partNum = ''
    if (specification && specification.partNumber) {
      partNum += specification.partNumber
    }
    let nYears = ''
    if (specification && specification.years) {
      nYears += specification.years
    }

    let rowdata = ''

    rowdata += extDesc + '  ' + partNum + '  Years: ' + nYears

    if (
      specification &&
      specification.miscellaneousTexts &&
      specification.miscellaneousTexts.length > 0
    ) {
      for (let x = 0; x < specification.miscellaneousTexts.length; x++) {
        rowdata += '  ' + specification.miscellaneousTexts[x]
      }
    }

    return {
      description: rowdata,
      partNumber: specification.partNumber,
      manufacturerCode: '',
      unitCorePrice: 0,
      unitCostPrice: 0,
      unitListPrice: 0,
      unitListCore: 0,
      quantityAvailable: 0,
      quantityRequested: 0,
      locationId: '',
      locationDescription: '',
      seqNo: 1,
      lineNo: 2,
      type: LineType.specification,
      partCategory: '',
      brand: '',
      minOrderQty: 0,
      incrementalOrderQty: 0,
      unitOfMeasure: '',
      priceOverride: false,
      priceOverrideMsg: '',
      skillLevel: '',
      warrantyHrs: 0,
      hrlyCostRate: 0,
      hrlyListRate: 0,
      locationStatus: '',
      status: 'fnd',
      laborCode: '',
      quantityShipped: 0,
      mitchellHrs: 0,
      value: '',
    }
  }
}
