import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import { StoreInstances } from '../StoreInstancesContainer'
import { LaborItem } from '../models/LaborModel'
import { PartsCatalog } from '../partsCatalog/PartsCatalog'
import { LaborCatalogAPI } from '../partsCatalog/api/LaborCatalogAPI'

export const LaborLookupContext = createContext<LaborStore>(undefined)

export const useLaborLookup = (): LaborStore => useContext(LaborLookupContext)

export class LaborStore {
  laborRate: string

  laborResultLoading = false

  results: LaborItem[] = []

  lookup: PartsCatalog

  constructor() {
    makeAutoObservable(this)
    this.lookup = new PartsCatalog(new LaborCatalogAPI())
  }

  fetchLaborResult = async (): Promise<void> => {
    this.laborResultLoading = true
    this.results = []
    const { currentVehicle } = StoreInstances.searchStore
    const formattedVehicle = {
      ...currentVehicle,
      initialized: undefined,
    }
    const body = {
      vehicle: formattedVehicle,
      cgts: this.lookup.getCtgs(),
    }
    this.results = await CatalogServiceProvider.getLaborSearchResults(body)
    this.laborResultLoading = false
  }

  public resetLaborResult = (): void => {
    this.results = []
  }

  setLaborRate = (laborRate: string): void => {
    this.laborRate = laborRate
  }

  public calculateLaborCost = (labor: LaborItem): number => {
    return labor.rate * Number(labor.hours)
  }

  public calculateLaborCostFromItems = (laborItems: LaborItem[]): number => {
    if (!laborItems) return 0

    return laborItems.reduce(
      (partialCost, labor) => partialCost + this.calculateLaborCost(labor),
      0
    )
  }
}
