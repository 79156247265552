import { VehicleSuggestionResponse } from 'src/api/vehicle/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'

class VehicleSaytServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'sayt', '1.0.1', '')
  }

  getVehicleSuggestion = async (
    query: string
  ): Promise<VehicleSuggestionResponse> => {
    try {
      const queryParams = {
        count: 10,
        regionId: 1,
        catId: 111,
        query,
      }
      const resp = await this.post<VehicleSuggestionResponse>(
        'suggestionsV2',
        {},
        { params: { ...queryParams } }
      )
      if (resp.status === 200) {
        return resp.data
      }
    } catch (error) {
      throw new Error(error.toString())
    }
    return null
  }
}

export default new VehicleSaytServiceProvider()
