import { ReactElement, useEffect, useState } from 'react'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import CustomMakesDrawer from './CustomMakesDrawer'
import { show as showNiceModal } from '@ebay/nice-modal-react'
import { observer } from 'mobx-react-lite'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import { I18n } from 'src/i18n'
import { Settings } from '@mui/icons-material'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import { useVehicleMakesStore } from '../../store/VehicleMakesStore'
import CustomMakesSwitch from './CustomMakesSwitch'
import { Box, LinearProgress } from '@mui/material'

enum SetupOptions {
  NONE,
  CUSTOMIZE,
  EDIT,
}

const CustomizeMakesControls = observer((): ReactElement => {
  const makesStore = useVehicleMakesStore()
  const isMakesFilterEnabled = makesStore.isMakesFilterEnabled()
  const showMakesToggle = makesStore.showMakesToggle()
  const isLoadingMakes = makesStore.isLoadingMakes()
  const [showOption, setShowOption] = useState(SetupOptions.NONE)

  useEffect(() => {
    if (!isLoadingMakes) {
      const option = isMakesFilterEnabled
        ? SetupOptions.EDIT
        : SetupOptions.CUSTOMIZE
      setShowOption(option)
    }
  }, [isMakesFilterEnabled, isLoadingMakes])

  const handleCustomizeMakes = async (e): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()
    const options = await VehicleServiceProvider.getAllMakeOptions()
    showNiceModal(CustomMakesDrawer, { options })
  }

  return (
    <>
      {isLoadingMakes && (
        <Box>
          <LinearProgress />
        </Box>
      )}
      <CustomizeMakesWrapper isSingleItem={!showMakesToggle}>
        {showMakesToggle && <CustomMakesSwitch />}
        <CustomMakesLink>
          {showOption == SetupOptions.CUSTOMIZE && (
            <Hyperlink action={handleCustomizeMakes} underlined={false}>
              <Settings style={{ paddingRight: '0.2rem' }} />
              <I18n name={'customize'} />
            </Hyperlink>
          )}
          {showOption == SetupOptions.EDIT && (
            <Hyperlink action={handleCustomizeMakes} underlined={false}>
              <I18n name={'edit'} />
            </Hyperlink>
          )}
        </CustomMakesLink>
      </CustomizeMakesWrapper>
    </>
  )
})

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: ${(p) => p.theme.colors.text};
  border: ${(p) => p.theme.fieldBorder};
  max-height: 350px;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    max-height: 225px;
    overflow: auto;
  }
`

interface CustomizeMakesWrapperProps {
  isSingleItem: boolean
}

const CustomizeMakesWrapper = styled(InnerWrapper)<CustomizeMakesWrapperProps>`
  justify-content: ${(p) => (p.isSingleItem ? 'end' : 'space-between')};
  align-items: center;
  padding: 8px;
  border-top: none;
  width: 100%;
  margin-top: 0px;
  font-size: 16px;
`

const CustomMakesLink = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

export default CustomizeMakesControls
