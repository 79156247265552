import { CheckOutlined } from '@mui/icons-material'
import React, { ReactElement, useEffect, useState } from 'react'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

export interface ICheckboxClickHandler {
  value: string | number
  label: string
  isChecked: boolean
  groupId?: string | number
}

interface CheckboxModel {
  value: string | number
  label: string
  onClick: (data: ICheckboxClickHandler) => void
  active?: boolean
  isCheckMixed?: boolean
  isTitle?: boolean
  groupId?: string | number
  labelStyle?: React.CSSProperties
  checkOutlinedIconStyle?: React.CSSProperties
  disabled?: boolean
}

export const Checkbox = (props: CheckboxModel): ReactElement => {
  const {
    value,
    label,
    onClick,
    active,
    isTitle,
    isCheckMixed,
    groupId,
    labelStyle,
    checkOutlinedIconStyle,
    disabled = false,
  } = props
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const handleClick = () => {
    if (disabled) {
      return
    }
    if (isCheckMixed) {
      setIsChecked(false)
      onClick({ value, label, isChecked: false, groupId })
      return
    }
    // Trigger internal state only if the component is uncontrolled.
    if (typeof active === 'undefined') {
      setIsChecked(!isChecked)
    }

    onClick({ value, label, isChecked: !isChecked, groupId })
  }

  useEffect(() => {
    setIsChecked(active || false)
  }, [active])

  return (
    <Wrapper
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="checkbox-wrapper"
    >
      <CheckboxWrapper
        hover={isHovering}
        checked={isChecked || isCheckMixed || false}
        disabled={disabled}
      >
        {isChecked && !isCheckMixed ? (
          <CheckOutlined
            style={{
              fontSize: 15,
              stroke: 'black',
              strokeWidth: '2',
              ...checkOutlinedIconStyle,
            }}
          />
        ) : null}
        {isTitle && isCheckMixed ? (
          <div style={{ width: '70%', background: '#000', height: 3 }} />
        ) : null}
      </CheckboxWrapper>
      <Label isTitle={isTitle || false} style={labelStyle}>
        <span>{label}</span>
      </Label>
    </Wrapper>
  )
}

Checkbox.defaultProps = {
  active: false,
  isTitle: false,
  isCheckMixed: false,
  groupId: undefined,
  labelStyle: undefined,
  checkOutlinedIconStyle: undefined,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: auto;
  }
`
interface LabelProps {
  isTitle: boolean
}
const Label = styled.div<LabelProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props) => (props.isTitle ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  color: #050505;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
`
interface CheckWrapperProps {
  checked: boolean
  hover: boolean
  disabled: boolean
}
const CheckboxWrapper = styled.div<CheckWrapperProps>`
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${(props) =>
      props.checked
        ? defaultTheme.colors.primary
        : props.hover
          ? props.disabled
            ? '#E5E4E1'
            : 'rgb(5,5,5)'
          : '#adadaa'};
  box-sizing: border-box;
  border-radius: 3px;
  background: ${(props) => {
    if (props.disabled) {
      return 'rgb(229,228,225)'
    }
    if (props.checked) {
      return defaultTheme.colors.primary
    }
    return '#fff'
  }};
`
