import { SpecificConditionI } from 'src/store/models/SpecificConditions'

/* We don't really need to hash anything here */
const buildSpecCondHash = (q: SpecificConditionI): string => {
  return (
    q.id +
    q.choices
      .map((choice) => choice.id)
      .sort()
      .join()
  )
}

export const hash = (question: SpecificConditionI): SpecificConditionI => {
  return { ...question, hash: buildSpecCondHash(question) }
}
