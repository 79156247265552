import { Close } from '@mui/icons-material'
import { Box, Modal } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { Button, TextInput } from 'src/ui-components'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'

interface SimpleGroupAddEditModalProps {
  showGroupAddEditModal: boolean
  handleGroupAddEditModal: () => void
  addGroup?: (labelValue: string) => void
  editGroup?: (labelValue: string) => void
  modalName: string
  groupName: string
}

export const SimpleGroupAddEditModal = (
  props: SimpleGroupAddEditModalProps
): ReactElement => {
  const {
    showGroupAddEditModal,
    handleGroupAddEditModal,
    modalName,
    groupName,
    addGroup,
    editGroup,
  } = props

  const isTablet = window.innerWidth <= breakpoints.tablet

  const modalStyle = {
    position: 'absolute',
    top: `${isTablet ? 'auto' : '50%'}`,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    outline: 'none',
    width: `${isTablet ? '100%' : '450px'}`,
    bottom: `${isTablet ? '-18%' : 'auto'}`,
    padding: '25px',
  }
  const [groupNameValue, setGroupNameValue] = useState('')

  useEffect(() => {
    setGroupNameValue(groupName)
  }, [groupName, setGroupNameValue])

  return (
    <Modal open={showGroupAddEditModal} onClose={handleGroupAddEditModal}>
      <Box sx={modalStyle}>
        <HeaderContainer>
          <StyledText>
            {`${Translate(modalName)} ${Translate('group')}`}
          </StyledText>
          <Close
            style={{ cursor: 'pointer' }}
            onClick={handleGroupAddEditModal}
          />
        </HeaderContainer>

        <StyledText normal style={{ marginTop: '10px' }}>
          <I18n name="name" />
        </StyledText>
        <TextInput
          value={groupNameValue}
          onChange={(str) => setGroupNameValue(str)}
          noMargin
          width="100%"
        />

        <ButtonsWrapper>
          <CancelButton
            onClick={handleGroupAddEditModal}
            variant="secondary"
            size="medium"
            text={Translate('cancel')}
          />

          <SaveChangesButton
            onClick={() => {
              if (modalName.includes('new')) {
                addGroup(groupNameValue)
              } else {
                editGroup(groupNameValue)
              }
              setGroupNameValue('')
            }}
            variant="primary"
            size="medium"
            text={
              modalName.includes('new')
                ? Translate('createGroup')
                : Translate('saveChanges')
            }
            disabled={groupNameValue.length === 0}
          />
        </ButtonsWrapper>
      </Box>
    </Modal>
  )
}

SimpleGroupAddEditModal.defaultProps = {
  addGroup: null,
  editGroup: null,
}
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`

const CancelButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const SaveChangesButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`

interface StyledTextProps {
  lightText?: boolean
  normal?: boolean
}
const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => (p.normal ? '13px' : '22px')};
  font-family: ${(p) => (p.normal ? 'Roboto, serif' : 'Oswald, serif')};
  color: ${(p) => (p.lightText ? 'gray' : 'black')};
  font-weight: ${(p) => (p.normal ? '500' : 'bold')};
  display: block;
  text-align: left;
`
