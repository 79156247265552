import axios from 'axios'
import { UserCredentials } from 'src/api/user/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'

class UserInfoServiceOpProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'userinfo', '1.0', '')
  }

  getCredentialsfromtoken = async (token: string): Promise<UserCredentials> => {
    // Using axios as this API doesn't require authentication.
    const { data } = await axios.get<UserCredentials>('generateV3Credentials', {
      params: { token },
      baseURL: this.getConstructedUrl(),
    })
    return data
  }
}

export default new UserInfoServiceOpProvider()
