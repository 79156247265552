import { observer } from 'mobx-react-lite'
import { FC, Suspense, ComponentProps, useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import HeaderPerAppMode from 'src/features/navigation/header/HeaderPerAppMode'
import { useUserStore } from '../../store/user/UserStore'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import Footer from 'src/routes/Footer'
import { StorageKeys } from 'src/config/constants'

export const RouteRequiresLogin: FC<ComponentProps<typeof Route>> = observer(
  ({ children, ...props }) => {
    const userStore = useUserStore()
    const history = useHistory()
    if (userStore.redirectToLogin) {
      return <Redirect to="/login" />
    }

    const isUserImpersonated = localStorage.getItem(
      StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY
    )

    useEffect(() => {
      if (
        (userStore.preferences?.EULA_accepted || '').toLowerCase() !== 'true' &&
        isUserImpersonated !== 'true'
      ) {
        history.push('/terms-and-conditions')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Route {...props}>
        <HeaderPerAppMode />
        <Suspense
          fallback={
            <Wrapper>
              <Spinner animation="border" />
            </Wrapper>
          }
        >
          {children}
        </Suspense>
        <Footer />
      </Route>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
`
