import {
  GENERAL_RETRIES_INTERVAL,
  GENERAL_RETRIES_LIMIT,
} from 'src/config/constants'

const interval = Math.max(Number(GENERAL_RETRIES_INTERVAL), 1)
const limit = Math.max(Number(GENERAL_RETRIES_LIMIT), 1)

interface Monitor<T> {
  target: () => Promise<T>
  passCheck: (result: T) => boolean
  counter?: number
  retryInterval?: number
  retryLimit?: number
}

export const monitorUpdates = async <T>({
  target,
  passCheck,
  counter = 0,
  retryInterval = interval,
  retryLimit = limit / interval, // How many retries can be made in the given time period
}: Monitor<T>): Promise<T | null> => {
  await delay(retryInterval)

  if (++counter > retryLimit) return null

  const result = await target()
  if (passCheck(result)) return result

  return monitorUpdates({
    target,
    passCheck,
    counter,
    retryInterval,
    retryLimit,
  })
}

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms))
