import ArrowBack from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReactElement } from 'react'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import styled from 'styled-components'

const DeleteCategoryConfirmation = observer((): ReactElement => {
  const {
    deleteCategoryModal,
    setDeleteCategoryModal,
    deleteSelectedCategory,
    onDeleteCategory,
  } = useListsStore()

  const show = deleteCategoryModal
  const ref = React.useRef<HTMLInputElement>()

  const onBack = () => {
    setDeleteCategoryModal(false)
  }

  const handleDeleteConfirmed = () => {
    onDeleteCategory(deleteSelectedCategory.id)
  }
  useClickOutsideDetector([ref], show, () => setDeleteCategoryModal(false))

  return (
    <ResponsiveDrawer
      anchor="bottom"
      open={show}
      onClose={() => setDeleteCategoryModal(false)}
      PaperProps={{
        square: false,
      }}
      style={{
        width: '800px',
      }}
    >
      <Container ref={ref}>
        <HeaderContainer>
          <StyledBackIcon onClick={onBack} />
          <TitleHeader>
            <I18n name="deleteCategory" />
            <StyledCloseIcon onClick={() => setDeleteCategoryModal(false)} />
          </TitleHeader>
        </HeaderContainer>
        <ConfirmationTextContainer>
          <ConfirmationText>
            <I18n name="areYouSureYouWantToDelete" />{' '}
            <CategoryName>{deleteSelectedCategory?.label}</CategoryName>
            ? <I18n name="thisCannotBeUndone" />
          </ConfirmationText>
        </ConfirmationTextContainer>

        <ButtonContainer>
          <Button onClick={handleDeleteConfirmed}>
            <ButtonContent>
              <StyledDeleteIcon />
              <DeleteText>
                <I18n name="deleteCategory" />
              </DeleteText>
            </ButtonContent>
          </Button>
          <ButtonCancel onClick={onBack}>
            <I18n name="cancel" />
          </ButtonCancel>
        </ButtonContainer>
      </Container>
    </ResponsiveDrawer>
  )
})

export default DeleteCategoryConfirmation

const ConfirmationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0 0px 0px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 32px;
    p {
      margin: 0px;
    }
  }
`

const ConfirmationText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`

const CategoryName = styled.span`
  font-weight: bolder;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  padding: 24px;
  margin: 0px 0px 36px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin-top: 47px;
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 12px 24px;
  gap: 9px;
  width: 100%;
  height: 48px;
  background: ${defaultTheme.colors.red};
  border-radius: 3px;
  border: none;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    height: 36px;
    width: 150px;
    flex-direction: row;
    gap: 0px;
    padding: 0px;
    align-items: center;
    margin: 0px;
  }
`

const DeleteText = styled.span`
  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: relative;
    top: 1px;
  }
`

const ButtonContent = styled.h6`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-decoration: uppercase;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
  }
`

const ButtonCancel = styled(Button)`
  background-color: white;
  border-radius: 3px;
  border: 1px solid black;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
    width: 91px;
    height: 36px;
  }
`

const ResponsiveDrawer = styled(Drawer)``

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`
const StyledBackIcon = styled(ArrowBack)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 27px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  margin-bottom: 3px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    height: 22px;
    width: 22px;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};
  margin-left: 30px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin-left: 0px;
    font-size: 24px;
  }
`
interface ContainerProps {
  height?: string
  width?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`
const HeaderContainer = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 0px;
  }
`
