import axios from 'axios'
import { getKeyTypePair } from 'src/graphql/queries'
import { getAwsConfig } from 'src/aws-exports'
import { UserAttributes, UserAttributesResp } from 'src/store/user/interfaces'
import { updateCache } from 'src/graphql/mutations'

const awsConfig = getAwsConfig()

export const getUserAttributes = (
  userId: number,
  token: string
): Promise<UserAttributes> => {
  const graphQL = {
    query: getKeyTypePair,
    variables: { type: 'get', key: `user:${userId}` },
  }
  return axios
    .post<UserAttributesResp>(awsConfig.aws_appsync_graphqlEndpoint, graphQL, {
      headers: {
        Authorization: token,
      },
    })
    .then((resp) => {
      return JSON.parse(resp.data.data.get.result) as UserAttributes
    })
}
/**
 * @deprecated We should not directly mutate the graphql cache. We should call the `saveOrUpdateAttribute` endpoint on the userattributes service.
 * @param attributes
 * @param userId
 * @param token
 * @returns
 */
export const updateUserAttributes = (
  attributes: UserAttributes,
  userId: number,
  token: string
): Promise<void> => {
  const query = {
    query: updateCache,
    variables: {
      type: 'update',
      key: `user:${userId}`,
      data: JSON.stringify(attributes),
    },
  }
  return (
    axios
      .post<unknown>(awsConfig.aws_appsync_graphqlEndpoint, query, {
        headers: {
          Authorization: token,
        },
      })
      // Keep the types compatible.
      .then(() => {
        return undefined
      })
  )
}
