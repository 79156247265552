import { createBrowserHistory, createMemoryHistory } from 'history'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'

/**
 * AES is served in a single page and the url never changes.
 * COSTAR is injecting a script into the page whenever the page loading state changes.
 * So multiple copies of a script are being injected into the dom and multple event listeners are created as the user navigates the page in V3 as it is a SPA.
 * Using memory history fixes this as the url and the page state never changes.
 */
export default IS_INTEGRATED_VERSION
  ? createMemoryHistory()
  : createBrowserHistory()
