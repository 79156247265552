import { makeAutoObservable } from 'mobx'

export default class Node {
  public type?: string

  public id: number

  public value: string

  private items: Array<Node>

  private _parent?: Node

  private _open: boolean

  constructor(id: number, value: string, type?: string) {
    this.items = [] as Array<Node>
    this.id = id
    this.value = value
    this.type = type
    this._open = false
    makeAutoObservable(this)
  }

  public addChild(child: Node): void {
    child._parent = this
    this.items.push(child)
  }

  public deleteAllChildren(): void {
    this.items = []
  }

  public getChildren(): Array<Node> {
    return this.items
  }

  public findChildren(id: number, name: string): Node | undefined {
    return this.items.find((child) => child.id === id && child.value === name)
  }

  getFirstChild(): Node {
    return this.items[0]
  }

  public getParent(): Node {
    return this._parent
  }

  public getAllDescendants(aChild: Node = this, descendants = []): Array<Node> {
    if (!aChild) {
      return null
    }
    for (const child of aChild.getChildren()) {
      descendants.push(child)
      this.getAllDescendants(child, descendants)
    }
    return descendants
  }

  public get open(): boolean {
    return this._open
  }

  public set open(bool: boolean) {
    this._open = bool
  }
}
