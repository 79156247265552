import {
  SaytByKeywordRequest,
  SaytOption,
  SaytRequest,
  SaytResponse,
} from 'src/api/searchResults/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import { Optional } from 'src/common-interfaces/generic-interfaces'
import { PartsCatalogSelection } from 'src/store/models/PartsCatalogModels'
import axios from 'axios'

class SaytServiceProvider extends BaseServiceProvider {
  saytApiUrl = import.meta.env.VITE_SAYT_API_URL
  saytApiKey = import.meta.env.VITE_SAYT_API_KEY
  ANY_CATEGORY_ID = 999 // NOTE: Special category ID provided by B2B team to address the fact that the B2C API does not include category information.

  constructor() {
    super(import.meta.env.VITE_SAYT_API_URL, '', '', '')
  }

  getSaytOptions = async (
    typedValue: string,
    language: string
  ): Promise<Array<SaytOption>> => {
    const req: SaytRequest = {
      'api-key': this.saytApiKey,
      searchTerm: typedValue,
      exactMatch: 'N',
      rows: 10,
      lang: language,
    }
    const resp = await axios.post<SaytResponse>(
      'aapa/control/getSAYTRecommendations',
      req,
      { baseURL: this.saytApiUrl }
    )
    return this.parseSaytOptions(resp?.data)
  }

  getSaytSynonymMatches = async (
    keyword: string,
    language: string
  ): Promise<Optional<SaytOption>> => {
    const req: SaytByKeywordRequest = {
      'api-key': this.saytApiKey,
      keyword,
      lang: language,
    }
    const resp = await axios.post<SaytResponse>(
      'aapa/control/getSAYTByKeyword',
      req,
      { baseURL: this.saytApiUrl }
    )
    const options = this.parseSaytOptions(resp?.data)
    const firstOption = options?.[0]
    if (!firstOption || firstOption?.astSearch) return undefined
    const allPartCatalogItems = options.flatMap((op) => op.partCatalogMappings)
    const combinedOptions = {
      ...firstOption,
      partCatalogMappings: allPartCatalogItems,
    }
    return combinedOptions
  }

  parseSaytOptions = (resp: SaytResponse): Array<SaytOption> => {
    const docs = resp?.result?.response?.docs ?? []
    return docs.map((doc) => {
      const catalogMappings = []
      for (const partGroupType of doc.EPEPartDescIds) {
        const [partTypeId, groupId] = partGroupType.split('_')
        const catalogMapping: PartsCatalogSelection = {
          category: { id: this.ANY_CATEGORY_ID, value: '' },
          group: { id: groupId, value: '' },
          terminology: { id: partTypeId, value: '' },
        }
        catalogMappings.push(catalogMapping)
      }
      const saytOption: SaytOption = {
        astSearch: doc.astSearch === 'Y',
        id: doc.uniqueId,
        label: doc.EPEPartDescName,
        partCatalogMappings: catalogMappings,
      }
      return saytOption
    })
  }
}

export default new SaytServiceProvider()
