import { GfxConditionType } from 'src/api/gfx/interfaces'
import { Optional } from 'src/common-interfaces/generic-interfaces'
import { SearchStore } from 'src/store/search/SearchStore'

export const SelectDiagram = (searchStore: SearchStore): Optional<string> => {
  const { gfxData } = searchStore

  if (!gfxData) {
    return undefined
  }

  if (gfxData.uniqueGFXAssetIds.length === 1) {
    return gfxData.uniqueGFXAssetIds[0]
  }

  const filteredRecords = gfxData.gfxRecords.filter((record) => {
    for (const attr of record.attributeList) {
      if (
        searchStore.getGfxAnswer(GfxConditionType.Attribute, attr.code) !==
        attr.label
      ) {
        return false
      }
    }

    for (const condition of record.specCondList) {
      if (
        searchStore.getGfxAnswer(GfxConditionType.Condition, condition.code) !==
        condition.label
      ) {
        return false
      }
    }
    return true
  })

  if (filteredRecords.length === 1) {
    return filteredRecords[0].gfxAssetsFound
  }

  if (filteredRecords.length > 1) {
    return filteredRecords[0].gfxAssetsFound
  }

  return undefined
}
