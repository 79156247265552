import { ReactElement } from 'react'
import { GfxConditionType, GfxSpecificCondition } from 'src/api/gfx/interfaces'
import { I18n } from 'src/i18n'
import styled from 'styled-components'
import { SpecificConditionI } from 'src/store/models/SpecificConditions'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { observer } from 'mobx-react-lite'
import { SpecificConditionQuestion } from './SpecificConditionQuestion'
import { GfxQuestion } from './GfxQuestion'
import { QuestionState } from './GfxSpecificConditionsModal'
import { IdValuePair } from 'src/store/models/KeyValuePair'
import { useSearchStore } from 'src/store/search/SearchStore'

interface GfxQuestionListProps {
  attributes: Array<GfxSpecificCondition>
  gfxConditions: Array<GfxSpecificCondition>
  normalSpecificConditions: Array<SpecificConditionI>
  onAnswerQuestion?: () => void
  allowDropdownReplacement?: boolean
}

export const GfxQuestionList = observer(
  (props: GfxQuestionListProps): ReactElement => {
    const {
      attributes,
      gfxConditions,
      normalSpecificConditions,
      onAnswerQuestion,
    } = props
    const { findQuestion: findNormalSpecificConditionQuestion } =
      useSpecificConditionStore()

    const allowDropdownReplacement = props.allowDropdownReplacement ?? false

    const searchStore = useSearchStore()

    const registeredQuestions: Array<QuestionState> = []

    const handleAnswerGfxQuestion = (
      type: GfxConditionType,
      questionCode: string,
      answerCode: string
    ) => {
      searchStore.answerGfxQuestion(type, questionCode, answerCode)
      markQuestionAnswered(questionCode)
    }

    const handleAnswerSpecificCondition = (
      question: SpecificConditionI,
      selection: IdValuePair<string>
    ) => {
      findNormalSpecificConditionQuestion(question).choice = selection
      markQuestionAnswered(`${question.id}`)
    }

    const markQuestionAnswered = (questionId: string): void => {
      const question = registeredQuestions.find(
        (q) => q.questionId === questionId
      )
      if (question) {
        question.hasAnswered = true
        scrollToNextQuestion()
        if (onAnswerQuestion) {
          onAnswerQuestion()
        }
      }
    }

    const scrollToNextQuestion = () => {
      const nextQuestion = registeredQuestions.find((q) => !q.hasAnswered)
      if (nextQuestion && nextQuestion.ref) {
        nextQuestion.ref.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (
      <>
        <Section>
          {attributes?.map((attribute) => (
            <GfxQuestion
              key={attribute?.code}
              type={GfxConditionType.Attribute}
              question={attribute}
              questionList={registeredQuestions}
              onChange={(questionCode, answerCode) =>
                handleAnswerGfxQuestion(
                  GfxConditionType.Attribute,
                  questionCode,
                  answerCode
                )
              }
              useDropdownReplacement={
                allowDropdownReplacement && attribute.code === 'VEHICLE SYSTEM'
              }
            />
          ))}
          {gfxConditions?.map((condition) => (
            <GfxQuestion
              key={condition?.code}
              type={GfxConditionType.Condition}
              question={condition}
              questionList={registeredQuestions}
              onChange={(questionCode, answerCode) => {
                handleAnswerGfxQuestion(
                  GfxConditionType.Condition,
                  questionCode,
                  answerCode
                )
              }}
            />
          ))}
        </Section>
        <Section>
          {normalSpecificConditions?.length > 0 && (
            <>
              <SubTitle>
                <I18n name="fitmentConditions" />
              </SubTitle>
              {normalSpecificConditions.map((question) => (
                <SpecificConditionQuestion
                  key={question.id}
                  question={question}
                  questionList={registeredQuestions}
                  onAnswer={(selection: IdValuePair<string>) => {
                    handleAnswerSpecificCondition(question, selection)
                  }}
                />
              ))}
            </>
          )}
        </Section>
      </>
    )
  }
)

const Section = styled.div`
  margin-bottom: 40px;
`

const SubTitle = styled.div`
  font-family: Oswald, serif;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 26px;
`
