import ArrowBack from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import { useState, useRef, ReactElement, useEffect } from 'react'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import { Button, TextInput } from 'src/ui-components'
import styled from 'styled-components'

const RenameListConfirmation = observer((): ReactElement => {
  const {
    nameListModal,
    setNameListModal,
    nameSelectedList,
    onNameList,
    nameListNameField,
    nameListNotesField,
    setNameListNameField,
    setNameListNotesField,
    listNameModalType,
  } = useListsStore()

  const [listName, setListName] = useState<string>(nameListNameField)
  const [listNote, setListNote] = useState<string>(nameListNotesField)

  useEffect(() => {
    setListName(nameListNameField)
    setListNote(nameListNotesField)
  }, [nameListNameField, nameListNotesField, nameListModal])

  const show = nameListModal
  const ref = useRef<HTMLInputElement>()

  const onBack = () => {
    setNameListModal(false)
  }

  const handleRenameConfirmed = () => {
    setNameListNameField(listName)
    setNameListNotesField(listNote)
    if (listNameModalType === 'new') {
      onNameList()
    } else {
      onNameList(nameSelectedList.id)
    }
  }
  useClickOutsideDetector([ref], show, () => setNameListModal(false))

  return (
    <ResponsiveDrawer
      anchor="bottom"
      open={show}
      onClose={() => setNameListModal(false)}
      PaperProps={{
        square: false,
      }}
      style={{
        width: '800px',
      }}
    >
      <Container ref={ref}>
        <HeaderContainer>
          <StyledBackIcon onClick={onBack} />
          <TitleHeader>
            {listNameModalType === 'rename' ? (
              <I18n name="renameList" />
            ) : (
              <I18n name="newList" />
            )}
            <StyledCloseIcon onClick={() => setNameListModal(false)} />
          </TitleHeader>
        </HeaderContainer>
        <ConfirmationTextContainer>
          <ConfirmationText>
            <div>
              <I18n name="category" />:{'\u00a0'}
              {nameSelectedList?.category}
            </div>
          </ConfirmationText>
          <TextInput
            label="name"
            value={listName}
            onChange={(value) => setListName(value)}
            type="text"
            placeholder=""
            noMargin
            width="100%"
          />
          <div style={{ marginTop: 20, width: '100%' }}>
            <Label>
              <I18n name="note" />
            </Label>
            <TextArea
              onChange={(event) => setListNote(event.target.value)}
              value={listNote}
              rows={4}
            />
          </div>
        </ConfirmationTextContainer>

        <ButtonContainer>
          <CancelButton
            onClick={onBack}
            variant="secondary"
            size="medium"
            text="cancel"
          />
          <CreateListButton
            onClick={handleRenameConfirmed}
            variant="primary"
            size="medium"
            text={
              listNameModalType === 'rename'
                ? Translate('renameList')
                : Translate('createList')
            }
            disabled={listName.length === 0}
          />
        </ButtonContainer>
      </Container>
    </ResponsiveDrawer>
  )
})

export default RenameListConfirmation

const ConfirmationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 0px 0px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 32px;
  }
`

const ConfirmationText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-bottom: 32px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  padding: 24px 24px 0px;
  margin-bottom: 46px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin-top: 47px;
  }
`

const CancelButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const ResponsiveDrawer = styled(Drawer)``

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`
const StyledBackIcon = styled(ArrowBack)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 27px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};
  margin-left: 30px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin-left: 0px;
    font-size: 24px;
  }
`
interface ContainerProps {
  height?: string
  width?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  padding: 24px;
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    height: 160px;
  }
`

const Label = styled.div`
  font-family: Roboto, serif;
  font-size: 14px;
  line-height: 24px;
  align-self: flex-start;
  margin-left: 5px;
  font-weight: bold;
`

const CreateListButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-top: 24px;
    width: 100%;

    `
const HeaderContainer = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 0px;
  *
`
