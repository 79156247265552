import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import OrderServiceProvider from 'src/services/OrderServiceProvider'
import { StoreInstances } from '../StoreInstancesContainer'
import {
  IAdvancedSearchForm,
  OrderHistoryItem,
  Detail,
  IReturnDataInfo,
} from './interfaces'
import { VehicleShort } from 'src/api/cart/interfaces'
import history from 'src/helpers/history'
import { UserAttributeKey } from '../user/interfaces'
import { Config } from 'src/config/ConfigManager'

export enum OrderStatus {
  ORDERED,
  PACKED,
  SHIPPED,
  DELIVERED,
}

export const defaultAdvancedSearch: IAdvancedSearchForm = {
  po: '',
  partNumber: '',
  vehicle: {
    year: '',
    make: '',
    model: '',
    engine: '',
  },
  confirmationID: '',
  location: '',
  notes: '',
}

const orderHistoryStoreDefaults = {
  fromDate: null,
  toDate: new Date(),
  searchParams: '',
  defaultAdvancedSearch,
}

export interface IOrderHistoryFilter {
  fromDate?: Date
  toDate?: Date
  searchTerm?: string
  po?: string
  partNumber?: string
  confirmationID?: string
  location?: string
  notes?: string
  vehicle?: VehicleShort
  start?: number
  limit?: number
  navigation?: boolean
}

export class OrderHistoryStore {
  public recentOrdersLoading = false

  public recentOrders: Array<OrderHistoryItem> = []

  public orderHistoryLoading = false

  public orderHistory: Array<OrderHistoryItem> = []

  public filterByModal = false

  public fromDate = orderHistoryStoreDefaults.fromDate

  public toDate = orderHistoryStoreDefaults.toDate

  public searchParams = orderHistoryStoreDefaults.searchParams

  public totalCount = 0

  public start = 0

  public limit = 10

  public selectedSort: 'orderedDate' | 'quantity' | 'totalCost' = undefined

  public advancedSearchForm: IAdvancedSearchForm =
    orderHistoryStoreDefaults.defaultAdvancedSearch

  public orderHistoryFilter: IOrderHistoryFilter = {}

  public selectedOrderItems: Detail[] = []

  public selectedReasonsItemsData: Detail[] = []
  public isReasonDropdownDisabled = false
  public returnOrderConformationDetails: IReturnDataInfo[] = []
  public returnProductEstimatedPrice: number = undefined
  public noteToStoreValue = ''
  public isRMAEnabled = Config.rmaEnabled

  constructor() {
    makeAutoObservable(this)
  }

  public setSelectedSort = (
    sort: 'orderedDate' | 'quantity' | 'totalCost'
  ): void => {
    if (this.selectedSort === sort) {
      this.selectedSort = undefined
    } else {
      this.selectedSort = sort
    }
    this.updateFilter()
  }

  public getOrderStatus = (status: string): number => {
    /* Using Javed's comment on 
    https://praxent.atlassian.net/browse/MP4P-346
    */

    if (!status || status === '') {
      return OrderStatus.ORDERED
    }
    if (status === 'Pending') {
      return OrderStatus.PACKED
    }
    if (status === 'Order') {
      return OrderStatus.SHIPPED
    }
    if (status === 'Delivered') {
      return OrderStatus.DELIVERED
    }
    return 0
  }

  public setFilter = (type: 'searchParams', value: string): void => {
    this[type] = value
    if (type === 'searchParams') {
      this.start = 0
      const searchFilter: IOrderHistoryFilter = {
        searchTerm: value,
        start: this.start,
      }
      this.updateFilter(searchFilter)
    }
  }

  public setDateFilter = (fromDate: Date = null, toDate: Date = null): void => {
    this.fromDate = fromDate
    this.toDate = toDate
    const dateFilter: IOrderHistoryFilter = {
      fromDate,
      toDate,
      start: 0,
    }
    this.updateFilter(dateFilter)
  }

  public changeFilterByModal = (open: boolean): void => {
    this.filterByModal = open
  }

  public onAdvancedSearch = (advancedSearchForm: IAdvancedSearchForm): void => {
    this.advancedSearchForm = {
      ...advancedSearchForm,
      vehicle: { ...advancedSearchForm.vehicle },
    }

    const searchFilter: IOrderHistoryFilter = {
      ...advancedSearchForm,
      vehicle: { ...advancedSearchForm.vehicle },
      start: 0,
    }
    this.updateFilter(searchFilter)
  }

  public fetchOrderHistory = async (
    start = 0,
    limit = 10,
    fromDate: Date,
    toDate: Date,
    searchTerm: string,
    poNumber: string,
    partNumber: string,
    confirmationNbr: string,
    location: string,
    noteTxt: string,
    vehicle: VehicleShort
  ): Promise<void> => {
    this.orderHistory = []
    this.start = start
    this.limit = limit
    this.orderHistoryLoading = true
    const { orgId } = StoreInstances?.userStore?.preferences || {
      orgId: undefined,
    }

    const res = await OrderServiceProvider.getOrderHistory(
      orgId,
      this.start,
      this.limit,
      {
        fromDate: fromDate?.toISOString() ?? null,
        toDate: toDate?.toISOString() ?? null,
        searchTerm,
        poNumber,
        partNumber,
        vehicle,
        confirmationNbr,
        location,
        noteTxt,
      },
      this.selectedSort
    )
    this.orderHistory = res.orders
    this.totalCount = res.totalCount
    this.orderHistoryLoading = false
  }

  public handleUpdate = (): void => {
    this.updateFilter()
  }

  public resetFilters = (): void => {
    this.fromDate = orderHistoryStoreDefaults.fromDate
    this.toDate = orderHistoryStoreDefaults.toDate
    this.searchParams = orderHistoryStoreDefaults.searchParams
    this.advancedSearchForm = orderHistoryStoreDefaults.defaultAdvancedSearch
  }

  public updateFilter = (
    updatedFilterObj?: IOrderHistoryFilter,
    navigation = true
  ): void => {
    this.orderHistoryFilter = {
      vehicle: this.advancedSearchForm.vehicle,
      ...this.orderHistoryFilter,
      ...updatedFilterObj,
    }

    if (navigation) {
      history.push(
        `/orderHistory?offset=${this.orderHistoryFilter.start}&limit=${
          this.orderHistoryFilter.limit
        }&fromDate=${
          this.orderHistoryFilter.fromDate?.toISOString() ?? ''
        }&toDate=${
          this.orderHistoryFilter.toDate?.toISOString() ?? ''
        }&searchTerm=${this.orderHistoryFilter.searchTerm}&poNumber=${
          this.orderHistoryFilter.po
        }&partNumber=${this.orderHistoryFilter.partNumber}&confirmationNbr=${
          this.orderHistoryFilter.confirmationID
        }&location=${this.orderHistoryFilter.location}&noteTxt=${
          this.orderHistoryFilter.notes
        }&vehicle=${JSON.stringify(this.orderHistoryFilter.vehicle)}`
      )
    }
  }

  public setSelectedOrderItems = (items: Detail[]): void => {
    this.selectedOrderItems = items
  }

  public setSelectedReasonsItemsData = (items: Detail[]): void => {
    this.selectedReasonsItemsData = items
  }

  public setIsReasonDrodownDisabled = (val: boolean): void => {
    this.isReasonDropdownDisabled = val
  }

  public setReturnOrderConformationDetails = (val: IReturnDataInfo[]): void => {
    this.returnOrderConformationDetails = val
  }

  public setReturnProductEstimatedPrice = (val: number): void => {
    this.returnProductEstimatedPrice = val
  }

  public setReturnDataByOrderId = (orderId: string): void => {
    // Get the data based on orderId
    const orderReturnData = JSON.parse(
      localStorage.getItem('returnSelectionParts')
    )[orderId]['parts']
    this.setSelectedOrderItems(orderReturnData)
  }

  public setReturnConfirmationDataByOrderId = (orderId: string): void => {
    // Get the Return confirmation page data based on orderId
    const orderReturnConfirmationData = JSON.parse(
      localStorage.getItem('returnConfirmationParts')
    )[orderId]
    this.setReturnOrderConformationDetails(orderReturnConfirmationData)
  }

  public setNoteToStoreValue = (val: string): void => {
    this.noteToStoreValue = val
  }

  public isRmaEnabled = (): boolean => {
    const rma = StoreInstances?.userStore?.getUserAttribute(
      UserAttributeKey.rma
    )
    return Number(rma) > 0 && this.isRMAEnabled ? true : false
  }
}

export const OrderHistoryContext = createContext<OrderHistoryStore>(undefined)

export const useOrderHistoryStore = (): OrderHistoryStore => {
  return useContext(OrderHistoryContext)
}
