import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserStore } from 'src/store/user/UserStore'

const SignOut = (): null => {
  const history = useHistory()
  const userStore = useUserStore()
  useEffect(() => {
    userStore.signOut()
    // eslint-disable-next-line no-console
    console.trace('in signout')

    history.push('/login')
  }, [history, userStore])
  return null
}

export default SignOut
