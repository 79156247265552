import { createContext, ReactElement, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { UserStore } from 'src/store/user/UserStore'
import { LanguageContextModel } from '../models/LanguageContextModel'

const initialContext = {
  selectedLanguage: 'en_US',
}

export const LanguageContext =
  createContext<LanguageContextModel>(initialContext)

LanguageContext.displayName = 'Language'

interface LanguageContextProps {
  children: ReactNode
  userStoreRef: UserStore
}

export const LanguageContextStore = observer(
  ({ children, userStoreRef }: LanguageContextProps): ReactElement => {
    const value = {
      selectedLanguage: userStoreRef?.preferences?.language ?? 'en_US',
    }

    return (
      <LanguageContext.Provider value={value}>
        {children}
      </LanguageContext.Provider>
    )
  }
)
