import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { SaytOption } from 'src/api/searchResults/interfaces'
import { zIndex } from 'src/theme/zIndex'
import { Translate } from 'src/i18n'

interface SaytOverlayProps {
  options: Array<SaytOption>
  onSelectOption: (selected: SaytOption) => void
  fieldValue: string
  wrapperRef: React.RefObject<HTMLInputElement>
}

export const SaytOverlay = (props: SaytOverlayProps): ReactElement => {
  const { options, fieldValue, wrapperRef, onSelectOption } = props
  if (options.length === 0) {
    return null
  }
  return (
    <ResultsWrapper data-testid="results" ref={wrapperRef}>
      <DataWrapper>
        <ResultsItem>{Translate('results')}</ResultsItem>
        {fieldValue &&
          options.length > 0 &&
          options.map((option) => (
            <DataItem key={option.id} onClick={() => onSelectOption(option)}>
              {option.label}
            </DataItem>
          ))}
      </DataWrapper>
    </ResultsWrapper>
  )
}

const ResultsWrapper = styled.div`
  width: 359px;
  top: 110%;
  min-height: 36px;
  position: absolute;
  background: white;
  border-radius: 3px;
  border: 1px solid #d3d2cf;
  z-index: ${zIndex.dropdown};
  left: 0;
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const DataItem = styled.span`
  padding-left: 20px;
  width: 100%;
  text-align: left;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  cursor: pointer;
  &:hover {
    background: #f3f2ee;
  }
  &:active {
    background: #777777;
  }
`
const ResultsItem = styled.span`
  padding-left: 20px;
  width: 100%;
  text-align: left;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  cursor: pointer;
  color: #9b9b9b;
`
