import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { I18n } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { ContactUsDrawer } from './ContactUsDrawer'
import { useUiStateStore } from '../../../../store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'
import { ContactInfo } from 'src/api/cart/interfaces'
import { Tooltip } from 'src/ui-components'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import { OrgType } from 'src/services/attributes/AttributesModel'

const getString = (value: string | undefined | null): string =>
  typeof value === 'string' ? value : ''

const ContactUs = observer((): ReactElement => {
  const user = useUserStore()
  const { parentOrgId, orgType, orgId } = user.preferences
  const { showContactDrawer, setShowContactDrawer } = useUiStateStore()
  const isContactTypeEmail = user.preferences.contactUs_type === 'By Email'
  const [userContactData, setUserContactData] = useState<ContactInfo>()

  useEffect(() => {
    const contactId = orgType === OrgType.LOC ? parentOrgId : orgId

    if (contactId) {
      UserDataServiceProvider.contactAdressInfo(contactId).then((resp) => {
        setUserContactData(resp)
      })
    }
  }, [orgId, orgType, parentOrgId])

  const handleContactUsDrawer = (show: boolean) => {
    setShowContactDrawer(show)
  }

  const contactData = useMemo(() => {
    const { address, phone, name } = userContactData || {}
    return `${getString(name)},
     ${getString(address?.line1)},
    ${getString(address?.city)},${getString(address?.region)},${getString(
      address?.postalCode
    )}
    ${getString(phone?.extension)}, ${getString(phone?.phoneNumber)}`
  }, [userContactData])

  return (
    <ContactUS>
      <ContactContainer
        role="button"
        onClick={() => handleContactUsDrawer(true)}
      >
        <Tooltip
          show={!isContactTypeEmail && showContactDrawer}
          message={contactData}
          onClose={() => handleContactUsDrawer(false)}
        >
          <I18n name="contactUs" />
        </Tooltip>
      </ContactContainer>

      {isContactTypeEmail && (
        <ContactUsDrawer
          open={showContactDrawer}
          onCloseDrawer={() => setShowContactDrawer(false)}
        />
      )}
    </ContactUS>
  )
})

export default ContactUs

const ContactUS = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
  margin-right: 1em;
  position: relative;
`
const ContactContainer = styled.div`
  min-width: 84px;
`
