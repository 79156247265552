import { ArrowDropDown, ArrowDropUp, Delete } from '@mui/icons-material'
import { Box, Grow, IconButton } from '@mui/material'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Overlay } from 'react-bootstrap'
import { useClickOutsideDetector } from 'src/hooks'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import styled from 'styled-components'

interface SingleOption {
  label: string
  value: string
}
interface SortSelectProps {
  hasDelete?: boolean
  onChange: (value: string) => void
  value?: string
  options: Array<SingleOption>
  label: string
  width?: string
  height?: string
  defaultValue?: string
  mobile?: boolean
}
export const SortSelect = (sortProps: SortSelectProps): ReactElement => {
  const {
    hasDelete,
    onChange,
    value: defaultValue,
    options,
    label,
    width,
    height,
    mobile,
  } = sortProps
  const [active, setActive] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string | null>(null)
  const fieldRef = useRef(null)
  const overlayRef = useRef(null)
  const optionsRef = useRef(null)
  useClickOutsideDetector([overlayRef, optionsRef], active, () =>
    setActive(false)
  )

  useEffect(() => {
    if (defaultValue && !selectedValue) {
      setSelectedValue(defaultValue)
    }
  }, [defaultValue, selectedValue])

  const onSelectItem = (value: string | null) => {
    setSelectedValue(value)
    const option = options.filter((item) => item.value === value)[0].value
    onChange(option)
    setActive(false)
  }
  const isSelected = (value: string): boolean => {
    return value === selectedValue
  }
  const renderLabel = () => {
    if (!active && selectedValue) {
      return options.filter((item) => item.value === selectedValue)?.[0]?.label
    }
    return defaultValue || 'All'
  }
  return (
    <MainWrapper ref={overlayRef} mobile={mobile}>
      <FieldButtonWrapper
        style={{ width, height }}
        ref={fieldRef}
        onClick={() => setActive(!active)}
      >
        <FieldButton disabled={false} height={height}>
          {label !== '' ? (
            <Label color="#9B9B9B">
              {label}:{'\u00a0'}
              <Label>{renderLabel()}</Label>
            </Label>
          ) : (
            <Label>{renderLabel()}</Label>
          )}
          {active ? (
            <ArrowDropUp htmlColor="#000" />
          ) : (
            <ArrowDropDown htmlColor="#000" />
          )}
        </FieldButton>
      </FieldButtonWrapper>
      {hasDelete && (
        <IconButton onClick={() => onSelectItem(null)} className="ml-0 pl-0">
          <Delete style={{ color: 'black' }} />
        </IconButton>
      )}
      <Overlay
        show={active}
        placement="top-start"
        popperConfig={{ strategy: 'absolute' }}
        target={fieldRef.current}
      >
        {({ ...props }) => (
          <Wrapper
            width={width}
            {...props}
            style={{
              ...props.style,
              zIndex: zIndex.dropdown,
            }}
          >
            <Box>
              <Grow in>
                <InnerWrapper ref={optionsRef}>
                  {options.map(
                    (option) =>
                      option && (
                        <Option
                          key={option?.value}
                          selected={isSelected(option?.value)}
                          onClick={() => onSelectItem(option?.value)}
                        >
                          {option?.label}
                        </Option>
                      )
                  )}
                </InnerWrapper>
              </Grow>
            </Box>
          </Wrapper>
        )}
      </Overlay>
    </MainWrapper>
  )
}
interface MainWrapperProps {
  mobile?: boolean
}
const MainWrapper = styled.div<MainWrapperProps>`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: ${(p) => (p.mobile ? 'block' : 'none')};
  }
  margin-right: 12px;
`
interface FieldButtonProps {
  disabled: boolean
  height?: string
}
interface OptionProps {
  selected: boolean
}
interface LabelProps {
  color?: string
}
const Label = styled.span<LabelProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${(p) => p.color || defaultTheme.colors.almostBlack};
`
interface WrapperProps {
  width?: string
}
const Wrapper = styled.div<WrapperProps>`
  display: inline-block;
  background: white;
  width: ${(p) => p.width || '220px'};
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${defaultTheme.colors.componentsBackground};
  color: ${(p) => p.theme.colors.text};
  border-radius: 3px;
  border: ${(p) => p.theme.fieldBorder};
`

const FieldButton = styled.span<FieldButtonProps>`
  border: ${(props) => props.theme.fieldBorder};
  display: flex;
  font-size: 14px;
  background-color: ${(p) =>
    p.disabled
      ? p.theme.colors.disabledBackground
      : defaultTheme.colors.componentsBackground};
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.height || '40px'};
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  width: 220px;
  margin-right: 0;
  cursor: pointer;
`

const FieldButtonWrapper = styled.span`
  display: flex;
  flex-grow: 1;
  position: relative;
`
const Option = styled.div<OptionProps>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(p) => (p.selected ? p.theme.colors.primary : 'none')};
  &:hover {
    background-color: ${(p) =>
      p.selected
        ? p.theme.colors.primary
        : defaultTheme.colors.disabledBackground};
  }
  &:active {
    background-color: ${(p) =>
      p.selected ? p.theme.colors.primary : '#777777'};
  }
`
