import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { BaseServiceProvider } from './BaseServiceProvider'
import {
  SaveOrUpdateAttributeRequest,
  SaveOrUpdateAttributeResponse,
  UuidType,
} from './attributes/AttributesModel'

class AttributesServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'attributes', '1.0', '')
  }

  saveOrUpdateAttribute = async (
    reqData: Array<SaveOrUpdateAttributeRequest>
  ): Promise<number> => {
    const resp = await this.post<SaveOrUpdateAttributeResponse>(
      'saveOrUpdateAttribute',
      reqData
    )

    if (resp.status === 200) {
      this.updateUserPreferences(reqData)
    }

    return resp.status
  }

  updateUserPreferences = (
    reqData: Array<SaveOrUpdateAttributeRequest>
  ): void => {
    if (reqData[0].uuid === UuidType.PARTS_USER_FILTER_LEVEL) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        display_userFiltering: reqData[0].attrValue.toString(),
      })
    }
    if (reqData[0].uuid === UuidType.LABOR_RATE) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        epeFeatures_laborRate: reqData[0].attrValue.toString(),
      })
    }
    if (reqData[0].uuid === UuidType.DISPLAY_SORTORDER) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        display_sortOrder: reqData[0].attrValue.toString(),
      })
    }
    if (reqData[0].uuid === UuidType.SHOW_YOUR_COST) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        display_priceFld: reqData[0].attrValue.toString(),
      })
    }
    if (reqData[0].uuid === UuidType.SELECTED_LANGUAGE) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        language: reqData[0].attrValue.toString(),
      })
    }
    if (reqData[0].uuid === UuidType.MISCPREFERENCES_DEFAULTS) {
      StoreInstances.userStore.setUserPreferences({
        ...StoreInstances.userStore.preferences,
        miscPreferences: reqData[0].attrValue,
      })
    }
  }
}

export default new AttributesServiceProvider()
