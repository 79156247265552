import { CatalogNode } from '../CatalogNode'
import { PartsCatalogSelections } from '../../models/PartsCatalogModels'
import { CatalogNodeType } from '../interfaces'

interface IgroupInfo {
  [key: string]: {
    total: number
    selected: number
  }
}

export class FlattenVisitor {
  private readonly selectedPartTypes: Array<CatalogNode>

  constructor() {
    this.selectedPartTypes = []
  }

  public visit(node: CatalogNode): void {
    if (node.type === CatalogNodeType.PART_TYPE) {
      if (node.isSelected) {
        this.selectedPartTypes.push(node)
      }
    }
  }

  public getCGTsForTransport(): PartsCatalogSelections {
    const cgts = []
    // For MCL sort: MPV3-3911
    // Looping to get the total terminologies in a group and the selected terminologies in a group
    const groupInfo: IgroupInfo = {}
    for (const partType of this.selectedPartTypes) {
      const group = partType.getParent()
      groupInfo[group.value] = {
        total: group.getChildren().length,
        selected: groupInfo[group.value]
          ? groupInfo[group.value].selected + 1
          : 1,
      }
    }

    for (const partType of this.selectedPartTypes) {
      const group = partType.getParent()
      const category = group.getParent()
      const terminology = partType
      cgts.push({
        category: {
          id: category.id,
          value: category.value,
        },
        group: {
          id: group.id,
          value: group.value,
        },
        terminology: {
          id: terminology.id,
          value: terminology.value,
        },
        selectAll:
          groupInfo[group.value].total === groupInfo[group.value].selected,
      })
    }
    return cgts
  }
}
