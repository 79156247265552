import {
  Mode,
  SelectionsInterface,
} from 'src/features/partsCatalog/Selections/interfaces.ts'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { VisibleStep } from 'src/store/partsCatalog/PartsCatalog.ts'

interface SharedCatalogSelectorMobileLogicReturnType {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  step: VisibleStep
  setStep: React.Dispatch<React.SetStateAction<VisibleStep>>
  mode: Mode
  setMode: (m: Mode) => void
}

interface SharedCatalogSelectorMobileLogicProps extends SelectionsInterface {
  setHideTitle: (arg: boolean) => void
}
export default (
  props: SharedCatalogSelectorMobileLogicProps
): SharedCatalogSelectorMobileLogicReturnType => {
  const { mode, setMode, setHideTitle } = props
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(VisibleStep.CATEGORY)

  useEffect(() => {
    setHideTitle(step !== VisibleStep.START)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (
      location?.pathname.indexOf('searchResults') > -1 &&
      mode !== Mode.UPDATE_SELECTIONS
    ) {
      setMode(Mode.DISPLAY_SELECTIONS)
    }
  }, [location, mode, setMode])

  return { loading, setLoading, step, setStep, mode, setMode }
}
