export enum SearchOptions {
  vehicle_ymme = 'vehicleYMME',
  license_plate = 'vehicleLicensePlate',
  vin = 'vehicleVIN',
}

export interface SearchPreference {
  id: SearchOptions
  hidden?: boolean
}
