import { CatalogNode } from './CatalogNode'

export class CatalogTreeSaver {
  public static getTreeJson(root: CatalogNode): string {
    return JSON.stringify(root, CatalogTreeSaver.getCircularReplacer())
  }

  private static getCircularReplacer = () => {
    const seen = new WeakSet()
    return (_key: unknown, value: unknown) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return undefined
        }
        seen.add(value)
      }
      return value
    }
  }
}
