import { Dispatch, ReactElement, SetStateAction } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { RouteUnauthenticated } from './RouteUnauthenticated'
// These pages are so small that including them in the main bundle is better than lazy loading them.
import SendCode from 'src/routes/SendCode'
import Login from 'src/routes/Login'
import ResetPassword from 'src/routes/ResetPassword'
import ContactAdmin from 'src/routes/ContactAdmin'
// Not lazy loading auto login page intentionally.
import AutoLogin from 'src/routes/AutoLogin'

interface PublicRoutesProps {
  urlToRedirect: string
  setUrlToRedirect: Dispatch<SetStateAction<string>>
  redirectToLogin: boolean
}

const PublicRoutes = (props: PublicRoutesProps): ReactElement => {
  const { urlToRedirect, setUrlToRedirect, redirectToLogin } = props

  return (
    <Switch>
      <RouteUnauthenticated exact path="/login">
        <Login
          urlToRedirect={urlToRedirect}
          setUrlToRedirect={setUrlToRedirect}
        />
      </RouteUnauthenticated>
      <RouteUnauthenticated exact path="/sendCode">
        <SendCode />
      </RouteUnauthenticated>
      <RouteUnauthenticated exact path="/resetPassword">
        <ResetPassword />
      </RouteUnauthenticated>
      <RouteUnauthenticated exact path="/contactAdmin">
        <ContactAdmin />
      </RouteUnauthenticated>
      <RouteUnauthenticated exact path="/auto-login">
        <AutoLogin />
      </RouteUnauthenticated>

      {redirectToLogin && <Redirect to="/login" />}
    </Switch>
  )
}

export default PublicRoutes
