import { StockOptions, FilterGroupName } from 'src/store/search/SearchStore'

export interface IstockOption {
  groupName: string
  items: StockOptions[]
}

export const stockOptionsCases = (displayFiltering: string): IstockOption => {
  switch (String(displayFiltering)) {
    case '1':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.ONLY_PRICED],
      }
    case '2':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.ONLY_STOCKED],
      }
    case '3':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.ONLY_PRICED, StockOptions.ONLY_STOCKED],
      }
    case '4':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.SHOW_ALL],
      }
    case '5':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.SHOW_ALL, StockOptions.ONLY_PRICED],
      }
    case '6':
      return {
        groupName: FilterGroupName.STOCK,
        items: [StockOptions.SHOW_ALL, StockOptions.ONLY_STOCKED],
      }
    case '7':
      return {
        groupName: FilterGroupName.STOCK,
        items: [
          StockOptions.SHOW_ALL,
          StockOptions.ONLY_PRICED,
          StockOptions.ONLY_STOCKED,
        ],
      }
    default:
      return {
        groupName: FilterGroupName.STOCK,
        items: [],
      }
  }
}
