import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import styled from 'styled-components'

interface HeaderProps {
  yearMakeModel: string
  engine: string
}

const DrawerHeader = observer((props: HeaderProps): ReactElement => {
  const { yearMakeModel, engine } = props
  return (
    <Header>
      <VehicleInfo>
        <VehicleYearMakeModel>{yearMakeModel}</VehicleYearMakeModel>
        <VehicleEngine>{engine}</VehicleEngine>
      </VehicleInfo>
    </Header>
  )
})
export default DrawerHeader

const Header = styled.div``

const VehicleInfo = styled.div`
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
`

const Bold = styled.span`
  font-weight: 700;
`

const VehicleYearMakeModel = styled(Bold)``

const VehicleEngine = styled.span`
  padding-left: 0.5rem;
`
