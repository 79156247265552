import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { Menu as HamburgerMenu } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import { Button, Menu, MenuItem } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CloseIcon from '@mui/icons-material/Close'
import { breakpoints } from 'src/theme/breakpoints'
import { NavLink } from 'react-router-dom'
import { I18n } from 'src/i18n'
import { ExperianAutocheckButton } from 'src/features/customizeSearch/components/ExperianAutocheckButton'
import { IMenuItem } from './MenuItem'
import { useMenuItems } from 'src/hooks/useMenuItems'
import { useSearchStore } from 'src/store/search/SearchStore'
import { defaultTheme } from 'src/theme'

const iconStyleMobile = { fontSize: 25, color: '#fff' }
const mobileMenuStyle = { fontSize: 25, width: '80%' }

const MobileHamburgerMenu = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuItems: IMenuItem[] = useMenuItems()
  const open = Boolean(anchorEl)
  const searchStore = useSearchStore()
  const { currentVehicle } = searchStore

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navLinkStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '24px',
    marginTop: '15px',
    width: '100%',
    padding: '6px 8px',
    fontSize: '18px',
  }

  return (
    <div>
      <StyledButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon style={iconStyleMobile} />
      </StyledButton>
      <MobileMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={mobileMenuStyle}
      >
        <MenuItemMenu className="home">
          <I18n name="Menu" />
          <CloseIcon onClick={handleClose} />
        </MenuItemMenu>
        {menuItems.map((menuItem, idx) => {
          const key = `mobile-menu-item-${idx}`
          return (
            <div key={key}>
              <NavLink
                exact
                to={menuItem.path}
                style={navLinkStyle}
                onClick={menuItem.onClick}
              >
                <I18n name={menuItem.text} />
                <ArrowForwardIosIcon style={{ fontSize: '1rem' }} />
              </NavLink>
              <Divider sx={{ width: '100%', height: '0px' }} />
            </div>
          )
        })}
        <ExpAutoCheck>
          <ExperianAutocheckButton vin={currentVehicle?.vin} />
        </ExpAutoCheck>
      </MobileMenu>
    </div>
  )
}

export default MobileHamburgerMenu

const MenuIcon = styled(HamburgerMenu)``

const StyledButton = styled(Button)`
  display: none;
  @media screen and (width < ${breakpoints.tabletLarge}px) {
    display: block;
  }
`
const MobileMenu = styled(Menu)`
  top: 13px;
  left: -15px;
  a {
    text-decoration: none;
    color: black;
  }
  .MuiPaper-root {
    padding: 0 16px;
    width:100%;
    @media screen and (width < ${breakpoints.tabletLarge}px) {
      width:100%;;
    }
    @media screen and (width < ${breakpoints.mobileSmall}px) {
      width:50%
    }
    height: 100%;
  }
  }
`

const MenuItemMenu = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  margin-top: 15px;
  width: 100%;
  padding: 6px 8px;

  svg {
    font-size: 1rem;
  }

  &.home {
    font-weight: bold;
    font-family: 'Roboto', serif;
    font-size: 23px;
    margin-top: 0;
    svg {
      font-size: 1.5rem;
    }
  }
`
const ExpAutoCheck = styled.div`
  margin-top: 15px;
  a {
    align-items: center;
    color: ${defaultTheme.colors.blueLight};
    gap: 10px;
  }
  svg {
    font-size: 25px;
  }
`
