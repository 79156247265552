import { ReactElement } from 'react'
import LoginScreen from 'src/features/login/LoginScreen'

export interface LoginScreenProps {
  urlToRedirect?: string
  setUrlToRedirect?: React.Dispatch<React.SetStateAction<string>>
}

const Login = ({
  urlToRedirect,
  setUrlToRedirect,
}: LoginScreenProps): ReactElement => {
  return (
    <LoginScreen
      urlToRedirect={urlToRedirect}
      setUrlToRedirect={setUrlToRedirect}
    />
  )
}

export default Login
