import { ReactElement, useEffect, useState } from 'react'

import { Sanitized } from '../search/Results/utils/Sanitized'
import { LogoImage } from '../navigation/TopNavigation/components/CommonMenuItems'
import { Button } from 'src/ui-components'
import { useHistory } from 'react-router-dom'
import UserInfoServiceProvider from 'src/services/UserInfoServiceProvider'
import logo from 'src/assets/images/logo.png'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useUserStore } from 'src/store/user/UserStore'
import styles from './termsandconditions.module.scss'
import {
  ISelectedOption,
  LanguageDropdown,
} from '../search/AllProductsSearch/LanguagesDropdown'
import { options } from '../search/AllProductsSearch/constants'
import { capitaliseOption } from 'src/helpers/utils'
const getTermsAndConditions = async (langauge: string): Promise<string> => {
  const response = await axios.get<string>(
    `https://s3.amazonaws.com/myplace-eula-dxp/2019010806+MyPlace+Terms+of+Use+EULA--${langauge}.htm`,
    {
      headers: {
        'Content-Type': 'text/html',
      },
    }
  )
  return response.data
}

const TermsAndConditions = (): ReactElement => {
  const [conditions, setConditions] = useState<string>('')
  const [spinner, setSpinner] = useState<boolean>(false)
  const [agreeButtonLoader, setAgreeButtonLoader] = useState<boolean>(false)
  const [language, setLanguage] = useState<ISelectedOption>(options[0])
  const [isEulaAccepted, setEulaAccepted] = useState<string>('')
  const history = useHistory()
  const userStore = useUserStore()

  useEffect(() => {
    setEulaAccepted(userStore.preferences.EULA_accepted || 'false')
    if (userStore.preferences.EULA_accepted === 'true') {
      history.push('/home')
    }
  }, [history, userStore.preferences.EULA_accepted])

  useEffect(() => {
    setSpinner(true)
    getTermsAndConditions(capitaliseOption(language))
      .then((resp) => {
        setConditions(resp.replace(/�/g, ''))
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
      .finally(() => {
        setSpinner(false)
      })
  }, [language])

  const acceptEula = () => {
    setAgreeButtonLoader(true)
    UserInfoServiceProvider.acceptTermsAndConditions()
      .then((response) => {
        if (response === 200) {
          history.push('/home')
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
      .finally(() => {
        setAgreeButtonLoader(false)
      })
  }

  if (!isEulaAccepted || isEulaAccepted === 'true') {
    return (
      <div className={styles.SpinnerContainer}>
        <Spinner animation="border" />
      </div>
    )
  }

  const onSelectLanguage = (selectedLanguage: ISelectedOption) => {
    setLanguage(selectedLanguage)
  }

  return (
    <div className="container">
      <div className={styles.LogoContainer}>
        <div className={styles.langContainer}>
          <LogoImage src={logo} alt="My place 4 parts logo"></LogoImage>
          <div className={styles.DropDownContainer}>
            <LanguageDropdown
              onLanguageChange={onSelectLanguage}
              selectedLanguage={language}
            />
          </div>
        </div>
      </div>
      <div className={styles.TermsContainer}>
        {spinner ? (
          <Spinner></Spinner>
        ) : (
          <div>
            <Sanitized html={conditions}></Sanitized>
            <div className={styles.AcceptButtonContainer}>
              <Button
                loading={agreeButtonLoader}
                text="I Agree"
                onClick={() => acceptEula()}
                variant="primary"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TermsAndConditions
