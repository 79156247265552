import { Transport } from 'src/api/cart/interfaces'
import { IShipmentEstimate } from 'src/api/shipment/interfaces'

export const regularEstimate: IShipmentEstimate = {
  basePrice: 0,
  estimatedTransitDays: 0,
  expectedDeliveryDate: '',
  serviceId: 'Regular',
  shipmentDate: '',
  totalPrice: 0.0,
}

export const PUROLATOR = 'Purolator'
export const DELIVERY = 'Delivery'

/*
The below constant is created for use in Transport Dropdown as an option.
Clicking upon this option should open the  purolator drawer.
Right now this is not yet used.
*/

export const puroLatorTransport: Transport = {
  seqNo: '',
  deliveryMethod: 'C', //TODO: R or U or C?
  carrier: PUROLATOR,
  shipCode: PUROLATOR,
  releaseCode: PUROLATOR,
  id: PUROLATOR, // UI Only ID
}
