import { Box, Modal } from '@mui/material'
import { ReactElement } from 'react'
import { Translate } from 'src/i18n'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import { Close } from '@mui/icons-material'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'

interface SimpleDeleteModalProps {
  showDeleteModal: boolean
  handleDeleteModal: () => void
  modalName: string
  handleDeleteAction: () => void
}

export const SimpleDeleteModal = (
  props: SimpleDeleteModalProps
): ReactElement => {
  const { showDeleteModal, handleDeleteModal, modalName, handleDeleteAction } =
    props

  const isTablet = window.innerWidth <= breakpoints.tablet

  const modalStyle = {
    position: 'absolute',
    top: `${isTablet ? 'auto' : '50%'}`,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    outline: 'none',
    width: `${isTablet ? '100%' : '450px'}`,
    bottom: `${isTablet ? '-18%' : 'auto'}`,
    padding: '25px',
  }

  return (
    <Modal open={showDeleteModal} onClose={handleDeleteModal}>
      <Box sx={modalStyle}>
        <HeaderContainer>
          <StyledText fontSize="22px">{`Delete ${Translate(
            modalName
          )}`}</StyledText>
          <StyledClose onClick={handleDeleteModal} />
        </HeaderContainer>
        <StyledText normal fontSize="14px" style={{ marginTop: '10px' }}>
          {`${Translate('deleteMessage')} ${Translate(modalName)}? ${Translate(
            'unDone'
          )}`}
        </StyledText>
        <ButtonsWrapper isTablet={isTablet}>
          <StyledDelete onClick={handleDeleteAction}>
            <DeleteIcon style={{ width: '22px' }} />
            <StyledText
              capitalize
              style={{ color: 'white' }}
            >{`Delete ${Translate(modalName)}`}</StyledText>
          </StyledDelete>
          <CancelButton
            onClick={handleDeleteModal}
            variant="secondary"
            size="medium"
            text={Translate('cancel')}
          />
        </ButtonsWrapper>
      </Box>
    </Modal>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface StyledTextProps {
  normal?: boolean
  fontSize?: string
  capitalize?: boolean
}
const StyledText = styled.span<StyledTextProps>`
  font-size: ${(p) => p.fontSize};
  font-family: ${(p) => (p.normal ? 'Roboto, serif' : 'Oswald, serif')};
  color: black;
  font-weight: ${(p) => (p.normal ? '500' : 'bold')};
  display: block;
  text-align: left;
  text-transform: ${(p) => (p.capitalize ? 'uppercase' : 'none')};
`

interface ButtonsWrapperProps {
  isTablet?: boolean
}

const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`
const CancelButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`
const StyledDelete = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${defaultTheme.colors.red};
  color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`
const StyledClose = styled(Close)`
  &:hover {
    cursor: pointer;
  }
`
