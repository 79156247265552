import { ReactElement } from 'react'
import { TableColumnOption } from '../../interfaces'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { Checkbox } from 'src/ui-components'
import { observer } from 'mobx-react-lite'
import { ICheckboxClickHandler } from 'src/ui-components/forms/Checkbox'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { breakpoints } from 'src/theme/breakpoints'

interface Props {
  column: Array<TableColumnOption>
}

const MakesColumn = observer((props: Props): ReactElement => {
  const { column } = props
  const makesStore = StoreInstances.vehicleMakesStore

  const handleMakeChange = (e: ICheckboxClickHandler) => {
    const { label } = e
    const makes = makesStore.getSelectedMakes()
    const element = makes.find((m) => m == label)
    if (element) makesStore.deleteMake(element)
    else makesStore.addMake(label)
  }

  const isChecked = (option: TableColumnOption): boolean => {
    const makes = makesStore.getSelectedMakes()
    return makes.find((m) => m == option.value) != undefined
  }

  return (
    <Wrapper>
      {column.map((make) => (
        <Make key={make.id}>
          <Checkbox
            value={make.id}
            label={make.value}
            onClick={handleMakeChange}
            active={isChecked(make)}
          />
        </Make>
      ))}
    </Wrapper>
  )
})

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  gap: 10px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`

const Make = styled.div``

export default MakesColumn
