import { History } from 'history'
import PartServiceProvider from 'src/services/PartServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import {
  ManufacturerList,
  ManufacturerRequestData,
  PartsList,
} from 'src/store/models/SearchModels'
import { NonCachedSearchType } from 'src/store/search/SearchStore'

export const getPartTypes = (
  data: string,
  history: string[] | History<unknown>
): Promise<PartsList> => {
  return PartServiceProvider.getInterchangePartTypes(data).then((res) => {
    if (res.status === 204) {
      StoreInstances.searchStore.lastNonCachedSearch =
        NonCachedSearchType.searchByInterchange
      history.push('/searchResults')
      return {
        partTypes: [],
        partsBinId: '',
      }
    } else if (res?.data?.partTypes?.length === 0) {
      StoreInstances.searchStore.lastNonCachedSearch =
        NonCachedSearchType.searchByInterchange
      history.push('/searchResults')
      return res.data
    } else {
      return res.data
    }
  })
}

export const getManufacturers = (
  data: ManufacturerRequestData,
  history: string[] | History<unknown>
): Promise<ManufacturerList> => {
  return PartServiceProvider.getInterchangeManufacturerData(data).then(
    (res) => {
      if (res.status === 204) {
        StoreInstances.searchStore.lastNonCachedSearch =
          NonCachedSearchType.searchByInterchange
        history.push('/searchResults')
        return {
          manufacturerList: [],
          partsBinId: '',
        }
      } else if (res?.data?.manufacturerList?.length === 0) {
        StoreInstances.searchStore.lastNonCachedSearch =
          NonCachedSearchType.searchByInterchange
        history.push('/searchResults')
        return res.data
      } else {
        return res.data
      }
    }
  )
}
