import { CheckOutlined } from '@mui/icons-material'
import { ReactElement, useState } from 'react'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'

interface CheckboxModel {
  value: string
  label: string
  selected?: boolean
  isCheckMixed?: boolean
  isTitle?: boolean
  hideSelector?: boolean
  onChange?: (arg1: string, arg2?: string) => void
  bold?: boolean
}

export const Checkbox = (props: CheckboxModel): ReactElement => {
  const {
    value,
    label,
    selected,
    isTitle,
    isCheckMixed,
    onChange,
    hideSelector,
    bold,
  } = props
  const [isHovering, setIsHovering] = useState(false)

  const handleClick = (): void => {
    return onChange(value)
  }
  return (
    <Wrapper
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {!hideSelector && (
        <CheckboxWrapper
          hover={isHovering}
          checked={selected || isCheckMixed}
          mixed={isCheckMixed}
        >
          {selected && !isCheckMixed ? (
            <CheckOutlined
              style={{
                fontSize: 15,
                stroke: 'black',
                strokeWidth: '2',
              }}
            />
          ) : (
            <div style={{ display: 'block', width: 20, height: 20 }} />
          )}
        </CheckboxWrapper>
      )}
      <Label bold={isTitle || bold}>
        <span>{label}</span>
      </Label>
    </Wrapper>
  )
}

Checkbox.defaultProps = {
  selected: false,
  isTitle: false,
  isCheckMixed: false,
  onChange: () => undefined,
  hideSelector: false,
  bold: false,
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
`
interface LabelProps {
  bold: boolean
}
const Label = styled.div<LabelProps>`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 24px;
  color: #050505;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  display: inline-flex;
  flex-grow: 1;
  text-overflow: ellipsis;
`
interface CheckWrapperProps {
  checked: boolean
  hover: boolean
  mixed: boolean
}
const CheckboxWrapper = styled.div<CheckWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  flex: 0 0 16px;
  border: 2px solid
    ${(props) =>
      props.checked
        ? defaultTheme.colors.primary
        : props.hover
          ? 'rgb(5,5,5)'
          : '#adadaa'};
  box-sizing: border-box;
  border-radius: 3px;
  background: ${(props) =>
    props.checked ? defaultTheme.colors.primary : '#fff'};

  margin-right: 10px;
  ${(props) =>
    props.mixed &&
    `
  :after {
    content: '';
    width: 10px;
    height: 3px;
    background: rgb(0, 0, 0);
    position: absolute;
    justify-self: center;
    align-self: center;
  }
  `}
`
