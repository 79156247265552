import { LookupType } from 'src/helpers/lookupType'
import { VehicleType } from './CustomizeSearchStore'
import { remove } from 'lodash-es'

export const prioritizePreference = (
  a: VehicleType,
  b: VehicleType,
  preference: VehicleType
): number => {
  if (b.id === preference?.id) return 1
  if (a.id === preference?.id) return -1
  return 0
}

export const sortVehicleTypes = (
  catalogs: VehicleType[],
  preference?: VehicleType
): VehicleType[] => {
  const sorted = [...catalogs]
  sorted.sort((a, b) => a.id - b.id)

  // Move Heavy Duty to the second position in the array
  const isHeavyDuty = (c) => c.id === LookupType.HEAVY_DUTY
  const heavyDuty = remove(sorted, isHeavyDuty)?.[0]
  if (heavyDuty) sorted.splice(1, 0, heavyDuty)

  if (preference) sorted.sort((a, b) => prioritizePreference(a, b, preference))

  return sorted
}
