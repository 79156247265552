import NiceModal from '@ebay/nice-modal-react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { ReactElement } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { IS_INTEGRATED_VERSION } from './config/ConfigManager'
import history from './helpers/history'
import { Routes } from './main/router/Routes'
import { DiscardAndStartNewOrder } from './store/cart/DiscardAndStartNewOrder'
import DuplicateVehicleModal from './store/cart/DuplicateVehicleModal'
import { defaultTheme } from './theme'
import './theme/bootstrap-overrides.css'
import { ModalNotification } from './ui-components'
import DDSUnavialbleDrawer from './features/orderHistory/components/ddsAdvancedTracker/DDSUnavialableDrawer'
import { SnackbarProvider } from 'notistack'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://tanstack.com/query/v3/docs/framework/react/guides/window-focus-refetching
      refetchOnWindowFocus: false,
    },
  },
})

function App(): ReactElement {
  const muiTheme = createTheme()

  return (
    <Wrapper>
      <Content>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={defaultTheme}>
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider
                classes={{
                  root: 'notification-wrapper',
                }}
              >
                <Router history={history}>
                  <NiceModal.Provider>
                    <div>
                      <Routes />
                    </div>
                    <ModalNotification />
                    <DuplicateVehicleModal />
                    <DiscardAndStartNewOrder />
                    <DDSUnavialbleDrawer />
                  </NiceModal.Provider>
                </Router>
              </SnackbarProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </MuiThemeProvider>

        {/* Form used to transfer data to host integrations when `useDataIsland` is enabled. */}
        {IS_INTEGRATED_VERSION && (
          <form id="partsBasketForm">
            <input type="hidden" id="partsBasket" name="partsBasket" value="" />
          </form>
        )}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  max-width: 1920px;
`

export default App
