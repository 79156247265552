export enum PartsBasketAction {
  checkAvailabilityRequest = 'checkAvailabilityRequest',
  checkAvailabilityResponse = 'checkAvailabilityResponse',
  goShoppingRequest = 'goShoppingRequest',
  goShoppingResponse = 'goShoppingResponse',
  placeOrderRequest = 'placeOrderRequest',
  placeOrderResponse = 'placeOrderResponse',
}
export const partsBasketResponseType = {
  [PartsBasketAction.goShoppingResponse]: '1',
  [PartsBasketAction.goShoppingRequest]: '1',
  [PartsBasketAction.checkAvailabilityResponse]: '2',
  [PartsBasketAction.checkAvailabilityRequest]: '2',
  [PartsBasketAction.placeOrderResponse]: '3',
  [PartsBasketAction.placeOrderRequest]: '3',
}

export enum LineType {
  part = 'part',
  labor = 'labor',
  specification = 'specification',
  graph = 'graph',
}

export enum CartButtonValues {
  TRANSFER = 'transfer',
  ORDER = 'order',
  BOTH = 'both',
}

export interface IGoShoppingParams {
  partnerId: string
  sellerId: string
  buyerId: string
  goShoppingUrl?: string
  useDataIsland?: string
  localInventory?: string
  width?: string // Ignoring this as V3 is responsive.
  height?: string // Ignoring this as V3 is responsive.
  partsBasket: string
  // Used to handle displaying the order and transfer buttons in checkout.
  cartButton?: CartButtonValues
  showHeader?: string // Need to check how this is being used.
  HOOK_URL?: string
  service?: string
  checkAvailabilityRedirect?: string
  aesServer: string
}

export interface IParsedGoShoppingParams {
  sellerId: string
  buyerId: string
  partnerId: string
  goShoppingUrl?: string
  useDataIsland: boolean
  localInventory: boolean
  width?: string // Ignoring this as V3 is responsive.
  height?: string // Ignoring this as V3 is responsive.
  partsBasket: string
  // Used to handle displaying the order and transfer buttons in checkout.
  cartButton?: CartButtonValues
  showHeader?: string // Need to check how this is being used.
  HOOK_URL?: string
  service?: string
  checkAvailabilityRedirect?: boolean
  aesServer: boolean
}

// Copied from AES for reference. V3 is not using catalogId 50.
// export const SAT_CALALOG_ID = 50
// export const EPE_CALALOG_ID = 11
// export const GSA_CALALOG_ID = 7

/**
EPE = ePartExpert application catalog

SAT=Supplies, Accessories, Tools

AST=Accessories, Supplies, Tools

GSA=Google Search

MSC=Miscellaneous searches
*/
export enum CatalogIdType {
  SAT = 'SAT',
  AST = 'AST',
  EPE = 'EPE',
  GSA = 'GSA',
  MSC = 'MSC',
}

/**
 * Valid values of IndexId are:
				For SAT catalog
				- DES - Search by Part Description
				- NUM - Part Number search
				For EPE catalog
				- PTS - Part Type Search
				For GSA catalog
				- SRH - GSA search
				For MSC catalog
				- SRH - search
				- VEH - shortHand search
 */
export enum IndexIdType {
  DES = 'DES',
  NUM = 'NUM',
  PTS = 'PTS',
  SRH = 'SRH',
  VEH = 'VEH',
}
