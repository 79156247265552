import { CheckAvailabilityResponse12 } from 'src/api/embedded/CheckAvailability'
import { BaseServiceProvider } from './BaseServiceProvider'

class EmbeddedServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_OP_URL, 'embedded', '1.1', '')
  }

  checkAvailability = async (creds, partsBasket) => {
    const resp = await this.post<CheckAvailabilityResponse12>(
      'proxy',
      `partnerId=${creds.partnerId}&buyerId=${creds.buyerId}&sellerId=${creds.sellerId}&partsBasket=${partsBasket}&service=checkAvailability`
    )
    return resp
  }
}

export default new EmbeddedServiceProvider()
