import { ReactElement } from 'react'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import { I18n, Translate } from 'src/i18n'
import { TableColumn } from '../../interfaces'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import Box from '@mui/material/Box/Box'
import MakesColumn from './MakesColumn'
import { observer } from 'mobx-react-lite'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { Checkbox } from 'src/features/partsCatalog/Selections/components/Checkbox'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  options: Array<TableColumn>
}

const CustomMakesDrawer = createNiceModal(
  observer((props: Props): ReactElement => {
    const { options } = props
    const modal = useModal()
    const makesStore = StoreInstances.vehicleMakesStore

    const handleUnSelectAll = (): void => {
      makesStore.unSelectAllMakes()
    }

    const handleOnClose = (): void => {
      makesStore.clearBuffer()
      modal.remove()
    }

    const save = (): void => {
      makesStore.persistBuffer()
      const makes = makesStore.getSelectedMakes()
      VehicleServiceProvider.updateCustomMakes(makes)
      const filterEnabled = makes?.length > 0
      VehicleServiceProvider.updateMakesFilterStatus(filterEnabled)
      makesStore.setMakesFilterEnabled(filterEnabled)
      makesStore.setShowMakesToggle(filterEnabled)
      modal.remove()
    }

    const title = (
      <Title>
        <I18n name="customizeMakeList" />
        <StyledCloseIcon onClick={handleOnClose} />
      </Title>
    )

    const header = (
      <Header>
        <Instructions>
          <I18n name="selectTheMakesThatYouWantToSeeWhenAddingAVehicle" />
        </Instructions>
        <Checkbox
          value=""
          label={Translate('unSelectAll')}
          onChange={handleUnSelectAll}
          selected={makesStore?.getSelectedMakes()?.length > 0}
          isCheckMixed={makesStore?.getSelectedMakes()?.length > 0}
        />
      </Header>
    )

    const body = (
      <Body>
        <Box>
          <InnerWrapper>
            {options.map((column) => (
              <MakesColumn
                key={`column_${column.id}_${column.header}`}
                column={column.options}
              />
            ))}
          </InnerWrapper>
        </Box>
      </Body>
    )

    const footer = (
      <Footer>
        <Button variant="secondary" text="cancel" onClick={handleOnClose} />
        <Button text="save" onClick={save} />
      </Footer>
    )

    return (
      <BaseDrawer
        open={modal.visible}
        title={title}
        header={header}
        showHeaderDivider
        body={body}
        showFooterDivider
        footer={footer}
        onClose={() => modal.remove()}
        paddingX="0"
      />
    )
  })
)

const BaseSection = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 1rem 1rem 1rem 1rem;
  }
`

const Header = styled(BaseSection)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-top: 0rem;
  padding-bottom: 0rem;
`

const Body = styled(BaseSection)`
  display: inline-block;
  background-color: #fff;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`

const Footer = styled(BaseSection)`
  display: Flex;
  justify-content: end;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(p) => p.theme.colors.text};
  max-height: 100%;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 60vh;
    overflow: auto;
    padding: 0px;
  }
`

const Instructions = styled.div`
  margin-bottom: 1rem;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const Title = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0rem;
  padding-bottom: 0rem;
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

export default CustomMakesDrawer
