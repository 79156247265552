import { ReactElement, useEffect, useRef } from 'react'
import { Box, Drawer, Grid, Card } from '@mui/material'
import styled from 'styled-components'
import { Edit } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { useSearchStore } from 'src/store/search/SearchStore'
import { observer } from 'mobx-react-lite'
import { defaultTheme } from 'src/theme'
import { I18n, Translate } from 'src/i18n'
import { Button, Select } from 'src/ui-components'
import { zIndex } from 'src/theme/zIndex'
import { Spinner } from 'react-bootstrap'
import { ServiceIntervalsDrawerProps } from '../interfaces'
import { breakpoints } from '../../../../theme/breakpoints'
import { Print } from 'src/ui-components/util/Print'

export const ServiceIntervalsDrawer = observer(
  (props: ServiceIntervalsDrawerProps): ReactElement => {
    const {
      onClose,
      open,
      showEditFitmentConditions,
      handleEditFitmentConditions,
    } = props
    const {
      currentVehicle,
      selectedServiceIntervalsType,
      mileageOptions,
      selectedMileageOptions,
      recommendedServices,
      setSelectedServiceIntervalsType,
      setSelectedMileageOptions,
      setRecommendedServices,
      getMileageOptions,
      getRecommendedServicesTable,
      recommendedServicesLoading,
      setMileageOptions,
      mileageOptionsLoading,
    } = useSearchStore()

    const defaultServiceIntervalType = [
      {
        id: 1,
        value: Translate('normal'),
      },
      {
        id: 2,
        value: Translate('severe'),
      },
    ]

    const handleOnclose = (): void => {
      onClose()
    }

    const printRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      setSelectedServiceIntervalsType(defaultServiceIntervalType[0])
      getMileageOptions(vehicle)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      setRecommendedServices(null)
      setMileageOptions(null)
      setSelectedMileageOptions(null)
      getMileageOptions(vehicle)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedServiceIntervalsType])

    useEffect(() => {
      if (selectedMileageOptions) {
        getRecommendedServicesTable(vehicle)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMileageOptions])

    const defaultMessage = (header: string, body: string) => (
      <DefaultContainer>
        <DefaultMessage>
          <h1>
            <I18n name={header} />
          </h1>
          <p>{Translate(body)}</p>
        </DefaultMessage>
      </DefaultContainer>
    )

    const vehicle = {
      year: { id: currentVehicle.year.id, value: currentVehicle.year.value },
      make: { id: currentVehicle.make.id, value: currentVehicle.make.value },
      modelType: currentVehicle.modelType,
      model: currentVehicle.model,
      engine: currentVehicle.engine,
      specificConditions: currentVehicle.specificConditions,
    }

    return (
      <Drawer anchor="right" open={open} onClose={handleOnclose}>
        <Container ref={printRef}>
          <HeaderContainer>
            <HeaderWrapper>
              <StyledText>
                <I18n name="serviceIntervals" />
              </StyledText>
              <StyledCloseIcon onClick={handleOnclose} />
            </HeaderWrapper>
            <VehicleInfo container columnSpacing={1} marginTop={1}>
              <GridItemCarModel fontWeight={700} item>
                {`${currentVehicle.year?.value} ${currentVehicle.make?.value} ${currentVehicle.model?.value}`}
              </GridItemCarModel>
              <GridItemCarModel marginLeft={1}>
                {currentVehicle.engine?.value}
              </GridItemCarModel>
            </VehicleInfo>
            {showEditFitmentConditions && (
              <Grid marginTop="5px">
                <EditFitmentsLink
                  onClick={() => {
                    handleEditFitmentConditions()
                  }}
                >
                  <Edit fontSize="inherit" />
                  <I18n name="editFitmentConditions" />
                </EditFitmentsLink>
              </Grid>
            )}

            <Grid
              container
              rowSpacing={1}
              columnSpacing={1}
              paddingBottom="32px"
              marginTop="20px"
            >
              <Grid item xs={12} fontFamily="Roboto">
                <SelectWrapper>
                  <SelectDropdown>
                    <LabelSelect>
                      <I18n name="servicesIntervalType" />
                    </LabelSelect>
                    <SelectElement
                      optionFontSize="16px"
                      options={defaultServiceIntervalType}
                      selectedOption={selectedServiceIntervalsType}
                      onSelect={(selected) => {
                        setSelectedServiceIntervalsType(selected)
                      }}
                    />
                  </SelectDropdown>
                  <SelectDropdown>
                    <LabelSelect>
                      <I18n name="mileage" />
                    </LabelSelect>
                    <SelectElement
                      optionFontSize="16px"
                      options={mileageOptions}
                      selectedOption={selectedMileageOptions}
                      onSelect={(itemSelected) => {
                        setSelectedMileageOptions(itemSelected)
                      }}
                      defaultValue="Select Mileage"
                    />
                  </SelectDropdown>
                </SelectWrapper>
              </Grid>
            </Grid>
          </HeaderContainer>

          {recommendedServicesLoading ? (
            <BodyContainer>
              <SpinnerContainer>
                <Spinner animation="border" />
              </SpinnerContainer>
            </BodyContainer>
          ) : recommendedServices ? (
            <BodyContainer>
              <BodyWrapper margin="24px">
                <TitleRecommendedServices>
                  <I18n name="recommendedServices" />
                </TitleRecommendedServices>
                <HeaderRow>
                  <ColHeader width="35%">
                    <I18n name="subOperation" />
                  </ColHeader>
                  <ColHeader width="35%">
                    <I18n name="description" />
                  </ColHeader>
                  <ColHeader width="15%">
                    <I18n name="year" />
                  </ColHeader>
                  <ColHeader width="15%">
                    <I18n name="type" />
                  </ColHeader>
                </HeaderRow>

                {recommendedServices?.map((service) => (
                  <Wrapper
                    key={`${service.intervalType}_${service.subOperation}`}
                  >
                    <Row>
                      <Td width="35%">{service.subOperation}</Td>
                      <Td width="35%">{service.text}</Td>
                      <Td width="15%">{service.year}</Td>
                      <Td width="15%">{service.intervalType}</Td>
                    </Row>
                  </Wrapper>
                ))}
              </BodyWrapper>
              <CardContainer>
                <TitleRecommendedServices>
                  <I18n name="recommendedServices" />
                </TitleRecommendedServices>
                <CardWrapper>
                  {recommendedServices?.map((service) => (
                    <CardContent
                      key={`${service.intervalType}_${service.subOperation}`}
                      variant="outlined"
                    >
                      <CardInfo>
                        <CardHeader>
                          <I18n name="subOperation" />
                        </CardHeader>
                        <CardData>{service.subOperation}</CardData>
                      </CardInfo>
                      <CardInfo>
                        <CardHeader>
                          <I18n name="description" />
                        </CardHeader>
                        <CardData>{service.text}</CardData>
                      </CardInfo>
                      <CardInfo>
                        <CardHeader>
                          <I18n name="year" />
                        </CardHeader>
                        <CardData>{service.year}</CardData>
                      </CardInfo>
                      <CardInfo>
                        <CardHeader>
                          <I18n name="type" />
                        </CardHeader>
                        <CardData>{service.intervalType}</CardData>
                      </CardInfo>
                    </CardContent>
                  ))}
                </CardWrapper>
              </CardContainer>
            </BodyContainer>
          ) : (selectedMileageOptions ||
              !mileageOptions ||
              mileageOptions.length === 0) &&
            !mileageOptionsLoading ? (
            <BodyContainer>
              {defaultMessage('warning', 'noServiceInterval')}
            </BodyContainer>
          ) : (
            <BodyContainer>
              {defaultMessage('recommendedServices', 'recommendedServicesBody')}
            </BodyContainer>
          )}

          <FooterGrid>
            <Grid>
              <FooterBox display="flex" justifyContent="flex-end">
                <Button
                  variant="secondary"
                  text="cancel"
                  onClick={handleOnclose}
                />
                <Print
                  printRef={printRef}
                  documentTitle={`${Translate('SerivceIntervals')} `}
                  displayText={`${Translate('Print')}`}
                  printButton={true}
                />
              </FooterBox>
            </Grid>
          </FooterGrid>
        </Container>
      </Drawer>
    )
  }
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1000px;
  background-color: ${defaultTheme.colors.componentsBackground};
  font-family: 'Roboto';
  z-index: ${zIndex.modal};
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 450px;
  }
  @media print {
    background-color: white;
    display: flex;
    width: 100%;
  }
`
const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const VehicleInfo = styled(Grid)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
  }
`

const CardContainer = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const CardWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  @media print {
    position: relative;
    overflow: visible !important;
  }
`

const CardContent = styled(Card)`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 30px 30px 30px;
  @media print {
    position: relative;
    overflow: visible !important;
  }
`

const CardHeader = styled.div`
  font-style: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

const CardData = styled.div`
  font-style: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const HeaderContainer = styled.div`
  padding: 24px 24px 0px 24px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  margin-bottom: 9px;
  height: 64px;
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  align-self: center;
  margin-bottom: 1px;
  @media print {
    display: none;
  }
`

const StyledText = styled.p`
  font-size: 28px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  align-self: center;
  margin: 0px;
`

const BodyContainer = styled(Grid)`
  border-top: 1px solid #adadaa;
  border-bottom: 1px solid #adadaa;
  height: 509px;
  overflow-y: auto;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 0px 24px;
  }
  @media print {
    height: 100%;
    overflow-y: visible;
  }
`

const BodyWrapper = styled(Grid)`
  margin: 24px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const FooterGrid = styled(Grid)`
  position: sticky;
  background-color: ${defaultTheme.colors.white};
  padding: 32px 32px 32px 0;
  margin-bottom: 20px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-bottom: 0;
  }
  @media print {
    display: none;
  }
`

const GridItemCarModel = styled(Grid)`
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto', serif;
`

const SelectWrapper = styled.div`
  display: flex;
  @media print {
    display: none;
  }
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
  }
`

const SelectDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const LabelSelect = styled.p`
  //styleName: P Small / Bold;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
`
const EditFitmentsLink = styled.span`
  margin: 0px;

  cursor: pointer;
  svg {
    margin-right: 6px;
    font-size: 17px;
  }
  span {
    margin-left: 0;
    margin-right: 24.67px;
  }

  color: ${defaultTheme.colors.link};
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  @media print {
    display: none;
  }
`
const DefaultContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    justify-content: start;
  }
`

const DefaultMessage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 120px;

  h1 {
    font-family: 'Oswald';
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
  }
  p {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    width: 340px;
    margin: 0px;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: center;
    text-align: start;
    p {
      text-align: start;
    }
  }
`

const TitleRecommendedServices = styled.h2`
  //styleName: H / 4;
  font-family: Oswald;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
`

const HeaderRow = styled.div`
  background-color: ${defaultTheme.colors.primaryText};
  color: ${defaultTheme.colors.secondary};
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`

interface HeaderRowProps {
  width?: string
}
const ColHeader = styled.div<HeaderRowProps>`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  width: ${(p) => p.width};
  color: ${defaultTheme.colors.componentsBackground};
  mix-blend-mode: normal;
  padding-right: 24px;
  padding-left: 24px;
`
const Wrapper = styled.div`
  background: #f9f9f7;
`
const Row = styled.div`
  /* Light Gray */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #adadaa;
`
interface TdProps {
  clickable?: boolean
  width?: string
}
const Td = styled.div<TdProps>`
  vertical-align: top;
  padding: 16px 0px 16px 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;

  /* Almost Black */

  color: ${defaultTheme.colors.almostBlack};
  width: ${(p) => p.width};
`
const FooterBox = styled(Box)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    justify-content: center;
  }
`
const SelectElement = styled(Select)`
  width='276px';
   @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`
