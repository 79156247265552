import { format, zonedTimeToUtc } from 'date-fns-tz'

export const toLocaleDateString = (date: Date): string =>
  format(date, 'E, d LLL y p z')

/**
 * https://www.npmjs.com/package/date-fns-tz#date-and-time-zone-formatting
 * @param date
 * @param customFormat
 */
export const toLocaleDateStringCustomFormat = (
  date: Date,
  customFormat: string
): string => format(date, customFormat)

export const toUTCFromDateAndFormat = (date: Date): string => {
  const timeZone = 'UTC'
  const utcDate = zonedTimeToUtc(date, timeZone)
  return toLocaleDateStringCustomFormat(utcDate, 'p z')
}
