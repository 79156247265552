/* eslint-disable jsx-a11y/no-autofocus */
import { KeyboardEventHandler, ReactElement, ReactNode } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import { I18n, Translate } from 'src/i18n'
import { NumericFormat } from 'react-number-format'

interface TextInputModel {
  value: string
  label?: string
  placeholder?: string
  onChange?: (value: string) => void
  icon?: ReactNode
  style?: CSSProperties
  disabled?: boolean
  type?: 'password' | 'text' | 'currency' | 'tel'
  width?: string
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  noMargin?: boolean
  autoFocus?: boolean
  required?: boolean
  error?: boolean
  onValueChange?: (value: string) => void
}

export const TextInput = (props: TextInputModel): ReactElement => {
  const {
    onChange,
    placeholder,
    label,
    icon,
    disabled,
    type,
    width,
    noMargin,
    autoFocus,
    required,
    error,
    onValueChange,
    ...restOfProps
  } = props

  return (
    <Wrapper width={width} noMargin={noMargin} className="text-input-wrapper">
      <Label>
        <I18n name={label} />
        {required ? '*' : ''}
      </Label>
      <InputWrapper
        disabled={disabled}
        noMargin={noMargin}
        width={width}
        error={error}
      >
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {type === 'currency' && (
          <NumericInput
            onChange={(e) => onChange(e.target.value)}
            placeholder={Translate(placeholder)}
            disabled={disabled}
            decimalScale={2}
            prefix="$"
            fixedDecimalScale
            allowNegative={false}
            autoFocus={autoFocus}
            onValueChange={(values) => {
              onValueChange(values.floatValue.toString())
            }}
            {...restOfProps}
          />
        )}

        {(type === 'password' || type === 'text') && (
          <Input
            type={type}
            onChange={(e) => onChange(e.target.value)}
            placeholder={Translate(placeholder)}
            disabled={disabled}
            autoFocus={autoFocus}
            {...restOfProps}
          />
        )}
        {type === 'tel' && (
          <NumericInput
            type={type}
            onChange={(e) => onChange(e.target.value)}
            placeholder={Translate(placeholder)}
            disabled={disabled}
            allowNegative={false}
            decimalScale={0}
            autoFocus={autoFocus}
            {...restOfProps}
          />
        )}
      </InputWrapper>
    </Wrapper>
  )
}

TextInput.defaultProps = {
  placeholder: '',
  label: '',
  style: {},
  icon: null,
  disabled: false,
  type: 'text',
  width: '200px',
  onKeyPress: null,
  noMargin: false,
  autoFocus: false,
  required: false,
  error: false,
}

interface WrapperProps {
  width: string
  noMargin: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  margin: ${(p) => (p.noMargin ? '0' : '10px')};
  width: ${(p) => p.width};
`
const Label = styled.div`
  font-family: Roboto, serif;
  font-size: 14px;
  line-height: 24px;
  align-self: flex-start;
  margin-left: 5px;
  font-weight: bold;
`

const IconWrapper = styled.span`
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
`

const InputStyle = css`
  display: inline-block;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  caret-color: ${(p) => p.theme.colors.primary};
`
const Input = styled.input`
  ${InputStyle}
`

const NumericInput = styled(NumericFormat)`
  ${InputStyle}
`

interface InputWrapperProps {
  disabled: boolean
  noMargin: boolean
  width: string
  error: boolean
}

const InputWrapper = styled.span<InputWrapperProps>`
  display: flex;
  align-items: center;
  border: 1px solid
    ${(p) => (p.error ? p.theme.colors.red : p.theme.colors.border)};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.text};
  margin: ${(p) => (p.noMargin ? '0' : '5px')};
  min-width: ${(p) => p.width || '200px'};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.disabledBackground : '#FFF'};
`
