import { AddCircleOutline, Search, Add } from '@mui/icons-material'
import { LinearProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import CategoryItem from './Category'

const LeftPanel = observer((): ReactElement => {
  const {
    getCategories,
    loadingCategories,
    selectedList,
    showListGroups,
    setSelectedList,
    setCategoryNameModalType,
    setCategoryNameModal,
    setSearchTerm,
    root,
  } = useListsStore()

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const [searchFieldValue, setSearchFieldValue] = useState('')

  return (
    <Container navigateToListGroupsMobile={showListGroups}>
      <Header>
        <HeaderText>
          <Title>
            <I18n name="lists" />
          </Title>
          <CreateCategoryItem
            onClick={() => {
              setCategoryNameModal(true)
              setCategoryNameModalType('new')
            }}
          >
            <AddCircleOutline />
            <CreateCategoryTitle>
              <I18n name="newCategory" />
            </CreateCategoryTitle>
          </CreateCategoryItem>

          <CreateCategoryItemMobile
            onClick={() => {
              setCategoryNameModal(true)
              setCategoryNameModalType('new')
            }}
          >
            <AddIcon />
            <CreateCategoryTitle>
              <I18n name="newCategory" />
            </CreateCategoryTitle>
          </CreateCategoryItemMobile>
        </HeaderText>
        <FieldWrapper>
          <Search
            style={{
              margin: 8,
              fontSize: 20,
              cursor: 'pointer',
              color: defaultTheme.colors.disabledButtonText,
              alignSelf: 'center',
            }}
            onClick={() => setSearchTerm(searchFieldValue)}
          />
          <Field
            value={searchFieldValue}
            onChange={(e) => {
              setSearchFieldValue(e.target.value)
              if (e.target.value === '') {
                setSearchTerm(e.target.value)
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchTerm(searchFieldValue)
              }
            }}
            data-testid="input"
            placeholder={Translate('searchParts')}
          />
          {searchFieldValue?.length > 0 && (
            <ClearButton
              type="button"
              onClick={() => {
                setSearchTerm('')
                setSearchFieldValue('')
              }}
            >
              <span className="material-icons">clear</span>
            </ClearButton>
          )}
        </FieldWrapper>
      </Header>
      {loadingCategories ? (
        <LinearProgress />
      ) : (
        <Items>
          <div>
            {root.getChildren().map((category) => (
              <CategoryItem
                key={category.id}
                category={category}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            ))}
          </div>
        </Items>
      )}
    </Container>
  )
})

export default LeftPanel

interface ContainerProps {
  navigateToListGroupsMobile: boolean
}
const Container = styled.div<ContainerProps>`
  background: ${defaultTheme.colors.componentsBackground};
  width: 20%;
  margin: 20px;
  margin-right: 0px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  @media screen and (max-width: ${breakpoints.laptop}px) {
    width: 25%;
    overflow-x: hidden;
  }

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 96%;
    margin: 20px 0px 0px 0px;
    align-self: center;
    display: ${(props) =>
      props.navigateToListGroupsMobile ? 'none' : 'block'};
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  align-items: flex-start;
  position: sticky;
  top: 0px;
  z-index: 1;
  background: ${defaultTheme.colors.componentsBackground};
  /*   height: 160px; */

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 16px;
    position: initial;
  }
`
const Title = styled.h3`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: ${defaultTheme.colors.primaryText};

  mix-blend-mode: normal;
  margin-bottom: 10px;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 20px;
    margin-bottom: 0px;
  }
`

const FieldWrapper = styled.div`
  background: ${defaultTheme.colors.componentsBackground};
  border: ${defaultTheme.fieldBorder};
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  height: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
`

const Field = styled.input`
  border: none;
  width: 100%;
  height: 40px;
  flex-grow: 1;
  overflow: hidden;

  &:focus {
    outline-width: 0;
    outline: none;
  }
  &::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${defaultTheme.colors.greyDark};
    text-overflow: ellipsis;

    content: 'Line 1\A Line 2\A Line 3\A';
  }
`
const Items = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 427px;
  width: 96%;
  @media screen and (max-width: ${breakpoints.laptopLarge}px) {
    width: 90%;
  }
`
const CreateCategoryItem = styled.div`
  display: flex;
  border-top: none;
  padding: 0px;
  min-height: fit-content;
  width: fit-content;
  margin-bottom: 3px;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`
const CreateCategoryTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  color: ${defaultTheme.colors.primaryText};
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Oswald;
    margin-left: 4px;
  }
`

const CreateCategoryItemMobile = styled(CreateCategoryItem)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: flex;
    border-top: none;
    padding: 0px;
    min-height: fit-content;
    width: fit-content;
    margin-bottom: 3px;
  }
`

const HeaderText = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`

const AddIcon = styled(Add)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    font-size: 18px;
  }
`
const ClearButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 4px;
  color: ${defaultTheme.fieldBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;
`
