import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Custom hook to scroll page to the top on page change.
export const useScrollToTop = (): void => {
  const history = useHistory()
  useEffect(() => {
    if (!history) {
      return () => undefined
    }
    return history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      } as unknown as ScrollToOptions)
    })
  }, [history])
}
