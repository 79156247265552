import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'

export interface GfxSpecificConditionsRequest {
  vehicle: Vehicle
  group: IdValueGeneric<number | string, string>
}

export interface GfxRequest extends GfxSpecificConditionsRequest {
  selectedGfxPartTypeIds?: string
  gfxAssetNbr: string
  browserType: number
}

export interface GfxResponse {
  gfxsvgRecord: GfxSvgRecord
  status: string
}

export interface GfxSvgRecord {
  recordID: string
  msgType: string
  assetName: string
  svgDocument: string
  svgData: string
  dataSet: string
  assetType: string
  refreshKey?: number
  hasLoaded: boolean
}

export interface GfxSpecificConditionsResponse {
  gfxRecords: GfxRecordsData
  status: string
}

export interface GfxRecordsData {
  uniqueGFXAssetIds: Array<string>
  gfxRecords: Array<GfxRecord>
  specificConditions: Array<GfxSpecificCondition>
  attributes: Array<GfxSpecificCondition>
  selectedPartsGroup?: IdValueGeneric<string, string>
}

export interface GfxRecord {
  descList: string // Comma separated list of integers
  recordID: string
  specCondList: Array<CodeLabelPair>
  attributeList: Array<CodeLabelPair>
  gfxAssetsFound: string
}

export interface GfxSpecificCondition {
  code: string
  label: string
  type: string
  gfxConditions: Array<CodeLabelPair>
}

export interface CodeLabelPair {
  code: string
  label: string
}

export enum GfxConditionType {
  Attribute = 'attribute',
  Condition = 'condition',
}
