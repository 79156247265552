import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { Brand } from 'src/features/astPunchOut/interfaces'
import { AstPunchOutCatalogAPI } from '../partsCatalog/api/AstPunchOutCatalogAPI'
import { PartsCatalog } from '../partsCatalog/PartsCatalog'

export const AstPunchOutContext = createContext<AstPunchOutStore | undefined>(
  undefined
)

export const useAstPunchOut = (): AstPunchOutStore => {
  const astPunchOutStore = useContext(AstPunchOutContext)
  if (!astPunchOutStore) {
    throw new Error(
      'No AstPunchOutContext.Provider found when calling useAstPunchOut.'
    )
  }
  return astPunchOutStore
}

export class AstPunchOutStore {
  catalog: PartsCatalog

  api: AstPunchOutCatalogAPI

  private _loadingBrands: boolean

  constructor() {
    makeAutoObservable(this)
    this.api = new AstPunchOutCatalogAPI()
    this.catalog = new PartsCatalog(this.api)
    this._loadingBrands = false
  }

  fetchBrands = async (): Promise<Brand[]> => {
    this._loadingBrands = true
    const brands = await this.api.fetchBrands()
    const mappedBrands: Brand[] = brands.map((b): Brand => {
      const brand = {
        id: b.value,
        value: b.id,
        selected: false,
      }
      return brand
    })
    this._loadingBrands = false
    return mappedBrands
  }

  public get loadingBrands(): boolean {
    return this._loadingBrands
  }
}
