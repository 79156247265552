import { CatalogNode } from './CatalogNode'

export enum ChildrenSelectionState {
  ALL_SELECTED,
  NONE_SELECTED,
  MIXED,
}

export enum CatalogNodeType {
  ROOT,
  CATEGORY,
  GROUP,
  PART_TYPE,
}

export type CatalogNodeClickHandler = (self: CatalogNode) => void
