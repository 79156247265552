import { ReactElement, useState } from 'react'
import { SimpleModal } from 'src/ui-components'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import styled from 'styled-components'
import { RadioGroup } from 'src/ui-components/forms/RadioGroup'
import { SpecificConditionI } from 'src/store/models/SpecificConditions'
import { observer } from 'mobx-react-lite'
import { IdValuePair } from 'src/store/models/KeyValuePair'

export const SpecificConditionsModal = observer((): ReactElement => {
  const { specificConditionQuestions: questions } = useSpecificConditionStore()
  const { areAllQuestionsAnswered, findQuestion } = useSpecificConditionStore()
  const { processQuestions } = useSpecificConditionStore()
  const [enableButton, setEnableButton] = useState(areAllQuestionsAnswered())
  const { showFitmentConditions, fitmentConditionsDialogOptions } =
    useUiStateStore()

  const handleGo = () => {
    processQuestions()
    fitmentConditionsDialogOptions.proceed()
  }

  const handleCancel = () => {
    fitmentConditionsDialogOptions.cancel()
  }

  const onAnswerEvent = (
    question: SpecificConditionI,
    answer: IdValuePair<string>
  ): void => {
    findQuestion(question).choice = answer
    setEnableButton(areAllQuestionsAnswered())
  }

  return (
    <SimpleModal
      title="fitmentConditions"
      primaryActionText="go"
      secondaryActionText="back"
      onPrimaryAction={handleGo}
      onSecondaryAction={handleCancel}
      disablePrimaryButton={!enableButton}
      show={showFitmentConditions && questions.length > 0}
    >
      <Wrapper>
        {questions.map((question) => {
          return (
            <div key={question.id}>
              <QuestionAnswersWrapper>
                <QuestionWrapper>{question.value}</QuestionWrapper>
                <AnswersWrapper>
                  <RadioGroup
                    id={`${question.id} _ ${question.value}`}
                    name={question.value}
                    options={question.choices}
                    initialValue={question.choice?.id}
                    onChange={(selected: IdValuePair<string>): void => {
                      onAnswerEvent(question, selected)
                    }}
                  />
                </AnswersWrapper>
              </QuestionAnswersWrapper>
            </div>
          )
        })}
      </Wrapper>
    </SimpleModal>
  )
})

const Wrapper = styled.div`
  padding-top: 1rem;
`

const QuestionAnswersWrapper = styled.div`
  padding-bottom: 2rem;
`

const QuestionWrapper = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #050505;
  mix-blend-mode: normal;
  opacity: 0.9;
`
const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #050505;
  padding-top: 0.5rem;
`
