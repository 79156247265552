import { CatalogNode } from './CatalogNode'
import { CatalogNodeClickHandler, CatalogNodeType } from './interfaces'

export class CatalogTreeParser {
  private readonly categoryClickHandler: CatalogNodeClickHandler

  private readonly groupClickHandler: CatalogNodeClickHandler

  private readonly partTypeClickHandler: CatalogNodeClickHandler

  constructor(
    categoryClickHandler: CatalogNodeClickHandler,
    groupClickHandler: CatalogNodeClickHandler,
    partTypeClickHandler: CatalogNodeClickHandler
  ) {
    this.categoryClickHandler = categoryClickHandler
    this.groupClickHandler = groupClickHandler
    this.partTypeClickHandler = partTypeClickHandler
  }

  public parse(rootJSON: string): CatalogNode | null {
    const restoredRootData = JSON.parse(rootJSON)

    if (!restoredRootData.children) {
      return null
    }

    const root = new CatalogNode({
      id: restoredRootData.id,
      value: restoredRootData.value,
      type: CatalogNodeType.ROOT,
    })

    for (const category of restoredRootData.children) {
      const c = new CatalogNode({
        ...category,
        children: [],
        clickHandler: this.categoryClickHandler,
      })
      root.addChild(c)
      for (const group of category.children) {
        const g = new CatalogNode({
          ...group,
          children: [],
          clickHandler: this.groupClickHandler,
        })
        c.addChild(g)
        for (const part of group.children) {
          const p = new CatalogNode({
            ...part,
            children: [],
            clickHandler: this.partTypeClickHandler,
          })
          g.addChild(p)
        }
      }
    }
    return root
  }
}
