import { Translate2 } from 'src/i18n/translate2'
import { SortOrderItem } from 'src/store/models/SearchModels'

export function getSortOptions(): SortOrderItem[] {
  return [
    {
      label: Translate2('recommended'),
      property: 'Recommended',
      dir: 'desc',
    },
    {
      label: Translate2('availability'),
      property: 'Availability',
      dir: 'desc',
    },
    { label: Translate2('costLowHigh'), property: 'Cost', dir: 'asc' },
    { label: Translate2('costHighLow'), property: 'Cost', dir: 'desc' },
    { label: Translate2('listLowHigh'), property: 'List', dir: 'asc' },
    { label: Translate2('listHighLow'), property: 'List', dir: 'desc' },
    { label: Translate2('brandAZ'), property: 'Brand', dir: 'asc' },
    { label: Translate2('brandZA'), property: 'Brand', dir: 'desc' },
    {
      label: Translate2('partNumberAZ'),
      property: 'Part Number',
      dir: 'asc',
    },
    {
      label: Translate2('partNumberZA'),
      property: 'Part Number',
      dir: 'desc',
    },
    {
      label: Translate2('partDescriptionAZ'),
      property: 'Part Description',
      dir: 'asc',
    },
    {
      label: Translate2('partDescriptionZA'),
      property: 'Part Description',
      dir: 'desc',
    },
  ]
}

export function getCrossReferenceSortOptions(): SortOrderItem[] {
  return [
    {
      label: Translate2('partNumberAZ'),
      property: 'partNumber',
      dir: 'asc',
    },
    {
      label: Translate2('partNumberZA'),
      property: 'partNumber',
      dir: 'desc',
    },
    {
      label: Translate2('mfrAZ'),
      property: 'manufacturer',
      dir: 'asc',
    },
    {
      label: Translate2('mfrZA'),
      property: 'manufacturer',
      dir: 'desc',
    },
    {
      label: Translate2('descriptionAZ'),
      property: 'description',
      dir: 'asc',
    },
    {
      label: Translate2('descriptionZA'),
      property: 'description',
      dir: 'desc',
    },
  ]
}
