import ArrowBack from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import { ReactElement, useRef } from 'react'
import { useClickOutsideDetector } from 'src/hooks'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import { Button, TextInput } from 'src/ui-components'
import styled from 'styled-components'

const CategoryNameModal = observer((): ReactElement => {
  const {
    categoryNameModalType,
    categoryNameModal,
    setCategoryNameModal,
    onNameCategory,
    setCategoryNameField,
    categoryNameField,
    categoryToRename,
  } = useListsStore()

  const show = categoryNameModal
  const ref = useRef<HTMLInputElement>()

  const onBack = () => {
    setCategoryNameModal(false)
  }

  const handleRenameConfirmed = () => {
    if (categoryNameField.length <= 30) {
      if (categoryNameModalType === 'rename') {
        onNameCategory(Number(categoryToRename?.id))
      } else {
        onNameCategory()
      }
    } else {
      StoreInstances.uiStore.displayErrorNotification(
        'Category name exceeds 30 characters'
      )
    }
  }
  useClickOutsideDetector([ref], show, () => setCategoryNameModal(false))
  const isTablet = window.innerWidth <= breakpoints.tablet

  return (
    <ResponsiveDrawer
      anchor="bottom"
      open={show}
      onClose={() => setCategoryNameModal(false)}
      PaperProps={{
        square: false,
      }}
      style={{
        width: '800px',
      }}
    >
      <Container ref={ref}>
        <HeaderContainer>
          <TitleHeader>
            {categoryNameModalType === 'new' ? (
              <I18n name="newCategory" />
            ) : categoryNameModalType === 'rename' ? (
              <div>
                <StyledBackIcon onClick={onBack} />
                <I18n name="renameCategory" />
              </div>
            ) : null}

            <StyledCloseIcon onClick={() => setCategoryNameModal(false)} />
          </TitleHeader>
          <ConfirmationTextContainer>
            <TextInput
              label={isTablet ? '' : 'name'}
              value={categoryNameField}
              onChange={(value) => setCategoryNameField(value)}
              type="text"
              placeholder=""
              noMargin
              width="100%"
            />
          </ConfirmationTextContainer>
        </HeaderContainer>

        <ButtonContainer>
          <CancelButton
            onClick={onBack}
            variant="secondary"
            size="medium"
            text="cancel"
          />
          <CreateCategoryButton
            onClick={handleRenameConfirmed}
            variant="primary"
            size="medium"
            text={
              categoryNameModalType === 'new'
                ? Translate('createCategory')
                : Translate('saveChanges')
            }
            disabled={categoryNameField.length === 0}
          />
        </ButtonContainer>
      </Container>
    </ResponsiveDrawer>
  )
})

export default CategoryNameModal

const ConfirmationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 0px 0px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  margin: 32px 0px 36px;
  padding: 24px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin-top: 47px;
  }
`

const ResponsiveDrawer = styled(Drawer)``

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 5.5px;
`
const StyledBackIcon = styled(ArrowBack)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

const HeaderContainer = styled.div`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    padding: 24px 24px 0px;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: ${defaultTheme.colors.primaryText};

  span {
    margin-left: 7px;
  }

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin-left: 0px;
    font-size: 24px;
  }
`
interface ContainerProps {
  height?: string
  width?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0px;
    width: 500px;
    min-height: 228px;
    padding: 24px 32px;
  }
`
const CancelButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    display: none;
  }
`

const CreateCategoryButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
    height: 48px;
  }
`
