import styled from 'styled-components'
import { ReactElement } from 'react'
import { Search } from '@mui/icons-material'
import { Spinner } from 'react-bootstrap'
import { defaultTheme } from '../../theme'

interface SearchIconBtnProps {
  onClick: () => void
  disabled: boolean
  loading: boolean
  variant?: 'normal' | 'attached'
}

export const SearchIconBtn = (props: SearchIconBtnProps): ReactElement => {
  const { loading } = props
  return (
    <SearchIcon {...props}>
      {loading ? (
        <Spinner
          animation="border"
          style={{ width: '1.3rem', height: '1.3rem' }}
        />
      ) : (
        <Search />
      )}
    </SearchIcon>
  )
}

SearchIconBtn.defaultProps = {
  variant: 'normal',
}

type SearchIconProps = Pick<SearchIconBtnProps, 'disabled' | 'variant'>

const SearchIcon = styled.div<SearchIconProps>`
  background-color: ${(p) =>
    p.disabled
      ? defaultTheme.colors.disabledButton
      : defaultTheme.colors.primary};
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
  width: 50px;
  height: 50px;
  border-radius: ${(p) => (p.variant === 'attached' ? '0 4px 4px 0' : '4px')};
  display: flex;
  justify-content: center;
  align-items: center;
`
