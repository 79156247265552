import { ArrowBack, ChevronRight } from '@mui/icons-material'
import { Box, Grid, Radio } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect } from 'react'
import { I18n, Translate } from 'src/i18n'
import { PartsCatalog, VisibleStep } from 'src/store/partsCatalog/PartsCatalog'
import { defaultTheme } from 'src/theme'
import { Button } from 'src/ui-components'
import styled from 'styled-components'
import { CatalogNodeType } from 'src/store/partsCatalog/interfaces'
import { CheckboxSelections } from './CheckboxSelections'
import { Mode, SelectionsInterface } from '../interfaces'
import { CatalogNode } from 'src/store/partsCatalog/CatalogNode.ts'

export const MobileLaborLookupSteps = observer(
  (
    props: SelectionsInterface & {
      partsCatalog: PartsCatalog
      step: VisibleStep
      loading: boolean
      setStep: (arg: VisibleStep) => void
      onClick: () => void
    }
  ): ReactElement => {
    const { partsCatalog, mode, setStep, step, loading, onClick } = props

    const categoriesSelected = partsCatalog.root.getAllDescendants(
      (node) => node.type === CatalogNodeType.CATEGORY && node.selected
    )
    const groupsSelected = partsCatalog.root.getAllDescendants(
      (node) => node.type === CatalogNodeType.GROUP && node.selected
    )
    const partTypesSelected = partsCatalog.root.getAllDescendants(
      (node) => node.type === CatalogNodeType.PART_TYPE && node.selected
    )

    const categoryGroupsFiltered: CatalogNode[] = []

    partsCatalog.categoryGroups()?.forEach((group) => {
      group
        .getChildren()
        .forEach((item) =>
          item.selected ? categoryGroupsFiltered.push(item) : null
        )
    })

    useEffect(() => {
      if (step === VisibleStep.START) {
        partsCatalog.root.unselectAllDescendantsRecursively() // If going back to parts catalog screen deselect all
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    const handleMenuClick = (state: VisibleStep, qty: number) => {
      if (qty > 0) {
        setStep(state)
      }
    }

    const labels = {
      category: Translate('category').toLowerCase(),
      categories: Translate('categories').toLowerCase(),
      groups: Translate('groups').toLowerCase(),
      group: Translate('group').toLowerCase(),
      partTypes: Translate('description').toLowerCase(),
      partType: Translate('description').toLowerCase(),
    } // Removes warning on selecting an item

    function handleStepSelectedQtyLabel(
      visibleStep: VisibleStep,
      n: number
    ): string {
      if (n < 1) return ''
      switch (visibleStep) {
        case VisibleStep.CATEGORY:
          return n > 1 ? labels.categories : labels.category

        case VisibleStep.GROUP:
          return n > 1 ? labels.groups : labels.group

        case VisibleStep.PART_TYPE:
          return n > 1 ? labels.partTypes : labels.partType
        default:
          return ''
      }
    }

    const categoryGroups = partsCatalog.categoryGroups()
    const { groupPartTypes } = partsCatalog
    const visibleStep = partsCatalog.getVisibleStep()

    return (
      <Wrapper>
        <PartsCatalogWrapper>
          {(mode === Mode.ADD_SELECTIONS ||
            mode === Mode.UPDATE_SELECTIONS ||
            mode === Mode.DISPLAY_SELECTIONS) && (
            <>
              <TitleWrapper>
                {step === VisibleStep.START ? null : (
                  <Box display="flex" alignItems="center" width="100%">
                    {step !== VisibleStep.CATEGORY && (
                      <StyledArrowBack
                        onClick={() => setStep(VisibleStep.CATEGORY)}
                      />
                    )}

                    {step === VisibleStep.CATEGORY ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        {categoriesSelected.length > 0 ? (
                          <Title className="selected">
                            {categoriesSelected.length}{' '}
                            {handleStepSelectedQtyLabel(
                              VisibleStep.CATEGORY,
                              categoriesSelected.length
                            )}{' '}
                            selected
                          </Title>
                        ) : (
                          <Title>
                            <I18n name="selectCategory" />
                          </Title>
                        )}
                      </Box>
                    ) : step === VisibleStep.GROUP ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        {groupsSelected.length > 0 ? (
                          <Title className="selected">
                            {groupsSelected.length}{' '}
                            {handleStepSelectedQtyLabel(
                              VisibleStep.GROUP,
                              groupsSelected.length
                            )}{' '}
                            selected
                          </Title>
                        ) : (
                          <Title>
                            <I18n name="selectGroup" />
                          </Title>
                        )}
                      </Box>
                    ) : step === VisibleStep.PART_TYPE ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        {partTypesSelected.length > 0 ? (
                          <Title className="selected">
                            {partTypesSelected.length}{' '}
                            {handleStepSelectedQtyLabel(
                              VisibleStep.PART_TYPE,
                              partTypesSelected.length
                            )}{' '}
                            selected
                          </Title>
                        ) : (
                          <Title>
                            <I18n name="selectDescription" />
                          </Title>
                        )}
                      </Box>
                    ) : null}
                  </Box>
                )}
              </TitleWrapper>
              <Grid container spacing={2}>
                {step === VisibleStep.START ? (
                  <Box display="flex" flexDirection="column" width="100%">
                    <MenuItemWrapper
                      noBorder
                      onClick={() => handleMenuClick(VisibleStep.CATEGORY, 1)}
                    >
                      <MenuRadioNameWrapper>
                        <Radio />
                        <MenuItemText disabled={false}>
                          <I18n name="selectCategory" />
                        </MenuItemText>
                      </MenuRadioNameWrapper>
                      <StyledChevronRight />
                    </MenuItemWrapper>

                    <MenuItemWrapper
                      onClick={() =>
                        handleMenuClick(
                          VisibleStep.GROUP,
                          categoriesSelected?.length
                        )
                      }
                    >
                      <MenuRadioNameWrapper>
                        <Radio disabled={categoriesSelected?.length < 1} />
                        <MenuItemText disabled={categoriesSelected?.length < 1}>
                          <I18n name="selectGroup" />
                        </MenuItemText>
                      </MenuRadioNameWrapper>
                      <StyledChevronRight
                        disabled={categoriesSelected?.length < 1}
                      />
                    </MenuItemWrapper>
                    <MenuItemWrapper
                      onClick={() =>
                        handleMenuClick(
                          VisibleStep.PART_TYPE,
                          groupsSelected?.length
                        )
                      }
                    >
                      <MenuRadioNameWrapper>
                        <Radio disabled={groupsSelected?.length < 1} />

                        <MenuItemText disabled={groupsSelected?.length < 1}>
                          <I18n name="selectPartTypes" />
                        </MenuItemText>
                      </MenuRadioNameWrapper>
                      <StyledChevronRight
                        disabled={groupsSelected?.length < 1}
                      />
                    </MenuItemWrapper>
                    <MenuItemWrapper padding="20px 15px 20px 20px">
                      <Button
                        disabled={partTypesSelected.length < 1}
                        text="Show results"
                        loading={loading}
                        size="large"
                        minWidth="100% !important"
                        onClick={() => onClick()}
                      />
                    </MenuItemWrapper>
                  </Box>
                ) : step === VisibleStep.CATEGORY ? (
                  <Grid item xs={12} paddingBottom="94px">
                    <CheckboxSelections
                      loading={partsCatalog.loadingCategories}
                      title=""
                      type={CatalogNodeType.CATEGORY}
                      list={partsCatalog.categories}
                      partsCatalogStore={partsCatalog}
                    />
                  </Grid>
                ) : step === VisibleStep.GROUP ? (
                  <Grid item xs={12} paddingBottom="94px">
                    <CheckboxSelections
                      loading={
                        visibleStep <= VisibleStep.CATEGORY ||
                        partsCatalog.loadingCategoryGroups
                      }
                      title=""
                      type={CatalogNodeType.GROUP}
                      groups={categoryGroups}
                      partsCatalogStore={partsCatalog}
                    />
                  </Grid>
                ) : step === VisibleStep.PART_TYPE ? (
                  <Grid item xs={12} paddingBottom="94px">
                    <CheckboxSelections
                      loading={
                        visibleStep <= VisibleStep.GROUP ||
                        partsCatalog.loadingGroupPartTypes
                      }
                      title=""
                      type={CatalogNodeType.PART_TYPE}
                      groups={groupPartTypes}
                      partsCatalogStore={partsCatalog}
                    />
                  </Grid>
                ) : null}
              </Grid>
              {step === VisibleStep.CATEGORY ? (
                <Box
                  style={{
                    position: 'fixed',
                    height: 94,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    disabled={categoriesSelected.length < 1}
                    text="nextSelectGroup"
                    size="large"
                    minWidth="342px !important"
                    onClick={() => setStep(2)}
                  />
                </Box>
              ) : step === VisibleStep.GROUP ? (
                <Box
                  style={{
                    position: 'fixed',
                    height: 94,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    disabled={groupsSelected.length < 1}
                    text="nextSelectDescription"
                    size="large"
                    minWidth="342px !important"
                    onClick={() => setStep(3)}
                  />
                </Box>
              ) : step === VisibleStep.PART_TYPE ? (
                <Box
                  style={{
                    position: 'fixed',
                    height: 94,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    disabled={partTypesSelected.length < 1}
                    text="Get labor data"
                    size="large"
                    loading={loading}
                    minWidth="342px !important"
                    onClick={() => onClick()}
                  />
                </Box>
              ) : null}
            </>
          )}
        </PartsCatalogWrapper>
      </Wrapper>
    )
  }
)

interface ChevronRightProps {
  disabled?: boolean
}

const StyledChevronRight = styled(ChevronRight)<ChevronRightProps>`
  color: ${(p) =>
    p.disabled
      ? defaultTheme.colors.disabledButtonText
      : defaultTheme.colors.primaryText};
`

const Wrapper = styled.section`
  display: flex;
  margin-top: 0;
  font-family: Roboto, serif;
`
const PartsCatalogWrapper = styled.div`
  background: ${defaultTheme.colors.componentsBackground};
  flex-grow: 1;
`

const StyledArrowBack = styled(ArrowBack)`
  margin-right: 20px;
  font-size: 20px;
  color: ${defaultTheme.colors.almostBlack};
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
`
const Title = styled.h3`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  &.selected {
    font-size: 22px;
  }
`
interface MenuItemTextProps {
  disabled: boolean
}
const MenuItemText = styled.span<MenuItemTextProps>`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${(p) =>
    p.disabled
      ? defaultTheme.colors.columnBorder
      : defaultTheme.colors.primaryText};
`

interface MenuItemWrapperProps {
  noBorder?: boolean
  padding?: string
}
const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  border-top: ${(p) =>
    !p.noBorder ? `1px solid ${defaultTheme.colors.borderTable}` : null};
  padding: ${(p) => p.padding || '20px'};
`

const MenuRadioNameWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
