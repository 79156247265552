import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { ListGroupPartsTable } from './ListGroupPartsTable'
import { ListGroupsHeader } from './ListGroupsHeader'
import { ListGroupsTabs } from './ListGroupsTabs'
import { MultiSearchTable } from './MultiSearchTable'
import { breakpoints } from 'src/theme/breakpoints'
import { useListsStore } from 'src/store/lists/ListsStore'
import { I18n } from 'src/i18n'
export const RightPanel = observer((): ReactElement => {
  const listStore = useListsStore()
  const { isInSearchMode, resetListSearchResults, selectedList } = listStore

  useEffect(() => {
    return resetListSearchResults()
  }, [resetListSearchResults])

  return (
    <MainContainer>
      {selectedList === undefined ? (
        <NoResultContainer>
          <NoResultText>
            <I18n name="noListResultsFound" />
          </NoResultText>
        </NoResultContainer>
      ) : (
        <Container>
          <ListGroupsHeader />
          {isInSearchMode() ? (
            <MultiSearchTable />
          ) : (
            <>
              <ListGroupsTabs />
              <ListGroupPartsTable />
            </>
          )}
        </Container>
      )}
    </MainContainer>
  )
})

const Container = styled.div`
  background: white;
  width: 100%;
  margin: 20px;
  margin-left: 16px;
  flex-direction: column;
  height: fit-content;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    padding: 5px;
  }
`
const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-color: white;
  justify-content: center;
  margin-left: 16px;
  margin: 20px;
`
const MainContainer = styled.div`
  width: 75%;
  @media (max-width: ${breakpoints.tabletXLarge}px) {
    width: 100%;
  }
`

const NoResultText = styled.div`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 10px;
`
