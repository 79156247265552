import { ReactElement } from 'react'
import BaseDrawer from '../../../../ui-components/modals/BaseDrawer'
import { I18n, Translate } from 'src/i18n'
import styled from 'styled-components'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { observer } from 'mobx-react-lite'
import { toLocaleDateStringCustomFormat } from 'src/helpers/date.ts'
import { toDate } from 'date-fns-tz'

const DDSUnavialbleDrawer = observer((): ReactElement => {
  const { ddsUnavailableDrawerData, closeDDSUnavailableDrawer } =
    StoreInstances.uiStore

  const { message, orderNumber, orderDate } = ddsUnavailableDrawerData

  const createdOnDate = orderDate
    ? toLocaleDateStringCustomFormat(toDate(orderDate), 'd LLL Y pp z')
    : ''

  const drawerBody = (
    <>
      <div>
        <Text>
          <b>
            <I18n name={'orderNumber'} />:
          </b>
          {' #'}
          {orderNumber}
        </Text>
      </div>
      <div>
        <Text>
          <I18n name={'orderedOn'} textReplacement={[createdOnDate]} />
        </Text>
      </div>
      <hr />
      <Text>
        <I18n name={message} />
      </Text>
    </>
  )

  return (
    <BaseDrawer
      open={!!ddsUnavailableDrawerData?.orderNumber}
      width="auto"
      title={Translate('deliveryStatusUnavailable')}
      body={drawerBody}
      onClose={closeDDSUnavailableDrawer}
    />
  )
})

const Text = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  //styleName: P Small / Default;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`

export default DDSUnavialbleDrawer
