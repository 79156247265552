const decodingMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  P: 7,
  R: 9,
  S: 2,
  T: 3,
  U: 4,
  V: 5,
  W: 6,
  X: 7,
  Y: 8,
  Z: 9,
}

const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2]

export default class VinValidator {
  static isValid = (vin: string): boolean => {
    if (vin.length !== 17) {
      return false
    }

    const checkDigitChar = vin.charAt(8)

    let sum = 0
    for (let i = 0; i < vin.length; i += 1) {
      const c = vin.charAt(i)
      const weight = weights[i]
      const value = decodingMap[c]
      sum += weight * value
    }

    const remainder = sum % 11

    if (remainder === 10 && checkDigitChar === 'X') {
      return true
    }

    return remainder === decodingMap[checkDigitChar]
  }

  static sanitize = (vin: string): string => {
    let sanitized = vin
    if (sanitized.length > 17) {
      sanitized = sanitized.substring(0, 17)
    }
    sanitized = sanitized.replace(/[^a-zA-Z0-9]/g, '')
    sanitized = sanitized.toUpperCase()
    return sanitized
  }
}
