import { Drawer, PaperProps } from '@mui/material'
import React, {
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from 'react'
import { I18n } from 'src/i18n'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { breakpoints } from 'src/theme/breakpoints'
import { zIndex } from 'src/theme/zIndex'
import { defaultTheme } from 'src/theme'
import { useClickOutsideDetector } from 'src/hooks'
import { isMobileOnly } from 'react-device-detect'

interface BaseDrawerProps {
  open: boolean
  title: string | ReactElement
  header?: ReactNode
  body: ReactNode
  footer?: ReactNode
  paddingX?: string
  paddingY?: string
  showHeaderDivider?: boolean
  showFooterDivider?: boolean
  width?: string
  minWidth?: string
  onClose: () => void
  height?: string
  clickOutsideCustomRefs?: Array<React.RefObject<HTMLElement>>
  paperProps?: Partial<PaperProps>
  getPrintRef?: (ref: RefObject<HTMLDivElement>) => void
  scrollable?: boolean
}

const BaseDrawer = (props: BaseDrawerProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const {
    open,
    title,
    header,
    body,
    footer,
    paddingX = '0',
    paddingY = '0',
    width = 'auto',
    minWidth = 'auto',
    showHeaderDivider,
    showFooterDivider,
    onClose,
    height = 'auto',
    clickOutsideCustomRefs = [],
    paperProps,
    getPrintRef,
    scrollable = false,
  } = props

  useClickOutsideDetector([ref, ...clickOutsideCustomRefs], open, onClose)

  useEffect(() => {
    if (getPrintRef) {
      getPrintRef(ref)
    }
  }, [getPrintRef])

  return (
    <Drawer
      open={open}
      anchor={window.innerWidth < breakpoints.mobileLarge ? 'bottom' : 'right'}
      PaperProps={paperProps}
    >
      <Wrapper
        width={width}
        minWidth={minWidth}
        ref={ref}
        height={height}
        scrollable={scrollable}
      >
        <Title paddingX={paddingX} paddingY={paddingY}>
          <TitleHeader>
            {title && typeof title === 'string' && (
              <>
                <I18n name={title} />
                <StyledCloseIcon onClick={onClose} />
              </>
            )}
            {title && typeof title === 'object' && title}
          </TitleHeader>
          {header}
        </Title>
        {showHeaderDivider && <Divider />}

        <BodyWrapper
          paddingX={paddingX}
          paddingY={paddingY}
          scrollable={scrollable}
        >
          {body}
        </BodyWrapper>

        <FooterWrapper>
          {showFooterDivider && <Divider />}
          <PaddingWrapper paddingX={paddingX} paddingY={paddingY}>
            {footer}
          </PaddingWrapper>
        </FooterWrapper>
      </Wrapper>
    </Drawer>
  )
}

BaseDrawer.defaultProps = {
  paddingX: '2rem',
  paddingY: '1rem',
  titlePaddingX: '2rem',
  titlePaddingY: '1rem',
  showHeaderDivider: false,
  showFooterDivider: false,
  width: 'auto',
  minWidth: window.innerWidth < breakpoints.mobileLarge ? '100%' : '500px',
  header: null,
  footer: null,
  height: '100%',
  clickOutsideCustomRefs: [],
  paperProps: {
    sx: {
      zIndex: zIndex.modal,
    },
  },
}

export default BaseDrawer

interface WrapperProps {
  width: string
  minWidth: string
  height: string
  scrollable: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  overflow: ${(props) => (props.scrollable ? 'auto' : 'visible')};
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #d3d2cf;
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`

interface PaddingProps {
  paddingX: string
  paddingY: string
}

const PaddingWrapper = styled.div<PaddingProps>`
  padding-left: ${(props) => props.paddingX};
  padding-right: ${(props) => props.paddingX};
  padding-top: ${(props) => props.paddingY};
  padding-bottom: ${(props) => props.paddingY};
`

const Title = styled(PaddingWrapper)`
  font-family: Oswald, serif;
  font-style: normal;
  font-size: ${() => (isMobileOnly ? '20px' : '28px')};
  line-height: 48px;
  /* identical to box height, or 171% */
  /* Almost Black */
  color: ${defaultTheme.colors.almostBlack};
`

interface BodyWrapperProps {
  scrollable?: boolean
}

const BodyWrapper = styled(PaddingWrapper)<BodyWrapperProps>`
  height: auto;
  ${(props) =>
    !props.scrollable &&
    `
    @media not print {
      overflow-y: auto;
    }
  `}
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`

const FooterWrapper = styled.div`
  margin-top: auto;
  @media print {
    display: none;
  }
`
