/**
 * enum to maintain the keys used by to the app to store data in local/session storage.
 */
export enum StorageKeys {
  /**
   * The key used to store the origin of the auto login request.
   * Used to redirect the user back on logout.
   * Need to cleat the storage on logout.
   */
  AUTO_LOGIN_ORIGIN_KEY = 'auto-login-origin',

  /**
   * The key used to store whether the user is in impersonating state.
   * Need to cleat the storage on logout.
   */
  AUTO_LOGIN_IMPERSONATION_KEY = 'auto-login-impersonated',

  SALESPAD_KEY = 'is-salespad-user',

  CXMLDETAILS_KEY = 'cxmlDetails',
  CONDENSEDVIEW_KEY = 'isCondensedView',
}

export const VITE_EXPERIAN_AUTOCHECK_BASE_URL =
  'https://www.autocheck.com/vehiclehistory/?siteID=7500'

export const SENTRY_DSN =
  'https://8508dada5dc4613c5e779662a4077beb@o4505784096391168.ingest.sentry.io/4505786669924352'

export const LISTS_IMPORT_RETRIES_LIMIT = 600000 // 10 mins in milliseconds
export const LISTS_IMPORT_RETRIES_INTERVAL = 2500 // 2.5 seconds in milliseconds
export const GENERAL_RETRIES_LIMIT = 600000 // 10 mins in milliseconds
export const GENERAL_RETRIES_INTERVAL = 2500 // 2.5 seconds in milliseconds

/**
 * Batch size to pass to the sellnetwork.
 */
export const SELL_NETWORK_BATCH_SIZE = 30
