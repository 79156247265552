import {
  LostSalesReasons,
  LostSalesReport,
  LostSalesResponse,
} from 'src/store/models/LostSalesModel'
import { BaseServiceProvider } from './BaseServiceProvider'

class LostSaleServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'lost-sale', '1.1', '')
  }

  getLostSalesReasons = async (): Promise<LostSalesReasons[]> => {
    const resp = await this.get<LostSalesReasons[]>('reasons')
    return resp.data
  }

  sendLostSaleReport = async (reqData: LostSalesReport): Promise<number> => {
    const resp = await this.post<LostSalesResponse>('lostSale', reqData)
    return resp.status
  }
}

export default new LostSaleServiceProvider()
