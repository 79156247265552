import { ReactElement } from 'react'
import { Translate } from 'src/i18n'

interface INotificationMessage {
  message: string
  messageParams?: Array<string | number>
}
//Notistack takes an argument for every notification as a string ot Html content, It is not accepting the Translate as a function and resulting an error.
//This component helps to Translate notification message.

const NotificationMessage = (props: INotificationMessage): ReactElement => {
  return <>{Translate(props.message, props.messageParams)}</>
}

export default NotificationMessage
