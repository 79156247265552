import { Close } from '@mui/icons-material'
import React, { ReactElement, useState } from 'react'
import {
  MultiPartInquiry,
  MultiPartLinesData,
} from 'src/features/search/AllProductsSearch/interfaces'
import { I18n } from 'src/i18n'
import styled from 'styled-components'
import { Button } from '../forms'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import { parse } from 'papaparse'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

interface CSVFileParserProps {
  setImportButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setImportMultiPartsSearch: React.Dispatch<
    React.SetStateAction<Array<MultiPartInquiry>>
  >
  setMultiPartsLinesData: React.Dispatch<
    React.SetStateAction<MultiPartLinesData>
  >
}
const validImportListHeaders = ['MFR', 'Part Number', 'Qty', 'Comments']

export const CSVFileParser = (props: CSVFileParserProps): ReactElement => {
  const {
    setImportButtonDisabled,
    setImportMultiPartsSearch,
    setMultiPartsLinesData,
  } = props
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const resetFileSelection = () => {
    setImportButtonDisabled(true)
    setSelectedFile(null)
    setImportMultiPartsSearch([])
    setMultiPartsLinesData({})
  }

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) {
      return
    }
    const filesArray: File[] = Array.from(e.target.files)
    const fileSelection = filesArray[0]
    if (fileSelection.size > 0) {
      setImportButtonDisabled(false)
      setSelectedFile(fileSelection)
      // Using string[] instead of MultiPartInquiryImport as we are using header: false.
      parse<string[]>(fileSelection, {
        header: false,
        complete: (results) => {
          if (
            validImportListHeaders.toString() === results?.data[0]?.toString()
          ) {
            const totalNonEmptyData = results?.data
              .slice(1)
              .filter((data) => data.length === 4)
            const parsedData = totalNonEmptyData
              .filter((partData) => partData[1] !== '')
              .map((nonEmptyPartsData: string[]): MultiPartInquiry => {
                return {
                  lineCode: nonEmptyPartsData[0],
                  partNumber: nonEmptyPartsData[1],
                  stockQty:
                    nonEmptyPartsData[2] === ''
                      ? 1
                      : Number(nonEmptyPartsData[2]),
                  comment: nonEmptyPartsData[3],
                }
              })

            setImportMultiPartsSearch(parsedData as Array<MultiPartInquiry>)
            const errorLines = totalNonEmptyData.length - parsedData.length
            setMultiPartsLinesData({
              errorLines,
              successLines: parsedData?.length,
            })
          } else {
            StoreInstances.uiStore.displayErrorNotification(
              'pleaseUploadValidCsvFile'
            )
          }
        },
      })
    }
  }

  const fileSizeConversion = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 B'

    const kiloByte = 1000
    const decimal = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i: number = Math.floor(Math.log(bytes) / Math.log(kiloByte))

    return `${parseFloat((bytes / kiloByte ** i).toFixed(decimal))} ${sizes[i]}`
  }

  return (
    <Container>
      {selectedFile === null ? (
        <BrowseFileContainer>
          <FileUploadIcon style={{ color: 'lightgrey', scale: '2' }} />
          <Button
            text="browseFile"
            variant="primary"
            size="medium"
            style={{ marginTop: '25px' }}
          />
          <I18n
            name="dragAndDrop"
            style={{ fontSize: '16px', marginTop: '15px' }}
          />
          <BrowseFileFieldWrapper>
            <BrowseFileField
              type="file"
              onChange={handleFileSelected}
              title=""
              value=""
              accept=".csv"
            />
          </BrowseFileFieldWrapper>
        </BrowseFileContainer>
      ) : (
        <FileSelectedContainer>
          {selectedFile ? (
            <SelectedFileTitle>{`${selectedFile.name} (${fileSizeConversion(
              selectedFile.size
            )})`}</SelectedFileTitle>
          ) : null}
          <Close
            style={{ color: '#0F172A', cursor: 'pointer' }}
            onClick={resetFileSelection}
          />
        </FileSelectedContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
`

const BrowseFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const BrowseFileFieldWrapper = styled.div`
  height: 130px;
  cursor: pointer;
  position: absolute;
  left: 35px;
  right: 55px;
`

export const BrowseFileField = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const FileSelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const SelectedFileTitle = styled.span`
  font-size: 14px;
  font-family: Roboto, serif;
  color: black;
  font-weight: bold;
  display: block;
  text-align: left;
`
