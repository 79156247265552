import { VehicleShort } from 'src/api/cart/interfaces'
import type { Vehicle } from 'src/store/models/Vehicles'

export const MISCELLANEOUS_CART_ID = 999999

const miscellaneousVehicle: Vehicle = {
  id: MISCELLANEOUS_CART_ID.toString(),
  year: {
    value: '',
    id: MISCELLANEOUS_CART_ID,
  },
  make: {
    value: 'MISCELLANEOUS',
    id: MISCELLANEOUS_CART_ID,
  },
  modelType: {
    id: MISCELLANEOUS_CART_ID,
    value: 'CAR',
  },
  model: {
    id: MISCELLANEOUS_CART_ID,
    value: '',
  },
  engine: {
    id: MISCELLANEOUS_CART_ID,
    value: '',
  },
  vin: null,
  plate: null,
  specificConditions: [],
}

export const isMiscellaneousVehicle = (vehicle: Vehicle): boolean => {
  return (
    vehicle?.engine?.id === MISCELLANEOUS_CART_ID ||
    vehicle?.make?.value === 'MISCELLANEOUS'
  )
}
export const isMiscellaneousVehicleByMake = (
  vehicle: VehicleShort
): boolean => {
  return vehicle?.make === 'MISCELLANEOUS'
}

export default miscellaneousVehicle
