import { ReactElement } from 'react'
import { CSVDownloadFile } from './RightPanel/ListGroupsHeader'
import styled from 'styled-components'
import { Translate } from 'src/i18n'

interface PrintListProps {
  title: string
  data: CSVDownloadFile[]
}

const PrintList = (props: PrintListProps): ReactElement => {
  const { title, data } = props

  const count = data?.length

  const counter = ` (${count} ${
    count === 1 ? Translate('item') : Translate('items')
  })`

  return (
    <Wrapper>
      <Title>
        {title}
        {counter}
      </Title>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{Translate('group')}</th>
              <th>{Translate('mfr')?.toUpperCase()}</th>
              <th>{Translate('partNumber')}</th>
              <th>{Translate('stockQty')}</th>
              <th>{Translate('comments')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((e, index) => (
              <tr key={`partlist_${e.Group}_${index}`}>
                <td>{e.Group}</td>
                <td>{e.MFR}</td>
                <td>{e['Part Number']}</td>
                <td>{e['Stocking Qty']}</td>
                <td>{e.Comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2rem;
`

const Title = styled.span`
  font-family: Oswald;
  font-size: 22px;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
`

export default PrintList
