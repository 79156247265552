import { ReactElement, useState } from 'react'
import { I18n } from 'src/i18n'
import { Select } from 'src/ui-components'
import styled from 'styled-components'
import { H6, Text } from './Common'
import { VehicleType } from 'src/store/search/CustomizeSearchStore'
import { prioritizePreference } from 'src/store/search/Utils'

interface Props {
  options: VehicleType[]
  onChange: (value: VehicleType) => void
}

const VehicleTypeDropdown = (props: Props): ReactElement => {
  const [options, setOptions] = useState([...props.options])

  const handleOnSelect = (selection) => {
    const sorted = [...options].sort((a, b) =>
      prioritizePreference(a, b, selection)
    )
    setOptions(sorted)
    props.onChange(selection)
  }

  return (
    <Wrapper>
      <FlexColumn>
        <H6>
          <I18n name="defaultVehicleType" />
        </H6>
        <Text>
          <I18n name="selectYourPreferredDefaultVehicleTypeWhenDoingAYMMESearch" />
        </Text>
      </FlexColumn>
      <SelectWrapper>
        <Select
          options={options}
          selectedOption={options?.[0]}
          onSelect={handleOnSelect}
          placement="auto-start"
        />
      </SelectWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const SelectWrapper = styled.div`
  width: 272px;
`

export default VehicleTypeDropdown
