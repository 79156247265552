import { ReactElement } from 'react'
import { SimpleModal } from 'src/ui-components'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import { Translate } from 'src/i18n'

export interface AddVehicleForSearchModalI {
  onAddVehicle: () => void
  onSkipVehicle: () => void
}

const AddVehicleForSearchModal = createNiceModal(
  (props: AddVehicleForSearchModalI): ReactElement => {
    const { onAddVehicle, onSkipVehicle } = props
    const modal = useModal()
    const modalTitle = Translate('getThePerfectResults')?.toUpperCase()

    return (
      <SimpleModal
        show={modal.visible}
        title={modalTitle}
        primaryActionText="addVehicle"
        secondaryActionText="skipAndSeeAllResults"
        onPrimaryAction={onAddVehicle}
        onSecondaryAction={onSkipVehicle}
        onCancel={() => modal.remove()}
      >
        toEnsureWeCanFindTheBestMatchPleaseEnterVehicleInformation
      </SimpleModal>
    )
  }
)

export default AddVehicleForSearchModal
